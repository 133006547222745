import { Modal as AntModal } from "antd";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import Icon from "components/UI/Icon";
import { useTranslation } from "react-i18next";

const BaseModal = styled(AntModal)`
  background: ${(props) => props.bg && props.bg};
  border: 2px solid ${(props) => props.borderColor && props.borderColor};
  border-radius: 20px;
  .ant-modal-header {
    padding-bottom: 0;
    margin: 0 0 20px;
  }
  .ant-modal-content {
    max-height: ${(props) => props.style && `calc(${props.style.maxHeight})`};
    box-shadow: 0px 0px;
    border-radius: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .ant-modal-body {
    flex: 1;
    max-height: ${(props) =>
      props.style && `calc(${props.style.maxHeight} - 94px)`};
    overflow-y: auto;
    padding: ${(props) =>
      props.style && props.style.padding && props.style.padding};

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 0px;
      background-color: ${(props) =>
        props.bg ? props.bg : props.theme.colors.reinza};
    }
    ::-webkit-scrollbar {
      width: 3px;
      background-color: ${(props) =>
        props.scBg ? props.scBg : props.theme.colors.ceil};
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 28px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: ${(props) =>
        props.schBg ? props.schBg : props.theme.colors.ceil};
    }
  }
  .ant-modal-close-x {
    display: ${(props) => (props.closeIcon ? "" : "none")};
    height: auto;
  }
`;

function Modal({
  title,
  children,
  bg,
  borderColor = "independence2",
  style,
  ...rest
}) {
  const { colors } = useTheme();
  const { t } = useTranslation();
  return (
    <BaseModal
      title={title && t(title)}
      bg={colors[bg]}
      borderColor={colors[borderColor]}
      centered
      footer={null}
      destroyOnClose
      style={style}
      closeIcon={
        <Icon
          mt={45}
          display="block"
          mr={10}
          icon="icon-remove"
          color="blueYonder"
        />
      }
      {...rest}
    >
      {children}
    </BaseModal>
  );
}
// independence2
export default Modal;
