import React, { useContext } from "react";
import { Context as BlockedPeopleContext } from "context/BlockedPeopleContext";
import { Border } from "components/UI/Border";
import { Box, Flex } from "components/UI/CustomRebass";
import Loading from "components/Loading";
import BlockedUserDropdownItem from "./BlockedUserDropdownItem";
import SearchUserNoResult from "./SearchUserNoResult";

const BlockedUserDropdown = ({
  results,
  handleSearchResult,
  setSearchText,
  wrapperRef,
  type,
  ...rest
}) => {
  const {
    state: { loading },
  } = useContext(BlockedPeopleContext);

  return (
    <Box ref={wrapperRef} width="100%" mt={25}>
      <Border
        as="ul"
        borderWidth={0}
        width="100%"
        overflowY="auto"
        sx={{
          position: "absolute",
          transition: "0.5s ease",
          animationName: "searchdropdown",
          animationTimingFunction: "ease",
          animationDuration: "0.5s",
        }}
        {...rest}
      >
        <Flex flexWrap="wrap">
          {loading ? (
            <Flex justifyContent="center" width="100%">
              <Loading width={100} height={100} />
            </Flex>
          ) : (
            results &&
            results.length > 0 &&
            results.map((item) => (
              <Box
                width={[1 / 2, 1 / 2, 1 / 3, 1 / 3, 1 / 3]}
                key={item._id}
                mr={0}
                pr={5}
              >
                <BlockedUserDropdownItem item={item} type={type} />
              </Box>
            ))
          )}
        </Flex>
      </Border>
      {results && results.length < 1 && loading === false && (
        <SearchUserNoResult />
      )}
    </Box>
  );
};

export default BlockedUserDropdown;
