import React from "react";
import Header from "components/Header";
import { Outlet } from "react-router-dom";
import { Box } from "components/UI/CustomRebass";
import Footer from "components/Footer";
import InvitationPopup from "components/InvitationPopup";
import MoneyboxPopup from "components/MoneyboxPopup";

const DefaultLayout = () => {
  return (
    <Box minHeight="100vh">
      <Header />
      <Box
        as="main"
        sx={{
          minHeight: "calc(100vh - 167px)",
          maxWidth: "2000px",
          margin: "0 auto",
        }}
        px={[20, 20, 20, 20, 40, 40]}
      >
        <Outlet />
      </Box>
      <Footer />
      <InvitationPopup />
      <MoneyboxPopup />
    </Box>
  );
};

export default React.memo(DefaultLayout);
