import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box } from "components/UI/CustomRebass";
import Login from "containers/Login";
import { useScrollToTop } from "config/hooks/useScrollToTop";

const LoginLayout = () => {
  return (
    <Box bg="reinza">
      <Login>
        <Outlet />
      </Login>
    </Box>
  );
};

export default React.memo(LoginLayout);
