import React, { useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { settingModifiedData } from "store/settingSlice";
import { settingsMenu } from "config/enums";
import { enumToArray } from "config/utils";
import { Context as SettingsContext } from "context/SettingsContext";

import { Box, Flex, Text } from "components/UI/CustomRebass";
import Icon from "components/UI/Icon";
import { Border } from "components/UI/Border";

const LeftMenu = ({ selectMenu, setSelectMenu }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [iconShape, setIconShape] = useState("icon-arrow-right");
  const {
    state: { moderator },
    updateSettings,
  } = useContext(SettingsContext);

  const onMenuItemClick = (item) => {
    updateSettings({
      moderator: {
        ...moderator,
        moderatorType: item.id === 3 ? 4 : item.id,
        moderatorSearchList: null,
      },
    });
    setSelectMenu(item.id === 3 ? 4 : item.id);
    setIconShape("icon-arrow-up");
    if (item.id !== 3 && item.id !== 4 && item.id !== 5)
      setIconShape("icon-arrow-right");

    dispatch(
      settingModifiedData({
        name: "selectedModeratorPage",
        data: item.id === 3 ? 4 : item.id,
      }),
    );
  };

  const onIconClick = (item) => {
    item.id === 3
      ? setIconShape("icon-arrow-up")
      : setIconShape("icon-arrow-right");
  };

  const menu = useMemo(() => {
    return enumToArray(settingsMenu);
  }, []);

  const mainMenu = () =>
    menu.map((item) => {
      return (
        <>
          {!item.subMenu && (
            <Border
              key={item.id}
              borderWidth={0}
              display="flex"
              bg={selectMenu === item.id ? "independence" : ""}
              color={
                selectMenu === item.id ? "speedrunnersLime" : "lavenderBlue"
              }
              sx={{
                position: "relative",
                ":nth-last-of-type(1)": {
                  borderBottomLeftRadius: 15,
                  borderBottomRightRadius: 15,
                },
              }}
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                sx={{ cursor: "pointer" }}
                py={15}
                px={15}
                onClick={() => onMenuItemClick(item)}
                key={item.id}
                width="100%"
              >
                <Text fontSize={18}>{t(item.title)}</Text>
              </Flex>
              <Icon
                icon={
                  (selectMenu === 4 || selectMenu === 5) && item.id === 3
                    ? iconShape
                    : "icon-arrow-right"
                }
                fontSize={15}
                onClick={() => onIconClick(item)}
                height="100%"
                display="flex"
                alignItems="center"
                sx={{
                  position: "absolute",
                  right: 10,
                  cursor: "pointer",
                }}
              />
            </Border>
          )}

          {item.subMenu && iconShape === "icon-arrow-up" && (
            <Border
              as="div"
              borderWidth={0}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              py={15}
              pr={15}
              pl={35}
              onClick={() => onMenuItemClick(item)}
              key={item.id}
              width="100%"
              bg={selectMenu === item.id ? "independence" : "#1C253B"}
              color={
                selectMenu === item.id ? "speedrunnersLime" : "lavenderBlue"
              }
              sx={{
                cursor: "pointer",
                ":nth-last-of-type(1)": {
                  borderBottomLeftRadius: 15,
                  borderBottomRightRadius: 15,
                },
              }}
            >
              <Text fontSize={18}>{t(item.title)}</Text>
            </Border>
          )}
        </>
      );
    });

  const subMenu = () => {
    return menu.map((item) => {
      if (item.subMenu && iconShape === "icon-arrow-up") {
        return (
          <Border
            as="div"
            borderWidth={0}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py={15}
            pr={15}
            pl={35}
            onClick={() => onMenuItemClick(item)}
            key={item.id}
            width="100%"
            bg={selectMenu === item.id ? "independence" : "#1C253B"}
            color={selectMenu === item.id ? "speedrunnersLime" : "lavenderBlue"}
            sx={{
              cursor: "pointer",
              ":nth-last-of-type(1)": {
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
              },
            }}
          >
            <Text fontSize={18}>{t(item.title)}</Text>
          </Border>
        );
      }
    });
  };

  return (
    <Box mt={15}>
      {mainMenu()}
      {/* {subMenu()} */}
    </Box>
  );
};

export default LeftMenu;
