import { createSlice } from "@reduxjs/toolkit";
import { depthItemModified } from "config/utils";

const initialState = {
  info: {},
  wallet: {
    USD: 0,
    zapapaCoin: 0,
    freeCoin: 0,
  },
  currencyId: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userModifiedData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
    userUpdateData: (state, action) => {
      state[action.payload.name] = {
        ...state[action.payload.name],
        ...action.payload.data,
      };
    },
    userDepthModifiedData: (state, action) =>
      depthItemModified(
        state,
        action.payload.name,
        action.payload.depth,
        action.payload.data,
      ),
    userResetData: () => initialState,
  },
});

export const {
  userModifiedData,
  userUpdateData,
  userDepthModifiedData,
  userResetData,
} = userSlice.actions;

export const selectuser = (state) => state.user;

export default userSlice.reducer;
