import { eventAddData } from "store/eventSlice";

export default class ChatHandler {
  message = (indicator, args) => (dispatch, state) => {
    const muteList = state().event.muteList;
    if (muteList.filter((x) => x.typeId === args.userId).length === 0) {
      dispatch(eventAddData({ name: "chat", data: args }));
    }
  };
}
