import React, { useMemo } from "react";
import { Select } from "antd";
import withFormLabel from "components/UI/Forms/withFormLabel";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import styled from "@emotion/styled";

// const { Option } = Select;

// const opt = (props) => (
//   <Option key={props.value} value={props.value} label={props.label}>
//     <div
//       style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
//     >
//       <div style={{ height: "60px" }}>asd</div>
//       <div>{props.value}</div>
//       <div>asd</div>
//     </div>
//   </Option>
// lavenderBlue
// );

const SSelectStyled = styled(Select)`
  display: block;
  .ant-select-selection-search-input {
    color: ${(props) => props.theme.colors.lavenderBlue};
  }
  .ant-select-selector {
    border-radius: 12px !important;
  }
  .ant-select-item-option {
    color: ${(props) => props.theme.colors.lavenderBlue} !important;
    &-selected {
      color: ${(props) => props.theme.colors.policeBlue} !important;
    }
    &:hover {
    }
  }
  span.ant-select-selection-item {
    border-radius: 30px;
    font-size: 12px;
  }
  &:focus {
    border-color: transparent;
  }
  &::selection {
    border-color: transparent !important;
  }
`;

const SSelect = ({
  data,
  fieldNames = { label: "label", value: "value" },
  hasError,
  children,
  options,
  opt,
  isTranslate = false,
  placeholder,
  placeholderTx,
  ...rest
}) => {
  const { t } = useTranslation();
  const { language } = i18n;

  const mergedOptions = useMemo(() => {
    return isTranslate
      ? options.map((x) => ({
          ...x,
          [fieldNames.label]: t(x[fieldNames.label]),
        }))
      : options;
  }, [language, options]);

  return (
    <SSelectStyled
      {...rest}
      placeholder={placeholderTx ? t(placeholderTx) : placeholder}
      options={opt ? undefined : mergedOptions}
      allowClear
      fieldNames={fieldNames}
      className={hasError ? "ant-select-status-error" : ""}
      getPopupContainer={(el) => el.parentElement}
      filterOption={(input, option) => {
        return (
          option?.[fieldNames.label]
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0 ||
          option?.[fieldNames.label]
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        );
      }}
    >
      {/* {mergedOptions.map(opt)} */}
      {opt ? mergedOptions.map((e) => opt(e, rest)) : undefined}
    </SSelectStyled>
  );
};

export default withFormLabel(SSelect);
