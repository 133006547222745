import { dollarFormat } from "config/utils";
import { eventModifiedData } from "store/eventSlice";
import { userModifiedData } from "store/userSlice";

export default class WalletHandler {
  balance = (indicator, args) => (dispatch, state) => {
    if (state().user.info._id === args.userId) {
      dispatch(
        userModifiedData({
          name: "wallet",
          data: { ...args.balance, USD: dollarFormat(args.balance?.USD || 0) },
        }),
      );
      dispatch(eventModifiedData({ name: "walletAnimation", data: true }));
    }
  };
  info = (indicator, args) => (dispatch, state) => {
    if (state().user.info._id === args.userId) {
      dispatch(
        userModifiedData({ name: "paymentStatus", data: args.paymentStatus }),
      );
    }
  };

  transaction = (indicator, args) => (dispatch, state) => {};
}
