import withLayoutController from "./withLayoutController";

import defaultLayout from "./DefaultLayout";
import eventPageLayout from "./EventPageLayout";
import loginLayout from "./LoginLayout";
import nonFooterLayout from "./NonFooterLayout";
import walletLayout from "./WalletLayout";

export const DefaultLayout = withLayoutController(defaultLayout);
export const EventPageLayout = withLayoutController(eventPageLayout);
export const LoginLayout = withLayoutController(loginLayout);
export const NonFooterLayout = withLayoutController(nonFooterLayout);
export const WalletLayout = withLayoutController(walletLayout);
