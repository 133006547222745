import React from "react";
import { DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import withFormLabel from "../withFormLabel";
import styled from "@emotion/styled";

const DatepickerStyled = styled(DatePicker)`
  border-color: transparent;
  border-radius: 12px;
  background: ${(props) => props.theme.colors.policeBlue};
  .ant-picker-input {
    height: 44px;
  }
  .ant-picker-suffix {
    color: ${(props) => props.theme.colors.blueYonder};
  }
  .ant-picker-clear {
    background: ${(props) => props.theme.colors.policeBlue};
    color: ${(props) => props.theme.colors.blueYonder};
  }
  .ant-form-item-has-error {
    border-color: #ff4d4f !important;
  }
`;

const SDatepicker = ({ hasError, placeholder, placeholderTx, ...rest }) => {
  const { t } = useTranslation();

  return (
    <DatepickerStyled
      bg="policeBlue"
      height={67}
      placeholder={placeholderTx ? t(placeholderTx) : placeholder}
      className={hasError ? "ant-input-status-error" : ""}
      getPopupContainer={(el) => el.parentElement}
      {...rest}
    />
  );
};

export default withFormLabel(SDatepicker);
