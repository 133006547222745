import React, { useEffect, useState } from "react";
import Header from "components/Header";
import { Outlet } from "react-router-dom";
import { Box, Flex } from "components/UI/CustomRebass";
import { useSelector } from "react-redux";
import { getLocalStorage } from "config/utils";
import Loading from "components/Loading";
import InvitationNotifly from "components/InvitationNotifly";
import MoneyboxPopup from "components/MoneyboxPopup";

const EventPageLayout = () => {
  const [loading, setLoading] = useState(true);
  const { info } = useSelector(({ user }) => user);

  useEffect(() => {
    const token = getLocalStorage("token");
    if (token) {
      if (Object.keys(info).length > 0) {
        setLoading(false);
      }
    } else setLoading(false);
  }, [info]);

  return (
    <Box>
      <Header />
      <Box
        as="main"
        sx={{
          minHeight: "calc(100vh - 82px)",
          maxWidth: "2000px",
          margin: "0 auto",
        }}
        px={[0,0,0,40]}
      >
        {loading ? (
          <Flex
            width={1}
            height="calc(100vh - 114px)"
            justifyContent="center"
            alignItems="center"
          >
            <Loading width={120} height={120} />
          </Flex>
        ) : (
          <Outlet />
        )}
      </Box>
      <InvitationNotifly />
      <MoneyboxPopup />
    </Box>
  );
};

export default React.memo(EventPageLayout);
