import { createSlice } from "@reduxjs/toolkit";
import { depthItemModified } from "config/utils";
import { UserService } from "services";

const userService = new UserService();
const initialState = {
  messages: [],
  messageData: [],
  newMessage: false,
  groupId: null,
  receiverUser: null,
  messageLoading: false,
  userBlockedMe: null,
  meBlockedUser: null,
  blockedControlLoading: false,
  selectedReceiver: null,
};

export const inboxSlice = createSlice({
  name: "inbox",
  initialState,
  reducers: {
    inboxModifiedData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
    inboxAddUpdateModifiedData: (state, action) => {
      const exist = state[action.payload.name].filter(
        (fl) => fl[action.payload.item.name] === action.payload.item.data,
      );
      state[action.payload.name] =
        exist.length > 0
          ? state[action.payload.name]
              .map((m) =>
                m[action.payload.item.name] === action.payload.item.data
                  ? action.payload.data
                  : m,
              )
              .sort(
                (a, b) =>
                  b.detail.unreadMessageCount - a.detail.unreadMessageCount,
              )
          : [action.payload.data, ...state[action.payload.name]];
    },
    inboxUpdateModifiedData: (state, action) => {
      state[action.payload.name] = [
        ...state[action.payload.name],
        action.payload.data,
      ];
    },
    inboxDepthModifiedData: (state, action) =>
      depthItemModified(
        state,
        action.payload.name,
        action.payload.depth,
        action.payload.data,
      ),
    inboxResetData: () => initialState,
  },
});

export const {
  inboxModifiedData,
  inboxAddUpdateModifiedData,
  inboxUpdateModifiedData,
  inboxResetData,
} = inboxSlice.actions;

export const inboxMessages = () => (dispatch) => {
  userService.getMessages().then(({ data }) => {
    dispatch(inboxModifiedData({ name: "messages", data }));
  });
};
// TODO: header inbox unread etmek icin inbox sayfasindaki exist status 1 donerse messages objesinde ilgili mesajin detail objesindeki unreadMessageCount 0 yap.

export const inboxMessagesWithGroupId = (groupId) => (dispatch, state) => {
  const { messages } = state().inbox;
  const curMessages = messages.slice();
  const newMessages = curMessages.map((m) => {
    let newData = m;
    if (m.groupId === groupId) {
      newData = { ...m, detail: { ...m.detail, unreadMessageCount: 0 } };
    }
    return newData;
  });
  userService
    .getMessagesWithGroupId(groupId)
    .then(({ data }) => {
      dispatch(inboxModifiedData({ name: "messageLoading", data: false }));
      dispatch(inboxModifiedData({ name: "messageData", data }));
      dispatch(inboxModifiedData({ name: "messages", data: newMessages }));
    })
    .catch(() => {
      dispatch(inboxModifiedData({ name: "messageLoading", data: false }));
      dispatch(inboxModifiedData({ name: "messageData", data: null }));
    });
};

export const inboxReset = () => (dispatch) => {
  dispatch(inboxModifiedData({ name: "messageData", data: [] }));
  dispatch(inboxModifiedData({ name: "groupId", data: null }));
  dispatch(inboxModifiedData({ name: "newMessage", data: null }));
  dispatch(inboxModifiedData({ name: "receiverUser", data: null }));
  dispatch(inboxModifiedData({ name: "selectedReceiver", data: null }));
};

export const checkBlock = (userId, targetUserId) => (dispatch) => {
  const isBlockedData = {
    typeId: userId, // info._id,
    userId: targetUserId, // receiverUser?._id,
    type: "user",
  };

  userService
    .isUserBlocked(targetUserId, { type: "user" }) // receiverUser?._id
    .then(({ data }) => {
      dispatch(
        inboxModifiedData({
          name: "blockedControlLoading",
          data: false,
        }),
      );
      dispatch(
        inboxModifiedData({
          name: "meBlockedUser",
          data: data === true ? data : data.data, // TODO: should fix service
        }),
      );
    });

  userService.isBlockExist(isBlockedData).then(({ data }) => {
    dispatch(
      inboxModifiedData({
        name: "blockedControlLoading",
        data: false,
      }),
    );
    dispatch(
      inboxModifiedData({
        name: "userBlockedMe",
        data: data === true ? data : data.data, // TODO: should fix service
      }),
    );
  });
};

export default inboxSlice.reducer;
