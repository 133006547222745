import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as _ from "lodash";
import { useDebouncedCallback } from "use-debounce";
import { routesUrl } from "routes/routes-url";
import useClickOutside from "config/hooks/useClickOutside";
import { CmsService } from "services";
import { Box, Flex, Image } from "components/UI/CustomRebass";
import { Logo } from "components/UI/SvgIcon";
import SearchBox from "components/SearchBox";
import { Border } from "components/UI/Border";
import { Link } from "components/UI/Link";
import Button from "components/UI/Button";
import HeaderUserProfile from "./HeaderUserProfile/headerUserProfile";
import HeaderSearchDropdown from "./headerSearchDropdown";
import HeaderNotifications from "./headerNotifications";
import HeaderMenu from "./headerMenu";
import Icon from "components/UI/Icon";

const cmsService = new CmsService();

const Header = () => {
  const navigate = useNavigate();
  const { authenticated } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const screenWidth = window.innerWidth;

  const [searchResult, setSearchResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);

  const onSearchChange = _.debounce(
    (e) => {
      if (e.keyCode !== 13 && e.target.value.trim().length > 0) {
        setIsLoading(true);
        cmsService.getSearchParams({ name: e.target.value }).then((res) => {
          setIsLoading(false);
          setSearchResult(res.data);
        });
      } else {
        setSearchResult(null);
        e.target.value.trim().length > 0
          ? navigate(`${routesUrl.search}?q=${e.target.value}`)
          : (e.target.value = null);
      }
    },
    [1000],
  );

  const [searchParam] = useSearchParams();
  const queryString = searchParam.get("q");

  useEffect(() => {
    setSearchValue(queryString);
    if (searchActive) {
      onSetSearchActive();
    }
  }, [queryString]);

  const debounced = useDebouncedCallback((value) => {
    if (value.trim().length > 0) {
      setIsLoading(true);
      cmsService.getSearchParams({ name: value }).then((res) => {
        setIsLoading(false);
        setSearchResult(res.data);
      });
    }
  }, 1000);

  const onLoginClick = () => {
    navigate(routesUrl.signIn);
  };

  const searchDropdown = () => {
    setSearchResult(null);
  };
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, searchDropdown);

  const onSetSearchActive = () => {
    setSearchActive(!searchActive);
  };

  return (
    <Border
      as="header"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px={40}
      mb={30}
      bg="reinza"
      height={80}
      sx={{ position: "sticky", top: 0, "z-index": 9 }}
      borderColor="purpleNavy"
      borderWidth={0}
      borderRadius={0}
      borderBottomWidth={2}
    >
      {searchActive && (
        <Box
          bg="reinza"
          width={1}
          height="100%"
          px={20}
          sx={{
            position: "absolute",
            zIndex: 1,
            left: 0,
          }}
        >
          <SearchBox
            width="calc(100% - 20px)"
            id="headerSearchInput"
            onKeyDown={(e) => {
              onSearchChange(e);
            }}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyUp={(e) => debounced(e.target.value)}
            value={searchValue}
            isLoading={isLoading}
            borderRadius="14px"
            borderBottom={searchResult ? "0" : "14px"}
            style={{ mt: 15 }}
          />
          {searchResult && (
            <HeaderSearchDropdown
              width="calc(100% - 60px)"
              handleSearchResult={() => {
                setSearchResult(null);
              }}
              results={searchResult}
            />
          )}
          <Icon
            icon="icon-close"
            color="vloudBurst"
            fontSize={24}
            sx={{
              cursor: "pointer",
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={onSetSearchActive}
          />
        </Box>
      )}
      <Flex
        display="inline-flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        mx="auto"
        // my="auto"
        sx={{ maxWidth: "1920px" }}
      >
        <Flex alignItems="center" justifyContent="center">
          <Box>
            <Link href={routesUrl.home} display="flex">
              <Image src="/assets/img/logo.png" />
              {/* <Logo /> */}
            </Link>
          </Box>
          <HeaderMenu />
        </Flex>
        <Flex alignItems="center">
          <Box
            display={["none", "none", "none", "block", "block"]}
            ref={wrapperRef}
            pb={location.pathname === "/search" ? 14 : 0}
            mb={location.pathname === "/search" ? -20 : 0}
            sx={{
              borderBottom: location.pathname === "/search" ? "2px solid" : "",
              borderColor:
                location.pathname === "/search" ? "electricBlue" : "",
            }}
          >
            <>
              <SearchBox
                id="headerSearchInput"
                onKeyDown={(e) => {
                  onSearchChange(e);
                }}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyUp={(e) => debounced(e.target.value)}
                value={searchValue}
                isLoading={isLoading}
                borderRadius="14px"
                borderBottom={searchResult ? "0" : "14px"}
              />
              {searchResult && (
                <HeaderSearchDropdown
                  handleSearchResult={() => {
                    setSearchResult(null);
                  }}
                  results={searchResult}
                />
              )}
            </>
          </Box>
          <Box display={["block", "block", "block", "none", "none"]} mt={7}>
            <Icon
              icon="icon-search"
              color="vloudBurst"
              fontSize={24}
              sx={{ cursor: "pointer" }}
              onClick={onSetSearchActive}
            />
          </Box>
          {authenticated ? (
            <>
              <HeaderNotifications />
              <HeaderUserProfile />
            </>
          ) : (
            <Button
              width={128}
              height={46}
              ml={[0, 0, 20, 150, 150]}
              fontSize={16}
              px={25}
              py={0}
              bg="policeBlue"
              color="electricBlue"
              sx={{
                "&:hover": {
                  backgroundColor: "electricBlue",
                  color: "policeBlue",
                },
              }}
              icon="icon-signin"
              iconOpt={{ mr: 3 }}
              text="title.login"
              onClick={() => onLoginClick()}
            />
          )}
        </Flex>
      </Flex>
    </Border>
  );
};

export default Header;
