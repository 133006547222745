import { useScrollToTop } from "config/hooks/useScrollToTop";
import React, { useEffect } from "react";
import { useLocation } from "react-router";

const withLayoutController = (WrappedComponent) => () => {
  useScrollToTop();
  const location = useLocation();
  useEffect(() => {
    window.gtag("config", process.env.REACT_APP_GA_MEASUREMENT_ID, {
      page_title: location.pathname,
      page_path: location.pathname,
    });
  }, [location.pathname]);

  return <WrappedComponent />;
};

export default withLayoutController;
