import { Switch } from "antd";
import React from "react";

const SSwitch = ({ defaultValue, hasError, ...rest }) => {
  return (
    <Switch
      defaultChecked={defaultValue}
      className={hasError ? "ant-switch-status-error" : ""}
      {...rest}
    />
  );
};
export default SSwitch;
