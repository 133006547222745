import styled from "@emotion/styled";
import Button from "components/UI/Button";

export const ButtonAnimation = styled(Button)`
  box-shadow: 0px 8px 8px -6px rgba(19, 26, 38, 0.4);
  display: flex;
  align-items: center;
  animation: ${(props) => props.animation} 3s ease infinite;
  position: relative;
  overflow: hidden;
  border: ${(props) => !props.border && "none"};
  border-radius: ${(props) =>
    !props.borderRadius ? "none" : `${props.borderRadius}px`};
  cursor: ${(props) =>
    props.loading
      ? "progress"
      : props?.sx?.cursor
      ? props?.sx?.cursor
      : "pointer"};

  ${(props) => {
    switch (props.bgAnimation) {
      case "TransitioningBackground":
        return `&::before {
          content: "";
          display: block;
          position: absolute;

          background: rgba(255, 255, 255, 0.5);
          width: 60px;
          height: 100%;
          top: 0;
          animation: TransitioningBackground 2s ease infinite;
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          background: rgba(255, 255, 255, 0.2);
          width: 30px;
          height: 100%;
          top: 0;
          animation: TransitioningBackground 2s ease infinite;
        }`;

      default:
        break;
    }
  }}

  .spinner {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    position: relative;
    animation: spinner 1s linear infinite paused;
    -webkit-animation: spinner 1s linear infinite;
  }

  .loader-line {
    position: absolute;
    height: 3px;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .loader-line:before {
    content: "";
    position: absolute;
    height: 3px;
    width: 100%;
    bottom: 0;
    left: 0;
    -webkit-animation: lineAnim 20s linear infinite;
    -moz-animation: lineAnim 20s linear infinite;
    animation: lineAnim 20s linear infinite;
  }
`;
