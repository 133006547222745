import Loading from "components/Loading";
import { Flex } from "components/UI/CustomRebass";
import React from "react";

const CustomSuspense = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <Flex
          justifyContent="center"
          alignItems="center"
          height="calc(100vh - 115px)"
        >
          <Loading width={120} height={120} />
        </Flex>
      }
    >
      {children}
    </React.Suspense>
  );
};

export default CustomSuspense;
