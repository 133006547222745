import React from "react";
import { useTranslation } from "react-i18next";
import useDevices from "containers/EventPage/hooks/useDevices";
import { Box, Text } from "components/UI/CustomRebass";
import DeviceSettings from "./components/DeviceSettings";

const BroadcastSettings = () => {
  const { t } = useTranslation();
  const {
    selectedAudioDevice,
    selectedVideoDevice,
    audioInputDevices,
    videoInputDevices,
    hasAudioInputDevices,
    hasVideoInputDevices,
    videoPermissions,
    audioPermissions,
    selectedDevice,
  } = useDevices();

  return (
    <Box>
      <Text fontSize={[20, 20, 25, 28, 34]} color="white" mb={12}>
        {t("title.broadcast_settings")}
      </Text>
      <DeviceSettings
        firstTitle="title.camera_permissions"
        firstText="description.camera_permisson_content"
        secondTitle="title.select_camera"
        secondText="description.select_camera_content"
        notDevicesText="description.there_is_not_available_camera"
        notDevicesPermissionText="description.you_need_to_enable_camera"
        permission={videoPermissions}
        inputDevices={videoInputDevices}
        selectedDevice={selectedVideoDevice}
        selectedDeviceAction={selectedDevice}
        type="video"
        hasDevice={hasVideoInputDevices}
      />
      <DeviceSettings
        firstTitle="title.microphone_permissions"
        firstText="description.microphone_permisson_content"
        secondTitle="title.select_microphone"
        secondText="description.select_microphone_content"
        notDevicesText="description.there_is_not_available_microphone"
        notDevicesPermissionText="description.you_need_to_enable_microphone"
        permission={audioPermissions}
        inputDevices={audioInputDevices}
        selectedDevice={selectedAudioDevice}
        selectedDeviceAction={selectedDevice}
        type="audio"
        hasDevice={hasAudioInputDevices}
      />
    </Box>
  );
};

export default BroadcastSettings;
