import { Input } from "antd";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Box } from "components/UI/CustomRebass";
import Icon from "components/UI/Icon";
import { useTheme } from "emotion-theming";
import SearchLoadingIcon from "components/Header/searchLoadingIcon";

const { Search } = Input;

const StyledSearchBox = styled(Box)`
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "0px"};

  border-bottom-right-radius: ${(props) =>
    props.borderBottom ? props.borderBottom : "14px"};
  border-bottom-left-radius: ${(props) =>
    props.borderBottom ? props.borderBottom : "14px"};

  &:focus {
    border-bottom-right-radius: 0;
  }
  .ant-input {
    border-radius: 14px;
    border: none;
    background-color: ${(props) => props.bg && props.bg};
    padding-left: ${(props) => (props.pl ? props.pl : "29px")};
    color: ${(props) => props.contentColor && props.contentColor};
    font-size: 16px; // TODO: font size
    height: ${(props) => props.height && props.height};
    &:focus {
      box-shadow: 0px 0px;
      border-bottom-right-radius: 0;
    }

    &::placeholder {
      color: ${(props) => props.contentColor && props.contentColor};
    }
    &-group {
      &-addon {
        background-color: ${(props) => props.bg && props.bg};
        border-top-right-radius: 14px;
        border-bottom-right-radius: 14px;
      }
    }
    &-group-addon {
    }
  }
  .ant-input-search-button {
    background-color: ${(props) => props.bg && props.bg};
    border: none;
    color: ${(props) => props.contentColor && props.contentColor};
  }
  .ant-btn {
    padding: 0;
    margin-right: 15px;
    z-index: 8;
    display: flex;
    align-items: center;
    &.anticon {
      font-size: 22px;
    }
  }
`;

const SearchBox = ({
  id,
  placeholder = "title.search",
  isLoading,
  borderRadius,
  borderBottom,
  value,
  onChange,
  onKeyUp,
  bgColor,
  icon = "search",
  onIconClick,
  searchRef,
  height = 46,
  style,
  ...rest
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <StyledSearchBox
      width={rest.width ? rest.width : [220, 220, 250, 210, 300]}
      bg={bgColor || colors.darkJungleGreen}
      contentColor={colors.ceil}
      borderRadius={borderRadius}
      borderBottom={borderBottom}
      height={height}
      {...style}
    >
      <Search
        id={id}
        ref={searchRef}
        onChange={onChange}
        value={value}
        onKeyUp={onKeyUp}
        placeholder={t(placeholder)}
        enterButton={
          isLoading ? (
            <SearchLoadingIcon mr={5} />
          ) : (
            <Icon
              icon={`icon-${icon}`}
              onClick={onIconClick}
              fontSize={[16, 16, 16, 22]}
            />
          )
        }
        {...rest}
      />
    </StyledSearchBox>
  );
};

export default SearchBox;
