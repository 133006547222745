import {
  settingAddUpdateModifiedData,
  settingModifiedData,
} from "store/settingSlice";

export const acceptedSettingModeratorAction = (args) => (dispatch, state) => {
  const { selectedModeratorPage } = state().setting;
  const { _id: userId } = state().user.info;

  if (userId === args.moderator.userId && selectedModeratorPage === 4) {
    dispatch(
      settingAddUpdateModifiedData({
        name: "moderators",
        item: {
          name: selectedModeratorPage === 4 ? "typeId" : "userId",
          data:
            selectedModeratorPage === 4
              ? args.moderator.typeId
              : args.moderator.userId,
        },
        data: {
          ...args.moderator,
          ownerUsername: args.moderator.username,
          username: args.user.username,
        },
      }),
    );
  } else if (userId === args.moderator.typeId && selectedModeratorPage === 5) {
    dispatch(
      settingAddUpdateModifiedData({
        name: "moderators",
        item: {
          name: selectedModeratorPage === 4 ? "typeId" : "userId",
          data:
            selectedModeratorPage === 4
              ? args.moderator.typeId
              : args.moderator.userId,
        },
        data: {
          ...args.moderator,
          ownerUsername: args.moderator.username,
          username: args.user.username,
        },
      }),
    );
  }
};

export const acceptedSettingModeratorDeleteAction =
  (args) => (dispatch, state) => {
    const { selectedModeratorPage, moderators } = state().setting;
    const { _id: deleteUserId } = state().user.info;

    if (deleteUserId === args.ownerId && selectedModeratorPage === 4) {
      let filteredModerators = moderators.filter(
        (x) => x.typeId !== args.userId,
      );
      dispatch(
        settingModifiedData({
          name: "moderators",
          data: filteredModerators,
        }),
      );
    } else if (deleteUserId === args.userId && selectedModeratorPage === 5) {
      let filteredModerators = moderators.filter(
        (x) => x.userId !== args.ownerId,
      );
      dispatch(
        settingModifiedData({
          name: "moderators",
          data: filteredModerators,
        }),
      );
    }
  };

export const settingDeleteEventModeratorAction =
  (args) => (dispatch, state) => {
    const { selectedModeratorPage, moderators } = state().setting;
    const { _id: deleteUserId } = state().user.info;
    console.log("delete event moderator args: ", args);
    if (deleteUserId === args.ownerId && selectedModeratorPage === 4) {
      let filteredModerators = moderators.filter(
        (x) => x.typeId !== args.userId,
      );
      dispatch(
        settingModifiedData({
          name: "moderators",
          data: filteredModerators,
        }),
      );
    } else if (deleteUserId === args.userId && selectedModeratorPage === 5) {
      let filteredModerators = moderators.filter(
        (x) => x.userId !== args.ownerId,
      );
      dispatch(
        settingModifiedData({
          name: "moderators",
          data: filteredModerators,
        }),
      );
    }
  };
