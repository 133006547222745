import styled from "@emotion/styled";
import { Radio } from "antd";
import { Border } from "components/UI/Border";

const BorderStyled = styled(Border)`
  width: 100%;
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0px 0px !important;
  }
  label {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid transparent;
    flex-direction: row-reverse;
    margin: 0;
    padding: 5px 0;
    span.ant-radio {
      top: 0;
      & + span {
        padding: 0;
      }
      .ant-radio-inner {
        background: none;
        border-color: ${(props) => props.theme.colors.lavenderBlue};
      }
    }
    span:last-child {
      color: ${(props) => props.theme.colors.lavenderBlue};
      font-size: 14px;
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &:hover {
        span {
          color: ${(props) => props.theme.colors.electricBlue};
        }
      }
    }
    &:hover {
      span:last-child {
        color: ${(props) => props.theme.colors.electricBlue};
      }
    }
  }
  .ant-radio-wrapper-checked {
    span.ant-radio {
      .ant-radio-inner {
        border-color: ${(props) => props.theme.colors.electricBlue};
        &:after {
          background-color: ${(props) => props.theme.colors.electricBlue};
        }
      }
    }
  }
`;

export const devicesRadioTemplate = (props) => {
  return (
    <BorderStyled
      key={props.deviceId}
      display="inline-flex"
      alignItems="center"
      borderWidth={0}
      borderBottomWidth={1}
      borderColor="purpleNavy"
      height={50}
      sx={{
        "&:last-child": {
          border: 0,
        },
      }}
    >
      <Radio value={props.deviceId}>{props.label}</Radio>
    </BorderStyled>
  );
};
