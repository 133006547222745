import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactions: null,
  paymentStatus: null,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    walletModifiedData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
  },
});

export const { walletModifiedData } = walletSlice.actions;

export const selectWallet = (state) => state.wallet;

export default walletSlice.reducer;
