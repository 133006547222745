import React, { useCallback } from "react";
import { Box, Text } from "components/UI/CustomRebass";
import Background from "components/UI/Background";
import { Border } from "components/UI/Border";
import { useNavigate } from "react-router-dom";
import { routesUrl } from "routes/routes-url";
import { userImageUrl } from "config/utils";
import { useDispatch } from "react-redux";
import { inboxMessagesWithGroupId, inboxModifiedData } from "store/inboxSlice";

const InboxMessageItem = ({ messageItem, ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToInbox = useCallback((userId) => {
    dispatch(inboxModifiedData({ name: "groupId", data: messageItem.groupId }));
    dispatch(
      inboxModifiedData({
        name: "receiverUser",
        data: messageItem.receiverUser,
      }),
    );
    dispatch(
      inboxModifiedData({
        name: "selectedReceiver",
        data: messageItem.receiverUser,
      }),
    );
    dispatch(inboxMessagesWithGroupId(messageItem.groupId));
    dispatch(inboxModifiedData({ name: "messageLoading", data: true }));

    navigate(`${routesUrl.inbox}`);
  }, []);

  return (
    <Border
      display="flex"
      py={12}
      borderColor="independence"
      borderWidth={0}
      borderBottomWidth={1}
      sx={{
        cursor: "pointer",
        "&:first-of-type": {
          paddingTop: 0,
        },
      }}
      onClick={() => goToInbox(messageItem._id)}
      {...rest}
    >
      <Background
        url={userImageUrl(messageItem.receiverUser?.username, "profile")}
        height={42}
        width={42}
        mr={8}
        sx={{
          backgroundSize: "cover",
          borderRadius: "50%",
        }}
      ></Background>
      <Box>
        <Text fontSize={14} color="white">
          {messageItem.receiverUser?.username}
        </Text>
        <Text
          fontSize={12}
          color="lavenderBlue"
          mr={30}
          mb={8}
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: 175,
          }}
        >
          {messageItem.detail?.lastMessage}
        </Text>
      </Box>
      {messageItem.detail.unreadMessageCount > 0 && (
        <Border
          bg={"speedrunnersLime"}
          width={8}
          height={8}
          ml="auto"
          borderRadius={8}
        ></Border>
      )}
    </Border>
  );
};

export default InboxMessageItem;
