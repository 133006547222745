import createDataContext from "./createDataContext";

const reducer = (state, action) => {
  switch (action.type) {
    case "update":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const updateSettings = (dispatch) => (payload) => {
  dispatch({ type: "update", payload });
};

export const { Provider, Context } = createDataContext(
  reducer,
  { updateSettings },
  {
    moderator: {
      moderatorList: null,
      moderatorSearchList: null,
      moderatorType: "",
      loading: false,
      change: false,
    },
  },
);
