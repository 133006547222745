import React from "react";
import { TimePicker } from "antd";
import { useTranslation } from "react-i18next";
import withFormLabel from "../withFormLabel";
import styled from "@emotion/styled";

const TimepickerStyled = styled(TimePicker)`
  border-color: transparent;
  background: ${(props) => props.theme.colors.policeBlue};
  border-radius: 12px;
  &.ant-picker-disabled {
    background: ${(props) => props.theme.colors.policeBlue};
    opacity: 0.5;
  }
  .ant-picker-input {
    height: 44px;
  }
  .ant-picker-suffix {
    color: ${(props) => props.theme.colors.blueYonder};
  }
  .ant-picker-clear {
    background: ${(props) => props.theme.colors.policeBlue};
    color: ${(props) => props.theme.colors.blueYonder};
  }
  .ant-form-item-has-error {
    border-color: #ff4d4f !important;
  }
`;

const STimepicker = ({ hasError, placeholder, placeholderTx, ...rest }) => {
  const { t } = useTranslation();

  return (
    <TimepickerStyled
      height={67}
      placeholder={placeholderTx ? t(placeholderTx) : placeholder}
      className={hasError ? "ant-input-status-error" : ""}
      getPopupContainer={(el) => el.parentElement}
      showNow={false}
      {...rest}
    />
  );
};

export default withFormLabel(STimepicker);
