import React from "react";
import { Box, Flex, Text } from "components/UI/CustomRebass";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import { logout } from "store/authSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const LogoutModalTemplate = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onSubmit = () => {
    dispatch(logout());
    closeModal();
  };

  return (
    <Box mt={25}>
      <Icon
        icon="icon icon-signout"
        fontSize={112}
        display="flex"
        color="magicPotion"
        mt={86}
        mb={48}
        justifyContent="center"
      />
      <Flex
        flexDirection="column"
        alignItems="center"
        color="lavenderBlue"
        fontSize={18}
        mb={70}
      >
        <Text
          textAlign="center"
          dangerouslySetInnerHTML={{
            __html: t("description.logoutDescription"),
          }}
        />
      </Flex>
      <Flex justifyContent="space-between">
        <Button
          flex="1"
          mr={10}
          px={40}
          mode="outinline"
          width="50%"
          onClick={() => closeModal()}
          text="title.cancel"
          fontSize={[13, 13, 16, 16]}
          color="lavenderBlue"
          fontWeight={600}
          sx={{
            borderColor: "lavenderBlue",
          }}
        />
        <Button
          flex="1"
          ml={10}
          width="50%"
          bg="electricBlue"
          onClick={onSubmit}
          text="title.yesImSure"
          fontSize={[13, 13, 16, 16]}
          fontWeight={600}
          sx={{
            "&:hover": {
              backgroundColor: "electricBlue",
              color: "policeBlue",
            },
          }}
        ></Button>
      </Flex>
    </Box>
  );
};

export default LogoutModalTemplate;
