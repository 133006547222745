import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import i18nextInit from "init/i18nextinit";
import { Provider } from "react-redux";
import store from "store";
import "swiper/css";
import "swiper/css/pagination";

i18nextInit();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
);
