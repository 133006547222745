import { defaultThemeColors } from "theme/defaultTheme/default-colors";

export default {
  default: {
    ".ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight":
      {
        paddingTop: "41px",
      },
    ".ant-popover": {
      "&-arrow": {
        "&-content": { display: "none" },
        backgroundImage: `url(/assets/img/triangle.svg)`,
        borderBottom: `3px solid ${defaultThemeColors.reinza}`,
        width: "35px",
        height: "26px",
        top: "19px",
      },
      "&-inner": {
        borderRadius: "19px",
        backgroundColor: defaultThemeColors.reinza,
        border: `1px solid ${defaultThemeColors.philippineSilver}`,
        "&-content": {
          paddingLeft: "0px",
          paddingRight: "0px",
          color: defaultThemeColors.lavenderBlue,
        },
      },
    },
  },
  headerShort: {
    ".ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight":
      {
        paddingTop: "51px",
      },
    ".ant-popover": {
      "&-arrow": {
        "&-content": { display: "none" },
        backgroundImage: `url(/assets/img/triangle.svg)`,
        borderBottom: `3px solid ${defaultThemeColors.reinza}`,
        width: "35px",
        height: "26px",
        top: "29px",
      },
      "&-inner": {
        borderRadius: "19px",
        backgroundColor: defaultThemeColors.reinza,
        border: `1px solid ${defaultThemeColors.philippineSilver}`,
        "&-content": {
          paddingLeft: "0px",
          paddingRight: "0px",
          color: defaultThemeColors.lavenderBlue,
        },
      },
    },
  },
  headerProfile: {
    ".ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight":
      {
        paddingTop: "41px",
      },
    ".ant-popover": {
      "&-arrow": {
        "&-content": { display: "none" },
        backgroundImage: `url(/assets/img/triangle.svg)`,
        borderBottom: `3px solid ${defaultThemeColors.reinza}`,
        width: "35px",
        height: "26px",
        top: "19px",
      },
      "&-inner": {
        borderRadius: "19px",
        backgroundColor: defaultThemeColors.reinza,
        border: `1px solid ${defaultThemeColors.philippineSilver}`,
        "&-content": {
          paddingLeft: "0px",
          paddingRight: "0px",
          color: defaultThemeColors.lavenderBlue,
        },
      },
    },
  },
  short: {
    ".ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight":
      {
        paddingTop: "32px",
      },
    ".ant-popover": {
      "&-arrow": {
        "&-content": { display: "none" },
        backgroundImage: `url(/assets/img/triangle.svg)`,
        borderBottom: `3px solid ${defaultThemeColors.reinza}`,
        width: "35px",
        height: "26px",
        top: "10px",
      },
      "&-inner": {
        borderRadius: "19px",
        backgroundColor: defaultThemeColors.reinza,
        border: `1px solid ${defaultThemeColors.philippineSilver}`,
        "&-content": {
          paddingLeft: "0px",
          paddingRight: "0px",
          color: defaultThemeColors.lavenderBlue,
        },
      },
    },
  },
  broadcast: {
    ".ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight":
      {
        paddingTop: "0",
      },
    ".ant-popover": {
      "&-arrow": {
        "&-content": { display: "none" },
      },
      "&-inner": {
        borderRadius: "19px",
        backgroundColor: defaultThemeColors.reinza,
        border: `1px solid ${defaultThemeColors.philippineSilver}`,
        "&-content": {
          paddingLeft: "0px",
          paddingRight: "0px",
          color: defaultThemeColors.lavenderBlue,
        },
      },
    },
  },
  emoji: {
    ".ant-popover": {
      "&-arrow": {
        "&-content": { display: "none" },
      },
      "&-inner": {
        borderRadius: "19px",
        backgroundColor: "none",
        "&-content": {
          color: defaultThemeColors.lavenderBlue,
          padding: "0",
        },
      },
    },
  },
};
