import React from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "components/UI/CustomRebass";
import Icon from "components/UI/Icon";

const BlockedUserNoResult = () => {
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      color="ceil"
      justifyContent="center"
      borderWidth={0}
      borderTopWidth={2}
      borderColor="reinza"
      width="100%"
      py={15}
      mt={[100, 120, 140, 160, 180]}
    >
      <Icon icon="icon-block" fontSize={[55, 55, 65, 85, 98]} mb={16} />
      <Text fontSize={[12, 14, 14, 16, 16]}>
        {t("description.no_blocked_people")}
      </Text>
    </Flex>
  );
};

export default BlockedUserNoResult;
