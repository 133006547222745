import { getLocalStorage } from "config/utils";
import { UserService } from "services";
import {
  notificationModifiedData,
  notificationUnshiftModifiedData,
} from "store/notificationSlice";
import firebase from "./firebaseAction";

const userService = new UserService();

export const notificationFirebase = () => (dispatch) => {
  const nPermission = getLocalStorage("notificationPermission");
  if (nPermission) {
    dispatch(notificationPermission(true));

    try {
      const client = firebase.init();
      // console.log("client", client);
      firebase
        .requestPermission(client, (token) => {
          // console.log({token});
          const data = { token };
          // save token to database
          userService.firebaseCreate(data).catch(() => {});

          // listen messages from firebase client
          firebase.onMessage(client, (message) => {
            // TODO: Firebase ‘den gelen senderUser data’si /notifications’ta gelen data icindeki senderUser objesiyle ayni olarak gelmeli.
            dispatch(
              notificationUnshiftModifiedData({
                name: "notifications",
                data: message.data,
              }),
            );
            dispatch(notificationModifiedData({ name: "notify", data: true }));
          });
        })
        .catch((error) => {
          dispatch(notificationPermission(false));
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
};

const notificationPermission = (payload) => (dispatch) => {
  dispatch(
    notificationModifiedData({ name: "notificationPermission", data: payload }),
  );
};
