import React, { useEffect } from "react";

const AutoCloseLayout = () => {
  useEffect(() => {
    window.close();
  }, []);

  return <></>;
};

export default AutoCloseLayout;
