import { createSlice } from "@reduxjs/toolkit";
import { getLocalStorage } from "config/utils";
import { chatSocketConnectAction } from "./actions/socketAction";
import { eventModifiedData } from "./eventSlice";

const initialState = { socket: null, unmount: false };

export const chatSocketSlice = createSlice({
  name: "chatSocket",
  initialState,
  reducers: {
    chatSocketModifiedData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
  },
});

export const { chatSocketModifiedData } = chatSocketSlice.actions;

export const chatSocketConnect = (id) => (dispatch) => {
  const token = getLocalStorage("token");

  dispatch(chatSocketConnectAction(token, id));
};

export const chatSocketDisconnect = (id) => (dispatch, state) => {
  const { socket } = state().chatSocket;
  if (socket) {
    socket.emit("chat:leave", { eventId: id });
    socket.close();
    dispatch(chatSocketModifiedData({ name: "socket", data: null }));
    dispatch(eventModifiedData({ name: "chat", data: [] }));
  }
};

export const chatSocketReconnect = (id) => (dispatch, state) => {
  const { socket } = state().chatSocket;
  socket.emit("chat:leave", { eventId: id });
  socket.close();
  dispatch(chatSocketModifiedData({ name: "socket", data: null }));
  dispatch(chatSocketConnect(id));
};

export default chatSocketSlice.reducer;
