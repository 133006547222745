import { eventDepthModifiedData, eventModifiedData } from "store/eventSlice";
import { socketEmit } from "./socketAction";

const { EventService, UserService, CmsService } = require("services");

const eventService = new EventService();
const userService = new UserService();
const cmsService = new CmsService();

export const getEvent = (eventId, authenticated) => async (dispatch, state) => {
  const { data } = await eventService.getEvent(eventId, authenticated);
  if (data?.event === false) {
    return data;
  }
  dispatch(eventModifiedData({ name: "info", data }));
  dispatch(
    eventModifiedData({
      name: "walletDonateAmount",
      data: data.donateTotalAmount,
    }),
  );
  dispatch(
    eventDepthModifiedData({
      name: "info",
      depth: ["isOwner"],
      data: data.user._id === state().user.info._id,
    }),
  );
  await dispatch(getEventActionStreams(eventId, authenticated));
  await dispatch(getEventActionPoll(eventId, authenticated));
  await dispatch(invitationModeratorAction(eventId, authenticated));
  await dispatch(invitationStreamAction(eventId, authenticated));
  await dispatch(getModeratorAction(eventId, authenticated));
  await dispatch(getDonates(eventId, authenticated));
  authenticated && (await dispatch(getMuteList(eventId, authenticated)));
  data.user._id === state().user.info._id &&
    eventService.eventActivity(eventId);
};

export const getMuteList =
  (eventId, authenticated) => async (dispatch, state) => {
    const params = { type: "mute", resourceId: eventId };
    await userService
      .getBlockedUserByUserId(params)
      .then(({ data }) =>
        dispatch(eventModifiedData({ name: "muteList", data })),
      );
  };

export const getEventActionStreams =
  (eventId, authenticated) => async (dispatch, state) => {
    await eventService.getStreams(eventId).then(({ data }) => {
      dispatch(
        eventDepthModifiedData({
          name: "streams",
          depth: ["accepted"],
          data,
        }),
      );
    });
  };

export const getEventActionPoll =
  (eventId, authenticated) => async (dispatch, state) => {
    await eventService.getPoll(eventId).then(({ data }) => {
      dispatch(
        eventModifiedData({
          name: "poll",
          data: data || {},
        }),
      );
    });
  };

const getModeratorAction = (eventId, authenticated) => (dispatch, state) => {
  if (!authenticated) return;
  let params = { userId: state().event.info.user._id, type: "user" };
  userService.getModerator(params).then(({ data }) => {
    data.forEach((m) => {
      dispatch(acceptedGetModeratorAction({ ...m }));
    });
  });
};

const invitationModeratorAction =
  (eventId, authenticated) => (dispatch, state) => {
    if (!authenticated) return;

    let params = {
      scope: "r",
      status: "pending",
      type: "event_moderator",
      eventStatus: "active",
    };
    userService.getInvitations(params).then(({ data }) => {
      data.length > 0 &&
        userService
          .getByUserId((params = { id: data[0].userId }))
          .then(({ data: userData }) => {
            dispatch(
              inviteModeratorAction({
                ...data[0],
                username: userData.username,
              }),
            );
          });
    });
    if (state().event.info.permissions?.moderator) {
      params = {
        scope: "s",
        status: "pending",
        type: "event_moderator",
        eventId,
      };
      userService.getInvitations(params).then(({ data }) => {
        data.forEach((m) => {
          dispatch(pendingModeratorAction({ ...m }));
        });
      });
      params = {
        scope: "s",
        type: "user_moderator",
        status: "pending",
      };
      userService.getInvitations(params).then(({ data }) => {
        data.forEach((m) => {
          dispatch(pendingModeratorAction({ ...m }));
        });
      });
    }
  };

// TODO: Add People
const invitationStreamAction =
  (eventId, authenticated) => (dispatch, state) => {
    if (!authenticated) return;

    let params = {
      scope: "r",
      status: "pending",
      type: "stream",
      eventId,
      eventStatus: "active",
    };
    userService.getInvitations(params).then(({ data }) => {
      data.length > 0 &&
        userService
          .getByUserId((params = { id: data[0].userId }))
          .then(({ data: userData }) => {
            dispatch(
              inviteStreamAction({
                ...data[0],
                username: userData.username,
              }),
            );
          });
    });
    if (state().event.info.permissions?.add_people) {
      let params = {
        type: "stream",
        scope: "s",
        eventId,
        status: "pending",
      };
      userService.getInvitations(params).then(({ data }) => {
        data.forEach((m) => {
          dispatch(pendingPeopleAction({ ...m }));
        });
      });
    }
  };

const inviteModeratorAction = (payload) => (dispatch, state) => {
  const curPopup = state().event.popup;
  if (curPopup) return;
  dispatch(
    eventModifiedData({
      name: "popup",
      data: payload,
    }),
  );
};

const inviteStreamAction = (payload) => (dispatch, state) => {
  const curPopup = state().event.popup;
  if (curPopup) return;
  dispatch(
    eventModifiedData({
      name: "popup",
      data: payload,
    }),
  );
};

export const pendingModeratorAction = (payload) => (dispatch, state) => {
  const curPending = state().event.moderators.pending;
  dispatch(
    eventDepthModifiedData({
      name: "moderators",
      depth: ["pending"],
      data: [
        ...curPending,
        {
          _id: payload._id,
          eventId: payload.typeId,
          user: { _id: payload.resourceId, username: payload.username },
        },
      ],
    }),
  );
};

export const pendingModeratorDeleteAction = (payload) => (dispatch, state) => {
  const newPending = state().event.moderators.pending.filter((fl) =>
    payload.type === "user"
      ? fl.user._id !== payload._id
      : fl._id !== payload._id,
  );
  dispatch(
    eventDepthModifiedData({
      name: "moderators",
      depth: ["pending"],
      data: newPending,
    }),
  );
};

export const acceptedGetModeratorAction = (payload) => (dispatch, state) => {
  const curPending = state().event.moderators.accepted;
  const eventId = state().event.info._id;
  dispatch(
    eventDepthModifiedData({
      name: "moderators",
      depth: ["accepted"],
      data: [
        ...curPending,
        {
          _id: payload._id,
          eventId,
          user: { _id: payload.typeId, username: payload.username },
        },
      ],
    }),
  );
};

export const acceptedModeratorAction = (payload) => (dispatch, state) => {
  const curPending = state().event.moderators.accepted;
  dispatch(
    eventDepthModifiedData({
      name: "moderators",
      depth: ["accepted"],
      data: [
        ...curPending,
        {
          _id: payload._id,
          eventId: payload.typeId,
          user: { _id: payload.resourceId, username: payload.username },
        },
      ],
    }),
  );
};

export const acceptedModeratorActionChange = (payload) => (dispatch, state) => {
  const eventId = state().event.info._id;
  const data = payload.map((m) => ({
    _id: m._id,
    eventId,
    user: {
      _id: m.type === "event" ? m.resourceId : m.typeId,
      username: m.username,
    },
  }));
  dispatch(
    eventDepthModifiedData({
      name: "moderators",
      depth: ["accepted"],
      data,
    }),
  );
};

export const acceptedModeratorDeleteAction = (payload) => (dispatch, state) => {
  const newPending = state().event.moderators.accepted.filter((fl) =>
    payload.type === "user"
      ? fl.user._id !== payload._id
      : fl._id !== payload._id,
  );
  dispatch(
    eventDepthModifiedData({
      name: "moderators",
      depth: ["accepted"],
      data: newPending,
    }),
  );
};

export const pendingPeopleAction = (payload) => (dispatch, state) => {
  const curPending = state().event.streams.pending;
  dispatch(
    eventDepthModifiedData({
      name: "streams",
      depth: ["pending"],
      data: [
        ...curPending,
        {
          _id: payload._id,
          eventId: payload.typeId,
          user: { _id: payload.resourceId, username: payload.username },
        },
      ],
    }),
  );
};

export const pendingPeopleDeleteAction = (payload) => (dispatch, state) => {
  const newPending = state().event.streams.pending.filter((fl) =>
    payload.type === "user"
      ? fl.user._id !== payload._id
      : fl._id !== payload._id,
  );
  dispatch(
    eventDepthModifiedData({
      name: "streams",
      depth: ["pending"],
      data: newPending,
    }),
  );
};

export const acceptedPeopleAction = (payload) => (dispatch, state) => {
  const curPending = state().event.streams.accepted;
  dispatch(
    eventDepthModifiedData({
      name: "streams",
      depth: ["accepted"],
      data: [
        ...curPending,
        {
          user: payload.user,
          clientConfig: payload.clientConfig,
          _id: payload._id,
          status: payload.status,
        },
      ],
    }),
  );
};

export const acceptedPeopleDeleteAction = (payload) => (dispatch, state) => {
  const newPending = state().event.streams.accepted.filter((fl) =>
    payload.type === "user"
      ? fl.user._id !== payload._id
      : fl._id !== payload._id,
  );
  dispatch(
    eventDepthModifiedData({
      name: "streams",
      depth: ["accepted"],
      data: newPending,
    }),
  );
};

export const unsubscribeAction = () => (dispatch, state) => {
  const { socket } = state().websocket;
  const {
    info: { _id: eventId },
  } = state().event;
  const {
    info: { _id: userId },
  } = state().user;

  socketEmit(socket, "unsubscribe-event", {
    eventId,
    userId,
  });
};

export const getDonates = () => (dispatch) => {
  cmsService.getDonates().then(({ data }) => {
    const newData = data.sort((a, b) => {
      var x = a.status;
      var y = b.status;
      return x < y ? -1 : x > y ? 1 : 0;
    });
    dispatch(eventModifiedData({ name: "donates", data: newData }));
  });
};
