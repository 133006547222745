import {
  acceptedModerator,
  acceptedModeratorDelete,
  acceptedPeople,
  acceptedPeopleDelete,
  eventDepthModifiedData,
  eventModifiedData,
  pendingModeratorDelete,
  pendingPeopleDelete,
} from "store/eventSlice";

export default class EventHandler {
  // example
  attendees = (indicator, args) => (dispatch) => {
    dispatch(
      eventDepthModifiedData({
        name: "info",
        depth: ["statistics", "viewers"],
        data: args.attendees,
      }),
    );
    dispatch(
      eventDepthModifiedData({
        name: "info",
        depth: ["statistics", "guest"],
        data: args.attendeesGuest,
      }),
    );
  };

  leaderboard = (indicator, args) => (dispatch, state) => {
    const {
      user: { id: userId },
      score: { raw },
    } = args.leaderboard;

    const data = state().event.rankings.list.map((x) => {
      if (x._id === userId) {
        return { ...x, raw: x.raw + raw, score: x.score + raw };
      }
      return x;
    });
    dispatch(
      eventDepthModifiedData({
        name: "rankings",
        depth: ["list"],
        data,
      }),
    );
  };

  update = (indicator, args) => (dispatch, state) => {
    const { info } = state().event;
    dispatch(
      eventModifiedData({ name: "info", data: { ...info, ...args.event } }),
    );
  };

  chat = (indicator, args) => (dispatch, state) => {
    switch (indicator) {
      case "banned":
        dispatch(
          eventDepthModifiedData({
            name: "info",
            depth: ["isBlocked"],
            data:
              state().user.info._id === args.userId
                ? true
                : state().event.info.isBlocked,
          }),
        );
        break;
      case "banned-delete":
        dispatch(
          eventDepthModifiedData({
            name: "info",
            depth: ["isBlocked"],
            data:
              state().user.info._id === args.userId
                ? false
                : state().event.info.isBlocked,
          }),
        );
        break;
      case "mute":
        if (state().user.info._id === args.userId) {
          dispatch(
            eventDepthModifiedData({
              name: "info",
              depth: ["isMuted"],
              data: true,
            }),
          );
        }
        break;
      case "mute-delete":
        if (state().user.info._id === args.userId) {
          dispatch(
            eventDepthModifiedData({
              name: "info",
              depth: ["isMuted"],
              data: false,
            }),
          );
          const { muteList } = state().event;
          const newMuteList = muteList.filter(
            (fl) => fl.typeId !== args.userId,
          );
          dispatch(eventModifiedData({ name: "muteList", data: newMuteList }));
        }
        break;
      default:
        break;
    }
  };

  action = (indicator, args) => (dispatch, state) => {
    switch (indicator) {
      case "voice": {
        const { accepted } = state().event.streams;
        const { _id: eventId } = state().event.info;
        const data = accepted.map((e) => {
          if (eventId === args.eventId && e.user._id === args.userId) {
            return {
              ...e,
              clientConfig: { ...e.clientConfig, voice: args.status },
            };
          }
          return e;
        });
        dispatch(
          eventDepthModifiedData({
            name: "streams",
            depth: ["accepted"],
            data: [...data],
          }),
        );
        break;
      }
      case "video": {
        const { accepted } = state().event.streams;
        const { _id: eventId } = state().event.info;
        const data = accepted.map((e) => {
          if (eventId === args.eventId && e.user._id === args.userId) {
            return {
              ...e,
              clientConfig: { ...e.clientConfig, video: args.status },
            };
          }
          return e;
        });
        dispatch(
          eventDepthModifiedData({
            name: "streams",
            depth: ["accepted"],
            data: [...data],
          }),
        );
        break;
      }
      case "chat": {
        dispatch(
          eventDepthModifiedData({
            name: "info",
            depth: ["status", "chat"],
            data: args.status,
          }),
        );
        break;
      }
      case "leaderboard": {
        dispatch(
          eventDepthModifiedData({
            name: "info",
            depth: ["status", "leaderboard"],
            data: args.status,
          }),
        );
        break;
      }
      case "advertise": {
        dispatch(
          eventDepthModifiedData({
            name: "info",
            depth: ["timestamps", "advertiseCooldown"],
            data: args.cooldown,
          }),
        );
        dispatch(
          eventDepthModifiedData({
            name: "info",
            depth: ["status", "advertising"],
            data: args.status ? "started" : "ended",
          }),
        );
        if (!args.status) {
          dispatch(
            eventDepthModifiedData({
              name: "advertising",
              depth: ["open"],
              data: 0,
            }),
          );
          dispatch(
            eventDepthModifiedData({
              name: "advertising",
              depth: ["close"],
              data: 0,
            }),
          );
          dispatch(
            eventDepthModifiedData({
              name: "advertising",
              depth: ["error"],
              data: 0,
            }),
          );
        }
        break;
      }
      case "poll": {
        dispatch(
          eventModifiedData({
            name: "poll",
            data: args.poll ? args.poll : {},
          }),
        );
        dispatch(
          eventDepthModifiedData({
            name: "info",
            depth: ["status", "poll"],
            data: args.poll ? "started" : "ended",
          }),
        );
        if (!args.poll) {
          dispatch(
            eventModifiedData({
              name: "pollStatus",
              data: "result",
            }),
          );
          dispatch(
            eventModifiedData({ name: "selectedPoll", data: args?.pollId }),
          );
          dispatch(
            eventModifiedData({
              name: "toolbarMenu",
              data: {
                trans: "result-list-poll",
                title: "title.poll_result_list",
              },
            }),
          );
        }
        break;
      }
      case "start-game": {
        dispatch(
          eventDepthModifiedData({
            name: "info",
            depth: ["status", "game"],
            data: "started",
          }),
        );
        break;
      }
      case "end-game": {
        dispatch(
          eventDepthModifiedData({
            name: "info",
            depth: ["status", "game"],
            data: "ended",
          }),
        );
        break;
      }
      case "end": {
        dispatch(eventModifiedData({ name: "endEvent", data: true }));
        dispatch(
          eventDepthModifiedData({
            name: "info",
            depth: ["status", "event"],
            data: "ended",
          }),
        );
        break;
      }
      case "pause": {
        dispatch(
          eventDepthModifiedData({
            name: "info",
            depth: ["status", "event"],
            data: "paused",
          }),
        );
        break;
      }
      case "start-stream": {
        dispatch(
          eventDepthModifiedData({
            name: "info",
            depth: ["status", "event"],
            data: "started",
          }),
        );
        break;
      }
      case "share-screen": {
        // dispatch(
        //   eventDepthModifiedData({
        //     name: "info",
        //     depth: ["status", "shareScreen"],
        //     data: args.status ? "started" : "pending",
        //   }),
        // );
        break;
      }
      case "rtmp": {
        dispatch(
          eventDepthModifiedData({
            name: "info",
            depth: ["status", "rtmpStream"],
            data: args.status,
          }),
        );
        setTimeout(() => {
          dispatch(
            eventDepthModifiedData({
              name: "info",
              depth: ["status", "event"],
              data: args.status ? "started" : "paused",
            }),
          );
        }, 0);
        break;
      }
      default:
        break;
    }
  };

  moderator = (indicator, args) => (dispatch, state) => {
    switch (indicator) {
      case "accepted-invite": {
        dispatch(pendingModeratorDelete({ _id: args.user._id, type: "user" }));
        dispatch(
          acceptedModerator({
            ...args.moderator,
            username: args.user.username,
          }),
        );
        if (state().user.info._id === args.user._id) {
          dispatch(
            eventDepthModifiedData({
              name: "info",
              depth: ["permissions"],
              data: args.moderator.permissions,
            }),
          );
        }
        break;
      }
      case "declined-invite": {
        dispatch(pendingModeratorDelete({ _id: args.user._id, type: "user" }));
        dispatch(eventModifiedData({ name: "popup", data: null }));
        break;
      }
      case "delete": {
        dispatch(acceptedModeratorDelete({ _id: args.userId, type: "user" }));
        if (state().user.info._id === args.user._id) {
          dispatch(
            eventDepthModifiedData({
              name: "info",
              depth: ["permissions"],
              data: args.permissions,
            }),
          );
        }
        break;
      }
      default:
        break;
    }
  };

  stream = (indicator, args) => (dispatch, state) => {
    switch (indicator) {
      case "accepted-invite":
        dispatch(pendingPeopleDelete({ _id: args.user._id, type: "user" }));
        dispatch(
          acceptedPeople({
            ...args.streamData,
          }),
        );
        if (state().user.info._id === args.user._id) {
          dispatch(
            eventDepthModifiedData({
              name: "info",
              depth: ["permissions", "stream"],
              data: true,
            }),
          );
        }
        break;
      case "declined-invite": {
        dispatch(pendingPeopleDelete({ _id: args.user._id, type: "user" }));
        break;
      }
      case "delete-user": {
        dispatch(acceptedPeopleDelete({ _id: args.userId, type: "user" }));
        if (args.userId === state().user.info._id)
          dispatch(
            eventDepthModifiedData({
              name: "info",
              depth: ["permissions", "stream"],
              data: false,
            }),
          );
        break;
      }
      default:
        break;
    }
  };

  donate = (indicator, args) => (dispatch, state) => {
    switch (indicator) {
      case "all": {
        break;
      }

      case "amount": {
        dispatch(
          eventModifiedData({ name: "walletDonateAmount", data: args.amount }),
        );
        break;
      }
      case "animation": {
        const { donateAnimation } = state().event;
        const updateCombo = (args, data) =>
          dispatch(
            eventDepthModifiedData({
              name: "donateAnimation",
              depth: [`${args}`],
              data,
            }),
          );

        if (args.donate.type === "combo") {
          const found = donateAnimation.combo.filter(
            (x) => x.donate.donateId === args.donate.donateId,
          );
          if (found.length) {
            const updateItem = donateAnimation.combo.map((x) => {
              if (x.donate.donateId === args.donate.donateId) {
                return args;
              } else return x;
            });
            updateCombo(args.donate.type, updateItem);
          } else {
            const addItems = [...donateAnimation.combo, args];
            updateCombo(args.donate.type, addItems);
          }
        } else {
          dispatch(
            eventDepthModifiedData({
              name: "donateAnimation",
              depth: [`${args.donate.type}`],
              data: [...donateAnimation[args.donate.type], args],
            }),
          );
        }
        break;
      }
      default:
        break;
    }
  };

  ad = (indicator, args) => (dispatch, state) => {
    if (indicator === "session") {
      switch (args.key) {
        case "open": {
          const openCount = state().event.advertising.open + 1;
          dispatch(
            eventDepthModifiedData({
              name: "advertising",
              depth: ["open"],
              data: openCount,
            }),
          );
          break;
        }
        case "close": {
          const openCount = state().event.advertising.open - 1;
          dispatch(
            eventDepthModifiedData({
              name: "advertising",
              depth: ["open"],
              data: openCount,
            }),
          );
          const closeCount = state().event.advertising.close + 1;
          dispatch(
            eventDepthModifiedData({
              name: "advertising",
              depth: ["close"],
              data: closeCount,
            }),
          );
          break;
        }
        case "error": {
          const errCount = state().event.advertising.error + 1;
          dispatch(
            eventDepthModifiedData({
              name: "advertising",
              depth: ["error"],
              data: errCount,
            }),
          );
          break;
        }

        default:
          break;
      }
    }
  };

  prize = (indicator, args) => (dispatch, state) => {
    dispatch(
      eventDepthModifiedData({
        name: "info",
        depth: ["statistics", "prizePool"],
        data: args.value,
      }),
    );
    dispatch(
      eventModifiedData({
        name: "prizeAnimation",
        data: true,
      }),
    );
  };

  alivestatus = (indicator, args) => () => {
    // console.log("indicator,args", indicator, args);
  };
}
