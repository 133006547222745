import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { routesUrl } from "routes/routes-url";
import { Border } from "components/UI/Border";
import ScrollView from "components/ScrollView";
import SearchItem from "./searchItem";

const HeaderSearchDropdown = ({ results, handleSearchResult, ...rest }) => {
  console.log("12313123123132", results);
  const navigate = useNavigate();

  const goToSearch = useCallback((data) => {
    console.log("data", data);
    handleSearchResult();
    navigate(`${routesUrl.search}?q=${data.name}`);
  }, []);

  return (
    <Border
      as="ul"
      bg="darkJungleGreen"
      borderWidth={0}
      borderTopWidth={1}
      borderBottomRightRadius={16}
      borderBottomLeftRadius={16}
      borderColor="purpleNavy"
      width={[200, 220, 250, 290, 300]}
      sx={{
        position: "absolute",
        transition: "0.5s ease",
        animationName: "searchdropdown",
        animationTimingFunction: "ease",
        animationDuration: "0.5s",
        zIndex: 10,
      }}
      {...rest}
    >
      <ScrollView maxHeight="224px" px={32}>
        {results.length ? (
          results.map((resultItem) => (
            <SearchItem
              key={resultItem._id}
              item={resultItem}
              onClick={() => goToSearch(resultItem)}
            />
          ))
        ) : (
          <SearchItem item={{ name: "No results" }} />
        )}
      </ScrollView>
    </Border>
  );
};

export default HeaderSearchDropdown;
