import React from "react";
import Icon from "components/UI/Icon";
import { Box, Flex, Text } from "components/UI/CustomRebass";
import { useTranslation } from "react-i18next";
import Button from "components/UI/Button";
import { setLocalStorage } from "config/utils";
import { useDispatch } from "react-redux";
import { notificationFirebase } from "store/actions/notificationAction";
import Modal from "components/UI/Modal";
import { useModal } from "config/hooks/useModal";

const NotificationPermission = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isModalVisible, openModal, closeModal } = useModal();

  const goToNotification = () => {
    if (Notification.permission === "denied") {
      openModal();
    } else {
      setLocalStorage("notificationPermission", true);
      dispatch(
        notificationFirebase({ name: "notificationPermission", data: true }),
      );
    }
  };
  return (
    <Box>
      <Flex flexDirection="column" alignItems="center">
        <Icon
          icon="icon icon-bell"
          fontSize={76}
          color="vloudBurst"
          sx={{ cursor: "pointer" }}
        />
        <Text mt={20} mb={12} fontSize={18} color="white">
          {t("title.permissionNeeded")}
        </Text>
        <Text mx={32} fontSize={14}>
          {t("description.allow_notifications_description")}
        </Text>
        <Box px={76} pt={24} pb={28}>
          <Button
            onClick={goToNotification}
            py={8}
            px={40}
            height={52}
            text="Allow" // TOOD: fix lang
            sx={{
              // TOOD: fix button style
              "&:hover": {
                transform: "scale(1.02)",
              },
            }}
          />
        </Box>
      </Flex>
      <Modal
        title="permission.notification_denied"
        visible={isModalVisible}
        onCancel={closeModal}
        width={510}
        style={{ maxHeight: "90vh" }}
      >
        <Flex justifyContent="center" alignItems="center">
          <Icon icon="icon-block" fontSize={35} color="magicPotion" mr={20} />

          <Text color="lavenderBlue">
            {t("permission.notification_description")}
          </Text>
        </Flex>
      </Modal>
    </Box>
  );
};

export default NotificationPermission;
