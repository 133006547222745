import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { Border } from "components/UI/Border";
import CustomEmpty from "components/UI/CustomEmpty";
import { Box, Flex, Text } from "components/UI/CustomRebass";
import Icon from "components/UI/Icon";
import Popover from "components/UI/Popover";
import { useTranslation } from "react-i18next";
import NotificationItem from "./notificationItem";
import NotificationPermission from "./notificationPermission";
import ScrollView from "components/ScrollView";
import Loading from "components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { notificationModifiedData } from "store/notificationSlice";

const IconStyled = styled(Box)`
  .show {
    border-bottom: 2px solid;
    padding-bottom: 26px;
  }
`;

const HeaderNotification = () => {
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // TODO: perms localstorage baglanacak.
  const { notify, notifications, notificationPermission } = useSelector(
    ({ notification }) => notification,
  );
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (visiblePopover === true) {
      visiblePopoverChange();
    }
  }, [location]);

  const visiblePopoverChange = () => {
    setVisiblePopover(!visiblePopover);
  };
  useEffect(() => {
    setIsLoading(true);
    if (visiblePopover) {
      notifications.length > 0 && setIsLoading(false);
      dispatch(notificationModifiedData({ name: "notify", data: false }));
    }
  }, [visiblePopover, notifications]);

  return (
    <Popover
      placement="bottom"
      mode="headerShort"
      visible={visiblePopover}
      onVisibleChange={() => visiblePopoverChange()}
      content={
        <Border width={330} height={360} borderRadius={19} borderWidth={0}>
          <Text
            mt={8}
            mx={80}
            mb={20}
            fontSize={22}
            color="white"
            sx={{ textAlign: "center" }}
          >
            {t("title.notification")}
          </Text>
          <ScrollView maxHeight="300px" px={16}>
            <Box>
              {!notificationPermission ? (
                <NotificationPermission />
              ) : isLoading && notifications === null ? (
                <Flex justifyContent="center">
                  <Loading width={50} height={50} />
                </Flex>
              ) : notifications && notifications.length > 0 ? (
                notifications.map((item, ind) => (
                  <NotificationItem key={ind} notificationItem={item} />
                ))
              ) : (
                notifications !== null &&
                notifications.length === 0 && (
                  <CustomEmpty
                    icon="icon-bell"
                    message="description.there_are_currently_no_notifications"
                    flexDirection="column"
                    my={60}
                    textOpt={{ pt: 32, fontSize: 16 }}
                    iconOpt={{ fontSize: 76, color: "ceil" }}
                  />
                )
              )}
            </Box>
          </ScrollView>
        </Border>
      }
    >
      <IconStyled sx={{ position: "relative" }} ml={[15, 15, 20, 24]}>
        {notify && (
          <Box
            sx={{
              position: "absolute",
              right: "-4px",
              top: "-4px",
              borderRadius: "14px",
              zIndex: "1",
            }}
            width={14}
            height={14}
            bg="magicPotion"
          ></Box>
        )}
        <Icon
          icon={visiblePopover ? "icon icon-bell-fill show" : "icon icon-bell"}
          fontSize={26}
          color={visiblePopover ? "electricBlue" : "vloudBurst"}
          sx={{ cursor: "pointer" }}
        />
      </IconStyled>
    </Popover>
  );
};

export default HeaderNotification;
