import { createSlice } from "@reduxjs/toolkit";
import { depthItemModified } from "config/utils";
import {
  acceptedModeratorAction,
  acceptedModeratorDeleteAction,
  acceptedPeopleAction,
  acceptedPeopleDeleteAction,
  pendingModeratorAction,
  pendingModeratorDeleteAction,
  pendingPeopleAction,
  pendingPeopleDeleteAction,
  unsubscribeAction,
} from "./actions/eventAction";

const initialState = {
  info: {},
  chat: [],
  donateAnimation: {
    combo: [],
    self: []
  },
  streams: {
    accepted: [],
    pending: [],
  },
  moderators: {
    accepted: [],
    pending: [],
  },
  poll: {},
  polls: null,
  pollLoading: false,
  pollStatus: null,
  selectedPoll: null,
  toolbarMenu: { trans: "default", title: "Toolbar" },
  rankings: {
    list: [],
    meta: null,
    user: null,
  },
  popup: null,
  donates: [],
  games: null,
  advertising: {
    open: 0,
    close: 0,
    error: 0,
  },
  isControl: false,
  isBrowserStreamPermission: { status: true, audio: false, video: false },
  gamepadQr: null,
  screen: [],
  endEvent: false,
  donateInitialize: false,
  muteList: [],
  prizeAnimation: false,
  walletAnimation: false,
  moneybox: {
    showPopup: false,
    prize: 0,
  },
  peer: {},
  player: {},
  walletDonateAmount: 0,
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    eventModifiedData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
    eventUpdateData: (state, action) => {
      state[action.payload.name] = {
        ...state[action.payload.name],
        ...action.payload.data,
      };
    },
    eventDepthModifiedData: (state, action) =>
      depthItemModified(
        state,
        action.payload.name,
        action.payload.depth,
        action.payload.data,
      ),
    eventAddUpdateModifiedData: (state, action) => {
      const exist = state[action.payload.name].filter(
        (fl) => fl[action.payload.item.name] === action.payload.item.data,
      );
      state[action.payload.name] =
        exist.length > 0
          ? state[action.payload.name]
              .map((m) =>
                m[action.payload.item.name] === action.payload.item.data
                  ? action.payload.data
                  : m,
              )
              .sort(
                (a, b) =>
                  b.detail.unreadMessageCount - a.detail.unreadMessageCount,
              )
          : [action.payload.data, ...state[action.payload.name]];
    },
    eventAddData: (state, action) => {
      state[action.payload.name] = [
        ...state[action.payload.name],
        action.payload.data,
      ];
    },
    eventResetData: () => initialState,
  },
});

export const {
  eventModifiedData,
  eventUpdateData,
  eventDepthModifiedData,
  eventAddData,
  eventResetData,
  eventAddUpdateModifiedData,
} = eventSlice.actions;

export const selectEvent = (state) => state.event;

export const pendingModerator = pendingModeratorAction;
export const pendingModeratorDelete = pendingModeratorDeleteAction;
export const acceptedModerator = acceptedModeratorAction;
export const acceptedModeratorDelete = acceptedModeratorDeleteAction;

export const pendingPeople = pendingPeopleAction;
export const pendingPeopleDelete = pendingPeopleDeleteAction;
export const acceptedPeople = acceptedPeopleAction;
export const acceptedPeopleDelete = acceptedPeopleDeleteAction;

export const unsubscribe = unsubscribeAction;

export default eventSlice.reducer;
