import React from "react";
import { Border } from "components/UI/Border";
import { Text } from "components/UI/CustomRebass";
import Icon from "components/UI/Icon";
import { useTranslation } from "react-i18next";

const SearchUserNoResult = () => {
  const { t } = useTranslation();
  return (
    <Border
      display="flex"
      alignItems="center"
      color="white"
      justifyContent="center"
      bg="independence"
      borderWidth={0}
      borderTopWidth={2}
      borderColor="reinza"
      width="100%"
      py={15}
    >
      <Icon icon="icon-user-group" fontSize={[13, 13, 16, 25, 20]} mr={10} />
      <Text fontSize={[8, 8, 10, 12, 14]}>
        {t("description.user_was_not_found")}
      </Text>
    </Border>
  );
};

export default SearchUserNoResult;
