import { configureStore } from "@reduxjs/toolkit";
import mainReducer from "./mainSlice";
import websocketReducer from "./websocketSlice";
import eventReducer from "./eventSlice";
import inboxReducer from "./inboxSlice";
import profileReducer from "./profileSlice";
import authReducer from "./authSlice";
import userReducer from "./userSlice";
import walletReducer from "./walletSlice";
import cacheReducer from "./cacheSlice";
import notificationReducer from "./notificationSlice";
import chatSocketReducer from "./chatSocketSlice";
import settingSlice from "./settingSlice";

export default configureStore({
  reducer: {
    main: mainReducer,
    websocket: websocketReducer,
    event: eventReducer,
    inbox: inboxReducer,
    profile: profileReducer,
    auth: authReducer,
    user: userReducer,
    wallet: walletReducer,
    cache: cacheReducer,
    notification: notificationReducer,
    chatSocket: chatSocketReducer,
    setting: settingSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
