import React from "react";
import styled from "@emotion/styled";
import { Box } from "components/UI/CustomRebass";
import HeaderInbox from "./HeaderInbox/headerInbox";
import HeaderNotification from "./HeaderNotification/headerNotification";

const ActiveMenu = styled(Box)`
  .active {
    &:before {
      content: "";
      bottom: -7px;
      left: 0;
      position: absolute;
      background-color: #64f6ff;
      height: 2px;
      width: ${(props) => props.lineWidth && props.lineWidth}px;
      transition: all 0.2s ease;
    }
    i {
      color: white;
    }
    span {
      color: white;
    }
  }
  i:hover {
    animation-name: bounce-4;
    animation-timing-function: ease;
    animation-duration: 0.7s;
  }
`;

const HeaderNotifications = () => {
  return (
    <Box
      ml={[25, 25, 30, 10, 90, 118]}
      display="flex"
      alignItems="center"
      pt={7}
    >
      <ActiveMenu activeColor="white">
        <HeaderInbox />
        <HeaderNotification />
      </ActiveMenu>
    </Box>
  );
};

export default HeaderNotifications;
