import axiosClient from "plugins/axios";

const { makeRequest, makeRequestAuth } = axiosClient(
  process.env.REACT_APP_BASE_API_ENDPOINT,
);

const DEFAULTURLS = {
  EVENTS: "events",
  EVENT: "event",
  EVENTACTION: "event/actions",
  POLLS: "polls",
  POLL: "poll",
  TOKEN: "token",
  INVITE: "invite",
  INVITATIONS: "invitations",
  SHOWCASES: "showcases",
  EVENTACTIONS: "actions",
  STREAMS: "streams",
};
export default class EventService {
  // ++
  getEvent = (id, authenticated, params) =>
    authenticated
      ? makeRequestAuth("get", `${DEFAULTURLS.EVENTS}/${id}`, { params })
      : makeRequest("get", `${DEFAULTURLS.EVENTS}/${id}`, { params });
  // ++
  getForYou = () => makeRequestAuth("get", `${DEFAULTURLS.SHOWCASES}/user`);
  //++
  getTrending = () => makeRequest("get", `${DEFAULTURLS.SHOWCASES}/trending`);
  //++
  getTrendingAuth = () =>
    makeRequestAuth("get", `${DEFAULTURLS.SHOWCASES}/trending`);
  //++
  getIncoming = () => makeRequest("get", `${DEFAULTURLS.SHOWCASES}/incoming`);
  //++
  getIncomingAuth = () =>
    makeRequestAuth("get", `${DEFAULTURLS.SHOWCASES}/incoming`);
  //++
  getBestStreamers = () =>
    makeRequest("get", `${DEFAULTURLS.SHOWCASES}/beststreamer`);
  //++
  createEvent = (data) => makeRequestAuth("post", DEFAULTURLS.EVENTS, { data });

  // STREAM INTEGRATION
  createEventGdlive = (data) =>
    makeRequestAuth("post", `${DEFAULTURLS.EVENTS}/gdlive`, { data });
  //++
  getEventsTopVideo = (params) =>
    makeRequest("get", `${DEFAULTURLS.SHOWCASES}/top`, { params });
  //++
  getEventBySearch = (params) =>
    makeRequest("get", `${DEFAULTURLS.EVENTS}/search`, { params });
  // ++
  search = (params) => makeRequest("get", DEFAULTURLS.EVENTS, { params });
  //++
  putStreamVideo = (eventId, userId, status) =>
    makeRequestAuth(
      "put",
      `${DEFAULTURLS.EVENTACTIONS}/${eventId}/video/${userId}/${status}`,
    );
  //++
  putStreamVoice = (eventId, userId, status) =>
    makeRequestAuth(
      "put",
      `${DEFAULTURLS.EVENTACTIONS}/${eventId}/voice/${userId}/${status}`,
    );
  //++
  putStreamChat = (eventId, status) =>
    makeRequestAuth(
      "put",
      `${DEFAULTURLS.EVENTACTIONS}/${eventId}/chat/${status}`,
    );
  //++
  putStreamLeaderboard = (eventId, status) =>
    makeRequestAuth(
      "put",
      `${DEFAULTURLS.EVENTACTIONS}/${eventId}/leaderboard/${status}`,
    );
  //++
  putStreamAdvertise = (eventId, status) =>
    makeRequestAuth(
      "put",
      `${DEFAULTURLS.EVENTACTIONS}/${eventId}/advertise/${status}`,
    );
  //++
  putStreamPause = (eventId) =>
    makeRequestAuth("put", `${DEFAULTURLS.EVENTACTIONS}/${eventId}/pause`);
  //++
  putStreamEnd = (eventId, data) =>
    makeRequestAuth("put", `${DEFAULTURLS.EVENTACTIONS}/${eventId}/end`, {
      data,
    });
  //++
  getStreams = (eventId) =>
    makeRequest("get", `${DEFAULTURLS.STREAMS}/${eventId}/event`);
  //++
  putEvent = (id, data) =>
    makeRequest("put", `${DEFAULTURLS.EVENTS}/${id}`, { data });
  //++
  getPoll = (eventId) =>
    makeRequest("get", `${DEFAULTURLS.POLLS}/${eventId}/event`);
  //++
  getPollDetail = (pollId) =>
    makeRequest("get", `${DEFAULTURLS.POLLS}/${pollId}`);
  //++
  getPolls = (eventId, params) =>
    makeRequest("get", `${DEFAULTURLS.POLLS}?eventId=${eventId}`, {
      params,
    });
  //++
  createPoll = (data) => makeRequestAuth("post", DEFAULTURLS.POLLS, { data });
  //++
  updatePoll = (pollId, data) =>
    makeRequestAuth("put", `${DEFAULTURLS.POLLS}/${pollId}`, { data });
  //++
  sharePoll = (pollId, data) =>
    makeRequestAuth("put", `${DEFAULTURLS.POLLS}/${pollId}/share`);
  //++
  endPoll = (pollId) =>
    makeRequestAuth("put", `${DEFAULTURLS.POLLS}/${pollId}/ended`);
  //++
  deletePoll = (pollId) =>
    makeRequestAuth("delete", `${DEFAULTURLS.POLLS}/${pollId}`);
  //++
  answerPoll = (pollId, index) =>
    makeRequestAuth("put", `${DEFAULTURLS.POLLS}/${pollId}/${index}/answer`);
  //++
  getGameToken = (eventId, authenticated) =>
    authenticated
      ? makeRequestAuth("get", `${DEFAULTURLS.EVENTS}/token/${eventId}`)
      : makeRequest("get", `${DEFAULTURLS.EVENTS}/token/${eventId}`);
  //++
  game = (id, status) =>
    makeRequestAuth("put", `${DEFAULTURLS.EVENTACTIONS}/${id}/game/${status}`);
  //++
  invite = (userId, data) =>
    makeRequestAuth("post", `${DEFAULTURLS.INVITE}/${userId}`, { data });
  //++
  deletePeople = (eventId, data) =>
    makeRequestAuth("delete", `${DEFAULTURLS.STREAMS}/${eventId}/user/stream`, {
      data,
    });
  //++
  answerInvite = (data) =>
    makeRequestAuth("put", `${DEFAULTURLS.INVITE}/answer`, { data });
  //++
  getAttendees = (authenticated, eventId, params) =>
    authenticated
      ? makeRequestAuth("get", `${DEFAULTURLS.EVENTS}/${eventId}/attendees`, {
          params,
        })
      : makeRequest("get", `${DEFAULTURLS.EVENTS}/${eventId}/attendees`, {
          params,
        });
  //++
  putStream = (eventId, status) =>
    makeRequestAuth(
      "put",
      `${DEFAULTURLS.EVENTACTIONS}/${eventId}/stream/${status}`,
    );
  //STREAM INTEGRATION
  putGdliveStream = (eventId, status) =>
    makeRequestAuth(
      "put",
      `${DEFAULTURLS.EVENTACTIONS}/${eventId}/stream/${status}gdlive`,
    );
  //++

  getStreamToken = (eventId, authenticated) =>
    authenticated
      ? makeRequestAuth("get", `${DEFAULTURLS.STREAMS}/${eventId}/stream/token`)
      : makeRequest("get", `${DEFAULTURLS.STREAMS}/${eventId}/stream/token`);
  //++
  eventActivity = (id) =>
    makeRequestAuth("put", `${DEFAULTURLS.EVENTS}/${id}/activity`);
  //++
  shareScreen = (id, status) =>
    makeRequestAuth(
      "put",
      `${DEFAULTURLS.EVENTACTIONS}/${id}/screen/${status}`,
    );

  postDonate = (data) => makeRequestAuth("post", "donations/donate", { data });
  myEvents = () => makeRequestAuth("get", `${DEFAULTURLS.EVENTS}/me`);

  checkEventPassword = (data) =>
    makeRequestAuth("post", `${DEFAULTURLS.EVENTS}/check_password`, { data });

  getGDLiveStreamToken = (eventId, authenticated) =>
    authenticated
      ? makeRequestAuth(
          "get",
          `${DEFAULTURLS.STREAMS}/${eventId}/stream/gdlive/token`,
        )
      : makeRequest(
          "get",
          `${DEFAULTURLS.STREAMS}/${eventId}/stream/gdlive/token`,
        );
}
