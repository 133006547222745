import React, { lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// import DefaultLayout from "components/Layouts";
// import LoginLayout from "components/Layouts";
// import EventPageLayout from "components/Layouts";
// import WalletLayout from "components/Layouts";

import {
  DefaultLayout,
  LoginLayout,
  EventPageLayout,
  WalletLayout,
} from "components/Layouts";

import CustomSuspense from "components/CustomSuspense";
import { routesUrl } from "./routes-url";
import UnauthRoute from "./Guards/UnauthRoute";
import AuthRoute from "./Guards/AuthRoute";
import Settings from "containers/Settings";
import { hotjar } from "react-hotjar";
import ContactLayout from "components/Layouts/ContactLayout";
import AutoCloseLayout from "components/Layouts/AutoCloseLayout";

const AboutUs = lazy(() => import("containers/Content/AboutUs"));
const CookieStatement = lazy(() =>
  import("containers/Content/CookieStatement"),
);
const PrivacyPolicy = lazy(() => import("containers/Content/PrivacyPolicy"));
const Contact = lazy(() => import("containers/Content/Contact"));
const ConditionOfUse = lazy(() => import("containers/Content/ConditionOfUse"));
const EventPage = lazy(() => import("containers/EventPage"));
const HomePage = lazy(() => import("containers/HomePage"));
const Inbox = lazy(() => import("containers/Inbox"));
const SignIn = lazy(() => import("containers/Login/SignIn"));
const SignUp = lazy(() => import("containers/Login/SignUp"));
const ForgotPassword = lazy(() => import("containers/Login/ForgotPassword"));
const ResetPassword = lazy(() => import("containers/Login/ResetPassword"));
const Profile = lazy(() => import("containers/Profile"));
const Rankings = lazy(() => import("containers/Rankings"));
const SearchPage = lazy(() => import("containers/SearchPage"));
const Wallet = lazy(() => import("containers/Wallet"));
const VideoPage = lazy(() => import("containers/VideoPage"));
const NotFound = lazy(() => import("components/DefaultPages/NotFound"));

const Router = () => {
  useEffect(() => {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_SITE_ID, "1.0.0");
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <CustomSuspense>
              <DefaultLayout />
            </CustomSuspense>
          }
        >
          <Route
            path={routesUrl.home}
            element={
              <React.Suspense fallback={<div>Loading</div>}>
                <HomePage />
              </React.Suspense>
            }
          />
          <Route
            path={routesUrl.rankings}
            element={
              <CustomSuspense>
                <Rankings />
              </CustomSuspense>
            }
          />

          <Route
            path={routesUrl.video}
            element={
              <CustomSuspense>
                <VideoPage />
              </CustomSuspense>
            }
          />
          <Route
            path={routesUrl.profilePage}
            element={
              <CustomSuspense>
                <Profile />
              </CustomSuspense>
            }
          />
          <Route
            path={routesUrl.search}
            element={
              <CustomSuspense>
                <SearchPage />
              </CustomSuspense>
            }
          />

          <Route
            path={routesUrl.settings}
            element={
              <CustomSuspense>
                <AuthRoute>
                  <Settings />
                </AuthRoute>
              </CustomSuspense>
            }
          />
        </Route>

        <Route element={<ContactLayout />}>
          <Route
            path={routesUrl.aboutUs}
            element={
              <CustomSuspense>
                <AboutUs />
              </CustomSuspense>
            }
          />
          <Route
            path={routesUrl.privacyPolicy}
            element={
              <CustomSuspense>
                <PrivacyPolicy />
              </CustomSuspense>
            }
          />
          <Route
            path={routesUrl.conditionOfUse}
            element={
              <CustomSuspense>
                <ConditionOfUse />
              </CustomSuspense>
            }
          />
          <Route
            path={routesUrl.cookieStatement}
            element={
              <CustomSuspense>
                <CookieStatement />
              </CustomSuspense>
            }
          />
          <Route
            path={routesUrl.contact}
            element={
              <CustomSuspense>
                <Contact />
              </CustomSuspense>
            }
          />
        </Route>

        <Route element={<WalletLayout />}>
          <Route
            path={routesUrl.wallet}
            element={
              <CustomSuspense>
                <AuthRoute>
                  <Wallet />
                </AuthRoute>
              </CustomSuspense>
            }
          />
        </Route>

        <Route element={<EventPageLayout />}>
          <Route
            path={routesUrl.eventPage}
            element={
              <CustomSuspense>
                <EventPage />
              </CustomSuspense>
            }
          />
          <Route
            path={routesUrl.inbox}
            element={
              <CustomSuspense>
                <AuthRoute>
                  <Inbox />
                </AuthRoute>
              </CustomSuspense>
            }
          />
        </Route>

        <Route element={<LoginLayout />}>
          <Route
            path={routesUrl.signIn}
            element={
              <CustomSuspense>
                <UnauthRoute>
                  <SignIn />
                </UnauthRoute>
              </CustomSuspense>
            }
          />
          <Route
            path={routesUrl.signUp}
            element={
              <CustomSuspense>
                <UnauthRoute>
                  <SignUp />
                </UnauthRoute>
              </CustomSuspense>
            }
          />
          <Route
            path={routesUrl.forgotPassword}
            element={
              <CustomSuspense>
                <UnauthRoute>
                  <ForgotPassword />
                </UnauthRoute>
              </CustomSuspense>
            }
          />
          <Route
            path={routesUrl.resetPassword}
            element={
              <CustomSuspense>
                <UnauthRoute>
                  <ResetPassword />
                </UnauthRoute>
              </CustomSuspense>
            }
          />
        </Route>
        <Route
          path={routesUrl.notFound}
          element={
            <CustomSuspense>
              <NotFound />
            </CustomSuspense>
          }
        />

        <Route path={routesUrl.payment} element={<AutoCloseLayout />} />
        <Route path="*" element={<Navigate to={routesUrl.notFound} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
