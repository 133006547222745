import withFormLabel from "../withFormLabel";

import SingleInput from "./SInput";
import SinglePasswordInput from "./SPasswordInput";
import SingleRadio from "./SRadio";
import SingleSelect from "./SSelect";
import SingleSwitch from "./SSwitch";
import SingleTextarea from "./STextarea";
import SingleDatepicker from "./SDatepicker";
import SingleTimepicker from "./STimepicker";
import SingleCheckbox from "./SCheckBox";
import SingleCheckboxGroup from "./SCheckboxGroup";

export const SInput = withFormLabel(SingleInput);
export const SPasswordInput = withFormLabel(SinglePasswordInput);
export const SRadio = withFormLabel(SingleRadio);
export const SSelect = withFormLabel(SingleSelect);
export const SSwitch = withFormLabel(SingleSwitch);
export const STextarea = withFormLabel(SingleTextarea);
export const SDatepicker = withFormLabel(SingleDatepicker);
export const STimepicker = withFormLabel(SingleTimepicker);
export const SCheckBox = withFormLabel(SingleCheckbox);
export const SCheckboxGroup = withFormLabel(SingleCheckboxGroup);
