import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { userImageUrl } from "config/utils";
import { UserService } from "services";
import { ButtonAnimation } from "assets/styled/animationClass";
import { Context as BlockedPeopleContext } from "context/BlockedPeopleContext";
import { Box, Flex, Image, Text } from "components/UI/CustomRebass";
import Modal from "components/UI/Modal";
import Button from "components/UI/Button";

const userService = new UserService();

const BlockUserModal = ({ item, type, isModalVisible, closeModal }) => {
  const { t } = useTranslation();

  const {
    state: { isBlocked, blockList, blockSearchList },
    updateBlockedPeople,
  } = useContext(BlockedPeopleContext);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    const serviceUrl = item.isBlocked ? "unBlockByUserId" : "blockByUserId";
    const data = {
      type: "user",
      typeId: item.isBlocked
        ? item.typeId
          ? item.typeId
          : item._id
        : item._id,
    };
    userService[serviceUrl](data).then(() => {
      if (item.isBlocked) {
        const filteredList = blockList.filter(
          (x) => x.username !== item.username,
        );
        updateBlockedPeople({ isBlocked: !isBlocked, blockList: filteredList });
      } else {
        updateBlockedPeople({
          isBlocked: !isBlocked,
          blockList: [...blockList, { ...item, isBlocked: true }],
        });
      }

      let newSearchList = blockSearchList.map((x) => {
        if (x.username === item.username) {
          return {
            ...x,
            isBlocked: !x.isBlocked,
          };
        } else {
          return x;
        }
      });
      updateBlockedPeople({
        isBlocked: !isBlocked,
        blockSearchList: newSearchList,
      });
      setIsLoading(false);
      closeModal();
    });
  };
  return (
    <Box pl={20}>
      <Modal
        title={item.isBlocked ? "title.unBlock" : "title.block"}
        visible={isModalVisible}
        onCancel={closeModal}
        width={610}
        style={{ maxHeight: "90vh" }}
      >
        <Box>
          <Flex
            pl={20}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              width={126}
              height={126}
              mt={78}
              mb={49}
              src={userImageUrl(item?.username, "profile")}
              sx={{
                borderRadius: "50%",
              }}
            />
            <Text fontSize={18} mb={57} color="lavenderBlue" textAlign="center">
              <Text
                dangerouslySetInnerHTML={{
                  __html: t("description.are_you_sure_you_want_to_block", {
                    block: item.isBlocked
                      ? t("title.unBlock")
                      : t("title.block"),
                    username: item?.username,
                  }),
                }}
              />
            </Text>
          </Flex>

          <Flex>
            <Button
              flex="1"
              mode="outinline"
              height={72}
              onClick={closeModal}
              text="title.no"
              fontSize={16}
              fontWeight={600}
              mr={8}
              sx={{
                borderRadius: 16,
              }}
            />
            <ButtonAnimation
              loading={isLoading}
              flex="1"
              onClick={handleSubmit}
              text="title.yes"
              fontSize={16}
              fontWeight={600}
              mode="cyan"
              px={40}
              py={8}
              height={72}
              sx={{
                borderRadius: 16,
              }}
            />
          </Flex>
        </Box>
      </Modal>
    </Box>
  );
};

export default BlockUserModal;
