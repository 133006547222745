import React from "react";
import { Box, Text } from "components/UI/CustomRebass";
import Background from "components/UI/Background";
import { Border } from "components/UI/Border";
import { Link } from "components/UI/Link";
import { useSelector } from "react-redux";
import { userImageUrl } from "config/utils";
import { useTranslation } from "react-i18next";

const ProfilePopup = () => {
  const { info } = useSelector(({ user }) => user);
  const { r } = useSelector(({ cache }) => cache);
  const { t } = useTranslation();

  return (
    <Border
      display="flex"
      py={18}
      borderColor="independence"
      borderWidth={0}
      borderBottomWidth={1}
      mb={18}
    >
      <Background
        url={userImageUrl(info.username, "profile", r)}
        height={68}
        minWidth={68}
        mr={8}
        sx={{
          backgroundSize: "cover",
          borderRadius: "50%",
        }}
      ></Background>
      <Box>
        <Text
          fontSize={14}
          color="white"
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${info.name || ""} ${info.lastname || ""}`}
        </Text>
        <Text
          fontSize={12}
          color="lavenderBlue"
          mr={30}
          mb={8}
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {info.email}
        </Text>
        {/* TODO // fix routesurl */}
        <Link href={`/profile/${info.username}`}>
          <Text
            fontSize={12}
            color="lavenderBlue"
            mr={30}
            mb={8}
            sx={{ textDecoration: "underline" }}
          >
            {t("title.go_to_profile")}
          </Text>
        </Link>
      </Box>
    </Border>
  );
};

export default ProfilePopup;
