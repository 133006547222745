import { notification } from "antd";
import { responseNotify } from "config/utils";
import { useTheme } from "emotion-theming";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { EventService } from "services";
import { socketEmit } from "store/actions/socketAction";
import {
  chatSocketConnect,
  chatSocketModifiedData,
} from "store/chatSocketSlice";
import { eventModifiedData } from "store/eventSlice";
import InvateStreamNotifly from "./components/InvateStreamNotifly";
import InviteModeratorNoifly from "./components/InviteModeratorNoifly";

const eventService = new EventService();

const InvitationNotifly = () => {
  const { colors } = useTheme();

  const { popup } = useSelector(({ event }) => event);
  const { t } = useTranslation();
  const { id: eventId } = useParams();
  const [req, setreq] = useState(null);
  const [key] = useState(`open${Date.now()}`);

  const { r } = useSelector(({ cache }) => cache);
  const { socket } = useSelector(({ chatSocket }) => chatSocket);
  const { socket: ws } = useSelector(({ websocket }) => websocket);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAnswerModerator = (answer) => {
    const data = {
      answer,
      invitationId: popup._id,
    };
    if (answer && popup.type === "event_moderator") {
      eventService.answerInvite(data).then(() => {
        if (eventId !== popup.typeId) {
          dispatch(chatSocketModifiedData({ name: "unmount", data: true }));
          if (socket) {
            socketEmit(socket, "chat:leave", { eventId });
            socket.close();
          }
          dispatch(chatSocketConnect(popup.typeId));
          socketEmit(ws, "unsubscribe-event", {
            eventId,
            userId: popup.resourceId,
          });
        }
        notification.close(key);
        navigate(`/event/${popup.typeId}`);
      });
    } else {
      eventService.answerInvite(data);
      notification.close(key);
    }
    dispatch(eventModifiedData({ name: "popup", data: null }));
  };

  const handleAnswerStream = (answer) => {
    const data = {
      answer,
      invitationId: popup._id,
    };
    if (answer) {
      if (popup.type === "event_people") {
        eventService.answerInvite(data).then(() => {
          if (eventId !== popup.typeId) {
            dispatch(chatSocketModifiedData({ name: "unmount", data: true }));
            if (socket) {
              socketEmit(socket, "chat:leave", { eventId });
              socket.close();
            }
            dispatch(chatSocketConnect(popup.typeId));
            socketEmit(ws, "unsubscribe-event", {
              eventId,
              userId: popup.resourceId,
            });
          }
          navigate(`/event/${popup.typeId}`);
        });
      } else {
        eventService.answerInvite(data).then(() => {
          navigate(`/event/${popup.typeId}`);
        });
      }
    } else {
      eventService.answerInvite(data);
    }
    dispatch(eventModifiedData({ name: "popup", data: null }));
    notification.close(key);
  };

  useEffect(() => {
    if (popup) {
      responseNotify({
        placement: "topRight",
        type: "open",
        duration: 0,
        closeIcon: <></>,
        key,
        message:
          popup.type === "stream"
            ? t("title.invite_streamer")
            : t("title.invite_moderator_popup"),
        style: {
          padding: 10,
          background: colors.reinza,
          border: `2px solid ${colors.independence2}`,
          borderRadius: 10,
          color: colors.independence2,
        },
        description:
          popup.type === "stream" ? (
            <InvateStreamNotifly
              item={{ ...popup, r }}
              themeColors={colors}
              handleOnClick={handleAnswerStream}
            />
          ) : (
            <InviteModeratorNoifly
              item={{ ...popup, r }}
              themeColors={colors}
              handleOnClick={handleAnswerModerator}
            />
          ),
      });
    } else {
      notification.close(key);
    }
  }, [popup]);
};

export default InvitationNotifly;
