import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTab: "",
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    mainModifiedData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
  },
});

export const { mainModifiedData } = mainSlice.actions;

export const selectMain = (state) => {
  return state;
};

export default mainSlice.reducer;
