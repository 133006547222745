import { createSlice } from "@reduxjs/toolkit";
import { depthItemModified } from "config/utils";

const initialState = {
  activeTab: "",
  moderators: null,
  moderatorsSearch: null,
  selectedModeratorPage: null,
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    settingModifiedData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
    settingDepthModifiedData: (state, action) =>
      depthItemModified(
        state,
        action.payload.name,
        action.payload.depth,
        action.payload.data,
      ),
    settingDepthUpdateModifiedData: (state, action) => {
      depthItemModified(
        state,
        action.payload.name,
        action.payload.depth,
        action.payload.data,
      );
    },
    settingAddUpdateModifiedData: (state, action) => {
      const exist = state[action.payload.name].filter(
        (fl) => fl[action.payload.item.name] === action.payload.item.data,
      );
      state[action.payload.name] =
        exist.length > 0
          ? state[action.payload.name].map((m) =>
              m[action.payload.item.name] === action.payload.item.data
                ? action.payload.data
                : m,
            )
          : [action.payload.data, ...state[action.payload.name]];
    },
    settingAddData: (state, action) => {
      state[action.payload.name] = [
        ...state[action.payload.name],
        action.payload.data,
      ];
    },
    settingResetData: () => initialState,
  },
});

export const {
  settingModifiedData,
  settingDepthModifiedData,
  settingDepthUpdateModifiedData,
  settingAddData,
  settingAddUpdateModifiedData,
  settingResetData,
} = settingSlice.actions;

export const selectsetting = (state) => {
  return state;
};

export default settingSlice.reducer;
