import Loading from "components/Loading";
import { Border } from "components/UI/Border";
import { Flex, Image, Text } from "components/UI/CustomRebass";
import { userImageUrl } from "config/utils";
import { useTranslation } from "react-i18next";
import withButtonsNotifly from "../withButtonsNotifly";

const InviteModeratorNoifly = ({ item, children }) => {
  const { t } = useTranslation();
  return (
    <Flex>
      <Border
        borderColor="reinza"
        borderWidth={2}
        width={80}
        height={80}
        borderRadius={125}
        mb={20}
        src={userImageUrl(item?.username, "profile", item.r)}
        as={Image}
        mr={15}
      />
      <Flex flexDirection="column" flex="1">
        <Text mb={10}>
          {item.username} {t("title.invite_moderator")}
        </Text>
        {children}
      </Flex>
    </Flex>
  );
};

export default withButtonsNotifly(InviteModeratorNoifly);
