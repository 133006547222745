import React from "react";
import { Radio } from "antd";

const SRadio = ({ opt, options, children, ...rest }) => {
  return (
    <Radio.Group
      option={opt ? undefined : options}
      style={{ display: "block" }}
      {...rest}
    >
      {children && children}
      {opt && options.map((e) => opt(e, rest))}
    </Radio.Group>
  );
};

export default SRadio;
