import axiosClient from "plugins/axios";

const { makeRequest, makeRequestAuth } = axiosClient(
  process.env.REACT_APP_WALLET_ENDPOINT,
);

const DEFAULTURLS = {
  SUGGEST: "/suggest",
  PAYMENT: "/payment",
};

export default class WalletService {
  getWallet = (params) => makeRequestAuth("get", `wallet/zapapa`, { params });

  getSuggestions = (params) =>
    makeRequest("get", DEFAULTURLS.SUGGEST, { params });

  purchasing = (data) =>
    makeRequestAuth("post", `${DEFAULTURLS.PAYMENT}/zapapa`, { data });

  exchange = (data) => makeRequestAuth("post", "/exchange/zapapa", { data });

  getTransactions = (params) =>
    makeRequestAuth("get", "/transaction/zapapa", { params });

  getExchangeRate = (params) =>
    makeRequest("get", "/exchange/rate", { params });

  payout = (data) => makeRequestAuth("post", "/payout/zapapa", { data });
}
