const settingsMenu = Object.freeze({
  broadcastSettings: {
    id: 1,
    title: "title.broadcast_settings",
  },
  blockedPeople: {
    id: 2,
    title: "title.blocked_people",
  },
  moderator: {
    id: 3,
    title: "title.moderator",
  },
  sentRequests: {
    id: 4,
    title: "title.sent_requests",
    subMenu: true,
  },
  incomingRequests: {
    id: 5,
    title: "title.incoming_requests",
    subMenu: true,
  },
  userSettings: {
    id: 6,
    title: "title.user_settings",
  },
});

export default settingsMenu;
