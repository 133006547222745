import axiosClient from "plugins/axios";

const { makeRequest } = axiosClient(
  process.env.REACT_APP_LEADERBOARD_API_ENDPOINT,
  false,
);

const DEFAULTURLS = {
  LEADERBOARDS: "/leaderboards",
};
export default class LeaderboardService {
  getLeaderboards = (params) =>
    makeRequest("get", DEFAULTURLS.LEADERBOARDS, { params });
}
