import { Border } from "components/UI/Border";
import Button from "components/UI/Button";
import { Box, Flex, Image, Text } from "components/UI/CustomRebass";
import { userImageUrl } from "config/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EventService } from "services";
import { socketEmit } from "store/actions/socketAction";
import {
  chatSocketConnect,
  chatSocketModifiedData,
} from "store/chatSocketSlice";
import { eventModifiedData } from "store/eventSlice";

const eventService = new EventService();

const EventModalInviteModerator = ({ item, closeModal }) => {
  const { r } = useSelector(({ cache }) => cache);
  const { socket } = useSelector(({ chatSocket }) => chatSocket);
  const { socket: ws } = useSelector(({ websocket }) => websocket);
  const { id: eventId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAnswer = (answer) => {
    const data = {
      answer,
      invitationId: item._id,
    };
    if (answer && item.type === "event_moderator") {
      eventService.answerInvite(data).then(() => {
        if (eventId !== item.typeId) {
          dispatch(chatSocketModifiedData({ name: "unmount", data: true }));
          if (socket) {
            socketEmit(socket, "chat:leave", { eventId });
            socket.close();
          }
          dispatch(chatSocketConnect(item.typeId));
          socketEmit(ws, "unsubscribe-event", {
            eventId,
            userId: item.resourceId,
          });
        }
        navigate(`/event/${item.typeId}`);
      });
    } else {
      eventService.answerInvite(data);
    }
    dispatch(eventModifiedData({ name: "popup", data: null }));
    closeModal();
  };
  return (
    <Box mt={25} textAlign="center">
      <Border
        borderColor="reinza"
        borderWidth={2}
        width={125}
        height={125}
        borderRadius={125}
        mb={20}
        src={userImageUrl(item?.username, "profile", r)}
        as={Image}
      />
      <Flex
        flexDirection="column"
        alignItems="center"
        color="lavenderBlue"
        fontSize={18}
        mb={70}
      >
        <Text width={330} textAlign="center">
          {item.username} {t("title.invite_moderator")}
        </Text>
      </Flex>
      <Flex justifyContent="space-between">
        <Button
          flex="1"
          mr={10}
          px={40}
          mode="outinline"
          width="50%"
          onClick={() => handleAnswer(false)}
          text={t("title.decline")}
          fontSize={[13, 13, 16, 16]}
          color="lavenderBlue"
          fontWeight={600}
          sx={{
            borderColor: "lavenderBlue",
          }}
        />
        <Button
          flex="1"
          ml={10}
          width="50%"
          bg="electricBlue"
          onClick={() => handleAnswer(true)}
          text={t("title.accept")}
          fontSize={[13, 13, 16, 16]}
          fontWeight={600}
          sx={{
            "&:hover": {
              color: "policeBlue",
            },
          }}
        ></Button>
      </Flex>
    </Box>
  );
};

export default EventModalInviteModerator;
