import { Input } from "antd";
import withFormLabel from "components/UI/Forms/withFormLabel";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import Icon from "components/UI/Icon";
import { Box } from "components/UI/CustomRebass";
// import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const { Password } = Input;

const SPasswordInputStyled = styled(Password)`
  border-radius: 12px;
  &:focus {
    border-color: transparent;
  }
  &.ant-password-status-error {
    border-color: #ff4d4f;
    border-width: 1px;
    &:hover {
      border-color: #ff4d4f;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: ${(props) => props.theme.colors.white};
    -webkit-box-shadow: 0 0 0px 1000px
      ${(props) => props.theme.colors.policeBlue} inset;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: ${(props) => props.theme.colors.white};
  }
`;

const renderIcon = (visible) => {
  return visible ? (
    <Box>
      <Icon icon="icon-view" color="ceil"></Icon>
    </Box>
  ) : (
    <Box>
      <Icon icon="icon-view-off" color="ceil"></Icon>
    </Box>
  );
};

const SPasswordInput = ({ hasError, placeholderTx, placeholder, ...rest }) => {
  const { t } = useTranslation();

  return (
    <SPasswordInputStyled
      className={hasError ? "ant-password-status-error" : ""}
      placeholder={placeholderTx ? t(placeholderTx) : placeholder}
      iconRender={renderIcon}
      {...rest}
    />
  );
};
export default withFormLabel(SPasswordInput);
