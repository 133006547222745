import React from "react";
import { useSelector } from "react-redux";
import { useModal } from "config/hooks/useModal";
import { userImageUrl } from "config/utils";
import { Flex, Image, Text } from "components/UI/CustomRebass";
import { Border } from "components/UI/Border";
import Icon from "components/UI/Icon";
import BlockUserModal from "./BlockUserModal";

const BlockedUserDropdownItem = ({ item, type, ...rest }) => {
  const { r } = useSelector(({ cache }) => cache);

  const {
    isModalVisible: isVisibleBlockUserModal,
    openModal: openBlockUserModal,
    closeModal: closeBlockUserModal,
  } = useModal();
  return (
    <>
      <Border
        as="li"
        fontSize={16}
        color="white"
        py={8}
        pl={5}
        borderWidth={0}
        bg="independence"
        display="flex"
        justifyContent="space-between"
        borderRadius={[13, 13, 14, 16, 16]}
        alignItems="center"
        mb={[5, 5, 10, 10, 13]}
        sx={{
          "&:last-child": {
            border: "none",
          },
          cursor: "pointer",
        }}
        onClick={openBlockUserModal}
        {...rest}
      >
        <Flex alignItems="center">
          <Image
            width={[22, 22, 24, 30, 36]}
            height={[22, 22, 24, 30, 36]}
            mx={[4, 4, 5, 6, 8]}
            sx={{ borderRadius: "50%" }}
            src={userImageUrl(item.username, "profile", r)}
          />
          <Text fontSize={[12, 12, 12, 14, 16]}>{item.username}</Text>
        </Flex>
        <Icon
          icon={item.isBlocked ? "icon-block" : "icon-plus"}
          mr={[5, 5, 8, 12, 15]}
          fontSize={[15, 15, 16, 18, 20]}
          color={item.isBlocked ? "magicPotion" : "electricBlue"}
        />
      </Border>
      <BlockUserModal
        isModalVisible={isVisibleBlockUserModal}
        closeModal={closeBlockUserModal}
        item={item}
      />
    </>
  );
};

export default BlockedUserDropdownItem;
