import { Button as AButton } from "antd";
import styled from "@emotion/styled";
import { Box, Image } from "components/UI/CustomRebass";
import { useTranslation } from "react-i18next";
import Icon from "components/UI/Icon";
import { useTheme } from "emotion-theming";

const ButtonStyled = styled(({ ...props }) => <Box {...props} />)`
  &:after {
    display: none;
  }
`;

const Button = ({
  children,
  fontSize = 20,
  icon,
  img,
  text,
  textVrb,
  mode = "green",
  iconOpt,
  imgOpt,
  loading,
  loadOpt = { width: "30px", height: "30px", mr: 8 },
  ...rest
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const btnTheme = theme.variants.buttons[mode];

  return (
    <ButtonStyled
      as={AButton}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      fontSize={fontSize}
      variant={`buttons.${mode}`}
      disabled={loading ? true : false}
      {...rest}
    >
      {loading && (
        <Box>
          <Box
            className="spinner"
            width={loadOpt.width}
            height={loadOpt.height}
            backgroundColor={btnTheme.backgroundColor}
            mr={loadOpt.mr}
            sx={{
              border: `5px solid ${btnTheme.color}`,
              borderBottomColor: `${btnTheme.color}50`,
            }}
          ></Box>
          <Box
            className="loader-line"
            sx={{
              backgroundColor: `${btnTheme.color}50`,
              "&:before": {
                backgroundColor: `${btnTheme.color}`,
              },
            }}
          ></Box>
        </Box>
      )}
      {icon && <Icon icon={icon} fontSize={16} mr={10} {...iconOpt} />}
      {img && <Image {...imgOpt} />}
      {children ? (
        children
      ) : (
        <Box
          as={"span"}
          width={1}
          overflow="hidden"
          sx={{ textOverflow: "ellipsis" }}
        >
          {t(text, textVrb)}
        </Box>
      )}
    </ButtonStyled>
  );
};

export default Button;
