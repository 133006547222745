/* eslint-disable import/no-anonymous-default-export */
import _ from "lodash";
import baseVariables from "./baseVariables";

export const colors = Object.assign({
  black: "#000",
  white: "#fff",
  transparent: "transparent",
  electricBlue: _.get(baseVariables, "electric-blue"), //#64F6FF
  speedrunnersLime: _.get(baseVariables, "speedrunners-lime"), //#B9FF47
  magicPotion: _.get(baseVariables, "magic-potion"), //#FF4B59
  reinza: _.get(baseVariables, "reinza"), //#212C46
  darkJungleGreen: _.get(baseVariables, "dark-jungle-green"), //#131A26
  independence: _.get(baseVariables, "independence"), //#415074
  americanBlue: _.get(baseVariables, "american-blue"), //#2E4271
  ceil: _.get(baseVariables, "ceil"), //#91A7CC
  lavenderBlue: _.get(baseVariables, "lavender-blue"), //#CBDEFF
  alabaster: _.get(baseVariables, "alabaster"), //#fafafa
  policeBlue: _.get(baseVariables, "police-blue"), //#374463
  lightCobaltBlue: _.get(baseVariables, "light-cobalt-blue"), //#8DADE5
  darkElectricBlue: _.get(baseVariables, "dark-electric-blue"), //#4C5D7A
  independence2: _.get(baseVariables, "independence2"), //#92A6C8
  blueYonder: _.get(baseVariables, "blue-yonder"), //#5B72A8
  lavender: _.get(baseVariables, "lavender"), //#E8E9FF
  jordyBlue: _.get(baseVariables, "jordy-blue"), //#969EFE
  lincolnGreen: _.get(baseVariables, "lincoln-green"), //#2A4104
  purpleNavy: _.get(baseVariables, "purple-navy"), //#4C5C7A
  cottonCandy: _.get(baseVariables, "cotton-candy"), //#FFBBD4
  chinesePink: _.get(baseVariables, "chinese-pink"), //#E17EA2
  twilightLavender: _.get(baseVariables, "twilight-lavender"), //#953F6B
  gargoyleGas: _.get(baseVariables, "gargoyle-gas"), //#FFD747
  bleachedCedar: _.get(baseVariables, "bleached-cedar"), //#2E2239
  cloudsEnd: _.get(baseVariables, "clouds-end"), //#CED4DA
  vloudBurst: _.get(baseVariables, "vloud-burst"), //#91A6CC
  philippineSilver: _.get(baseVariables, "philippine-silver"), //#B3B0B0
  silverPolish: _.get(baseVariables, "silver-polish"), //#C6C6C6
});

export default {
  themeName: "light",
  breakpoints: ["992px", "1200px", "1400px", "1600px", "1900px"],
  colors,
  font: ["Axiforma", "Roboto", "RobotoSemibold", "RobotoBold"],
  fontSizes: [],
  weight: [100, 300, 400, 500, 600, 700],
  radii: [],
  space: [],
  shadows: {},
  variants: {
    card: {
      border: `1px solid`,
      borderColor: "darkElectricBlue",
      borderRadius: "2px",
    },
    popover: {
      default: {
        ".ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight":
          {
            paddingTop: "36px",
          },
        ".ant-popover": {
          "&-arrow": {
            "&-content": { display: "none" },
            backgroundImage: `url(/assets/img/triangle.svg)`,
            borderBottom: `3px solid ${colors.reinza}`,
            width: "35px",
            height: "26px",
            top: "14px",
          },
          "&-inner": {
            borderRadius: "19px",
            backgroundColor: colors.reinza,
            border: `1px solid ${colors.philippineSilver}`,
            "&-content": {
              color: colors.lavenderBlue,
            },
          },
        },
      },
      short: {
        ".ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight":
          {
            paddingTop: "32px",
          },
        ".ant-popover": {
          "&-arrow": {
            "&-content": { display: "none" },
            backgroundImage: `url(/assets/img/triangle.svg)`,
            borderBottom: `3px solid ${colors.reinza}`,
            width: "35px",
            height: "26px",
            top: "10px",
          },
          "&-inner": {
            borderRadius: "19px",
            backgroundColor: colors.reinza,
            border: `1px solid ${colors.philippineSilver}`,
            "&-content": {
              color: colors.lavenderBlue,
            },
          },
        },
      },
    },
    buttons: {
      green: {
        backgroundColor: colors.speedrunnersLime,
        color: colors.lincolnGreen,
        fontWeight: 600,
        "&:hover,&:active,&:focus": {
          backgroundColor: colors.speedrunnersLime,
          color: colors.lincolnGreen,
          borderColor: colors.transparent,
        },
      },
      cyan: {
        backgroundColor: colors.electricBlue,
        color: colors.reinza,
        "&:hover,&:active,&:focus": {
          backgroundColor: colors.electricBlue,
          color: colors.reinza,
          borderColor: colors.transparent,
        },
      },
      outside: {
        backgroundColor: colors.darkJungleGreen,
        color: colors.white,
        border: `2px solid ${colors.white}`,
        "&:hover,&:active,&:focus": {
          border: `2px solid ${colors.white}`,
          backgroundColor: colors.darkJungleGreen,
          color: colors.white,
        },
      },
      outinline: {
        background: colors.transparent,
        color: colors.white,
        border: `2px solid ${colors.white}`,
        "&:hover,&:active,&:focus": {
          border: `2px solid ${colors.white}`,
          backgroundColor: colors.transparent,
          color: colors.white,
        },
      },
      policeBlue: {
        backgroundColor: colors.policeBlue,
        color: colors.white,
        "&:hover,&:active,&:focus": {
          backgroundColor: colors.policeBlue,
          color: colors.white,
          borderColor: colors.transparent,
        },
      },
      gray: {
        backgroundColor: colors.lavenderBlue,
        color: colors.reinza,
        "&:hover,&:active,&:focus": {
          backgroundColor: colors.lavenderBlue,
          color: colors.reinza,
          borderColor: colors.transparent,
        },
      },
    },
  },
};
