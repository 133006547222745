import ModeratorContent from "./moderatorPage";
import Icon from "components/UI/Icon";
import { Flex, Text } from "components/UI/CustomRebass";
import { ButtonAnimation } from "assets/styled/animationClass";
import { Border } from "components/UI/Border";

const moderatorPages = Object.freeze({
  sentRequests: {
    id: 4,
    title: "title.sent_requests",
    scope: "e",
    icon: (status, func, t) =>
      status === "pending" ? (
        <Flex>
          <Text color="lavenderBlue" fontSize={[8, 10, 10, 12, 12]} mr={5}>
            {t("title.pendings")}
          </Text>
          <Icon
            icon="icon-trash"
            mr={[5, 5, 8, 12, 15]}
            fontSize={[12, 12, 12, 14, 14, 16]}
            color="magicPotion"
            onClick={() => func[2]()}
          />
        </Flex>
      ) : (
        <Icon
          icon="icon-trash"
          mr={[5, 5, 8, 12, 15]}
          fontSize={[12, 12, 12, 14, 14, 16]}
          color="magicPotion"
          onClick={func}
        />
      ),
    description: "description.moderator_sent_requests_content",
    service: "",
    secondService: "moderators",
    // status: "accepted",
    value: "sentRequest",
    render: (x) => <ModeratorContent {...x} />,
  },
  incomingRequests: {
    id: 5,
    title: "title.incoming_requests",
    scope: "r",
    icon: (status, renderFunc) =>
      status === "pending" ? (
        <Flex>
          <ButtonAnimation
            text="title.accept"
            mode="cyan"
            p={10}
            mr={5}
            height={[27, 27, 27, 30, 30]}
            fontSize={13}
            onClick={() => renderFunc[0]()}
          />
          <Border
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderWidth={1}
            borderColor="lavenderBlue"
            borderRadius={6}
            mr={5}
            px={7}
            onClick={() => renderFunc[1]()}
          >
            <Icon icon="icon-remove" color="lavenderBlue" fontSize={13} />
          </Border>
        </Flex>
      ) : (
        <Icon
          icon="icon-trash"
          mr={[5, 5, 8, 12, 15]}
          fontSize={[12, 12, 12, 14, 14, 16]}
          color="magicPotion"
          onClick={() => renderFunc()}
        />
      ),
    description: "description.moderator_incoming_requests_content",
    service: "",
    secondService: "moderatorWhose",
    value: "incomingRequest",
    render: (x) => <ModeratorContent {...x} />,
  },
});

export default moderatorPages;
