import AppRouter from "routes";
import { ThemeProvider } from "emotion-theming";
import { defaultTheme, darkTheme } from "theme";
import _ from "lodash";
import "./index.css";
import "./ant.less";
import { Box } from "components/UI/CustomRebass";
import { useDispatch } from "react-redux";
import { getViaToken } from "store/actions/authAction";
import { useEffect } from "react";
import { getLocalStorage } from "config/utils";
import { websocketConnect } from "store/websocketSlice";
import DeviceMobile from "containers/DeviceMobile";

const themes = {
  light: defaultTheme,
  dark: darkTheme,
};

function App() {
  const dispatch = useDispatch();
  const screenWidth = window.innerWidth;

  useEffect(() => {
    const token = getLocalStorage("token");
    if (token) {
      dispatch(getViaToken());
    } else {
      dispatch(websocketConnect());
    }
  }, []);

  return (
    <ThemeProvider theme={_.get(themes, "light", defaultTheme)}>
      <Box bg="darkJungleGreen" minHeight="100vh">
        {/* {screenWidth >= 1280 ? <AppRouter /> : <DeviceMobile />} */}
        <AppRouter />
      </Box>
    </ThemeProvider>
  );
}

export default App;
