import { createSlice } from "@reduxjs/toolkit";
import { depthItemModified } from "config/utils";

const initialState = {
  info: {},
  videos: [],
  events: [],
  followers: [],
  followings: [],
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    profileModifiedData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },

    profileDepthModifiedData: (state, action) =>
      depthItemModified(
        state,
        action.payload.name,
        action.payload.depth,
        action.payload.data,
      ),

    profileResetData: () => initialState,
  },
});

export const {
  profileModifiedData,
  profileDepthModifiedData,
  profileResetData,
} = profileSlice.actions;

export const selectProfile = (state) => state.profile;

export default profileSlice.reducer;
