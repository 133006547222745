import { Box } from "rebass";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";

const IconStyled = styled(Box)`
  &:hover: {
    color: ${(props) => props.hoverColor && props.hoverColor};
  }
`;

const Icon = ({ icon, hoverColor, ...rest }) => {
  const { colors } = useTheme();
  return (
    <IconStyled
      as="i"
      hoverColor={colors[hoverColor]}
      className={icon}
      {...rest}
    />
  );
};

export default Icon;
