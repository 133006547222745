import {
  acceptedSettingModeratorAction,
  acceptedSettingModeratorDeleteAction,
  settingDeleteEventModeratorAction,
} from "store/actions/settingAction";
import {
  acceptedModeratorDelete,
  eventDepthModifiedData,
  eventModifiedData,
} from "store/eventSlice";
import {
  inboxAddUpdateModifiedData,
  inboxModifiedData,
  inboxUpdateModifiedData,
} from "store/inboxSlice";

export default class UserHandler {
  invitation = (indicator, args) => (dispatch) => {
    dispatch(eventModifiedData({ name: "popup", data: args.invitationData }));
  };

  "private-message" = (indicator, args) => (dispatch, state) => {
    const { groupId } = state().inbox;
    dispatch(
      inboxAddUpdateModifiedData({
        name: "messages",
        item: { name: "groupId", data: args.conversation.groupId },
        data: args.conversation,
      }),
    );

    if (args?.message && groupId === args?.conversation.groupId) {
      dispatch(
        inboxUpdateModifiedData({
          name: "messageData",
          data: args.message,
        }),
      );
    }
  };

  "block-delete" = (indicator, args) => (dispatch, state) => {
    if (args?.ownerId === state().inbox?.receiverUser?._id) {
      dispatch(
        inboxModifiedData({
          name: "userBlockedMe",
          data: false,
        }),
      );
    }
    if (state().event.info.user._id === args.ownerId) {
      dispatch(
        eventDepthModifiedData({
          name: "info",
          depth: ["isBlocked"],
          data: false,
        }),
      );
    }
  };

  "block-all" = (indicator, args) => (dispatch, state) => {
    if (args?.ownerId === state().inbox?.receiverUser?._id) {
      dispatch(
        inboxModifiedData({
          name: "userBlockedMe",
          data: true,
        }),
      );
    }
    if (
      state().user.info._id === args.userId &&
      state().event.info.user._id === args.ownerId
    ) {
      dispatch(
        eventDepthModifiedData({
          name: "info",
          depth: ["isBlocked"],
          data: true,
        }),
      );
    }
  };

  moderator = (indicator, args) => (dispatch, state) => {
    switch (indicator) {
      case "accepted-invite": {
        dispatch(acceptedSettingModeratorAction(args));
        break;
      }
      case "delete": {
        dispatch(acceptedModeratorDelete({ _id: args?.userId, type: "user" }));
        if (state().user.info._id === args?.user?._id) {
          dispatch(
            eventDepthModifiedData({
              name: "info",
              depth: ["permissions"],
              data: args.permissions,
            }),
          );
        }
        dispatch(acceptedSettingModeratorDeleteAction(args));

        break;
      }

      case "declined-invite": {
        dispatch(settingDeleteEventModeratorAction(args));
        dispatch(eventModifiedData({ name: "popup", data: null }));
        break;
      }

      default:
        break;
    }
  };

  stream = (indicator, args) => (dispatch, state) => {
    switch (indicator) {
      case "declined-invite": {
        dispatch(eventModifiedData({ name: "popup", data: null }));
        break;
      }

      default:
        break;
    }
  };

  wallet = (indicator, args) => (dispatch, state) => {
    switch (indicator) {
      case "information": {
        if (state().user.info._id === args.userId) {
          dispatch(
            eventModifiedData({
              name: "moneybox",
              data: { showPopup: true, prize: args.amount },
            }),
          );
        }

        break;
      }

      default:
        break;
    }
  };

  list = (indicator, args) => (dispatch, state) => {
    switch (indicator) {
      case "poll": {
        const dataSortingByCreatedDate = args.polls?.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        dispatch(
          eventModifiedData({
            name: "polls",
            data: dataSortingByCreatedDate,
          }),
        );

        break;
      }

      default:
        break;
    }
  };
}
