import {
  dollarFormat,
  getLocalStorage,
  removeLocalStorage,
  responseMessage,
} from "config/utils";
import _ from "lodash";
import { UserService, WalletService } from "services";
import { authModifiedData, authSignIn, logout } from "store/authSlice";
import { inboxMessages } from "store/inboxSlice";
import { getNotifications } from "store/notificationSlice";
import { userModifiedData } from "store/userSlice";
import { websocketConnect } from "store/websocketSlice";
const userService = new UserService();

export const signIn = () => (dispatch) => {
  dispatch(authModifiedData({ name: "authenticated", data: true }));
  dispatch(authModifiedData({ name: "token", data: getLocalStorage("token") }));
  dispatch(
    authModifiedData({
      name: "refreshToken",
      data: getLocalStorage("refreshToken"),
    }),
  );
};

export const getViaToken = () => (dispatch, state) => {
  const { socket } = state().websocket;
  const walletService = new WalletService();
  const params = { followers: true };
  return userService
    .getViaToken(params)
    .then(({ data }) => {
      dispatch(signIn());
      dispatch(userModifiedData({ name: "info", data }));
      socket && socket.close();
      const token = getLocalStorage("token");
      dispatch(websocketConnect(token));
      const params = { currency: "all" };
      return walletService
        .getWallet(params)
        .then(({ data }) => {
          dispatch(
            userModifiedData({
              name: "wallet",
              data: { ...data, USD: dollarFormat(data?.USD || 0) }, // TODO: use dollar format
            }),
          );
          dispatch(inboxMessages());
          dispatch(getNotifications());
          return true;
        })
        .catch((err) => {
          // responseMessage({
          //   type: "error",
          //   content: err,
          // });
          removeLocalStorage("token");
          removeLocalStorage("refreshToken");
          dispatch(logout());
          return false;
        });
    })
    .catch((err) => {
      // remove localstorage token - refreshToken
      removeLocalStorage("token");
      removeLocalStorage("refreshToken");
      dispatch(logout());
      return false;
    });
};

export const googleSignIn = (payload) => (dispatch) => {
  const data = {
    token: payload.tokenId,
  };
  return userService.googleSignin(data).then((res) => {
    if (res.status) {
      dispatch(authSignIn(res.data));
      return true;
    } else {
      return false;
    }
  });
};
