import axiosClient from "plugins/axios";

const { makeRequest } = axiosClient(process.env.REACT_APP_BASE_API_ENDPOINT);

const DEFAULTURLS = {
  CMS: "cms",
  TAGS: "tags",
  GAMES: "games",
};

export default class CmsService {
  getSearchParams = (params) =>
    makeRequest("get", `${DEFAULTURLS.CMS}/search/recommend`, { params });

  getTags = (params) =>
    makeRequest("get", `${DEFAULTURLS.CMS}/tags`, { params });

  getAllTags = () => makeRequest("get", `${DEFAULTURLS.CMS}/tags/all`);

  getGames = (params) =>
    makeRequest("get", `${DEFAULTURLS.CMS}/games`, { params });

  getBanner = (params) =>
    makeRequest("get", `${DEFAULTURLS.CMS}/banners`, { params });

  getDonates = () => makeRequest("get", `${DEFAULTURLS.CMS}/donations`);

  contactUs = (data) =>
    makeRequest("post", `${DEFAULTURLS.CMS}/contacts`, { data });
}
