import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  r: Date.now(),
  time: Date.now(),
};

export const cacheSlice = createSlice({
  name: "cache",
  initialState,
  reducers: {
    cacheModifiedData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
  },
});

export const { cacheModifiedData } = cacheSlice.actions;

export const selectCache = (state) => state.cache;

export default cacheSlice.reducer;
