import { routesUrl } from "routes/routes-url";

const footermenu = Object.freeze({
  about: {
    title: "title.about_us",
    to: routesUrl.aboutUs,
  },
  privacy: {
    title: "title.privacy_policy",
    to: routesUrl.privacyPolicy,
  },
  conditionOfUse: {
    title: "title.condition_of_use",
    to: routesUrl.conditionOfUse,
  },
  cookieStatement: {
    title: "title.cookie_statement",
    to: routesUrl.cookieStatement,
  },
  contact: {
    title: "title.contact",
    to: routesUrl.contact,
  },
});

export default footermenu;
