import styled from "@emotion/styled";
import { Popover as APopover } from "antd";
import { Box } from "components/UI/CustomRebass";

const PopoverStyled = styled(({ mode = "default", content, ...props }) => (
  <Box variant={`popover.${mode}`} {...props} />
))`
  display: inline-block;
`;

const Popover = ({
  children,
  trigger = "click",
  placement = "bottomRight",
  onVisibleChange,
  visible,
  content,
  getPopupContainer,
  destroyTooltipOnHide,
  ...rest
}) => {
  return (
    <PopoverStyled {...rest}>
      <APopover
        trigger={trigger}
        placement={placement}
        onVisibleChange={onVisibleChange}
        visible={visible}
        destroyTooltipOnHide={destroyTooltipOnHide}
        getPopupContainer={
          getPopupContainer ? getPopupContainer : (el) => el.parentElement
        }
        content={content}
      >
        {children}
      </APopover>
    </PopoverStyled>
  );
};

export default Popover;
