import axiosClient from "plugins/axios";

const { makeRequest, makeRequestAuth } = axiosClient(
  process.env.REACT_APP_BASE_API_ENDPOINT,
);
// NOTE: params -> queryString | data -> payload

const DEFAULTURLS = {
  AUTH: "auth",
  USERS: "users",
  INVITATIONS: "invitations",
};

export default class UserService {
  // service update
  deleteInvitations = (inviteId) =>
    makeRequestAuth("delete", `${DEFAULTURLS.INVITATIONS}/${inviteId}`);

  signup = (data) =>
    makeRequest("post", `${DEFAULTURLS.AUTH}/register`, { data });

  signin = (data) =>
    makeRequest("post", `${DEFAULTURLS.AUTH}/signin`, { data });

  googleSignin = (data) =>
    makeRequest("post", `${DEFAULTURLS.AUTH}/google`, { data });

  getUser = (params, authenticated = null) =>
    authenticated
      ? makeRequestAuth("get", `${DEFAULTURLS.USERS}/search`, { params })
      : makeRequest("get", `${DEFAULTURLS.USERS}/search`, { params });

  freezeAccount = () => makeRequestAuth("post", `${DEFAULTURLS.USERS}/freeze`);

  deleteAccount = () => makeRequestAuth("delete", `${DEFAULTURLS.USERS}`);

  getFollowers = (userId) => makeRequest("get", `followers/${userId}`);

  getFollowersViaToken = (userId) =>
    makeRequestAuth("get", `followers/${userId}`);

  getFollowings = (userId) => makeRequest("get", `followings/${userId}`);

  getFollowingsViaToken = (userId) =>
    makeRequestAuth("get", `followings/${userId}`);

  follow = (data) => makeRequestAuth("post", "followers", { data });

  unFollow = (userId) => makeRequestAuth("delete", `followers/${userId}`);

  updateImage = (data) =>
    makeRequestAuth("put", `${DEFAULTURLS.USERS}/me/image`, { data });

  update = (data) =>
    makeRequestAuth("put", `${DEFAULTURLS.USERS}/me`, { data });

  isUserBlocked = (typeId, params) =>
    makeRequestAuth("get", `blocks/${typeId}`, { params });

  isBlockExist = (params) =>
    makeRequestAuth("get", `blocks/exists`, { params });

  getBlockedUserByUserId = (params) =>
    makeRequestAuth("get", `blocks`, { params });

  getViaToken = (params) =>
    makeRequestAuth("get", `${DEFAULTURLS.USERS}/me`, { params });

  getMessages = () => makeRequestAuth("get", "messages");

  sendMessage = (groupId, data) =>
    makeRequestAuth("post", `messages${groupId ? `/${groupId}` : ""}`, {
      data,
    });

  deleteMessage = (messageId) =>
    makeRequestAuth("delete", `messages/${messageId}`);

  getMessagesWithGroupId = (groupId) =>
    makeRequestAuth("get", `messages/${groupId}`);

  blockByUserId = (data) => makeRequestAuth("post", "blocks", { data });

  unBlockByUserId = (params) => makeRequestAuth("delete", `blocks`, { params });

  getByUsernameExist = (username) =>
    makeRequest("get", `${DEFAULTURLS.USERS}/${username}/user`);

  getByUsername = (username, params) =>
    makeRequest("get", `${DEFAULTURLS.USERS}/${username}/user`, { params });

  getByUsernameAuth = (username, params) =>
    makeRequestAuth("get", `${DEFAULTURLS.USERS}/${username}/user`, { params });

  getByUserId = (params) =>
    makeRequest("get", `${DEFAULTURLS.USERS}`, { params });

  reports = (data) =>
    makeRequestAuth("post", `${DEFAULTURLS.USERS}/reports`, { data });

  getStatistics = (username) =>
    makeRequest("get", `${DEFAULTURLS.USERS}/${username}/statistic`);
  // service update END

  forgotPassword = (data) =>
    makeRequest("post", `${DEFAULTURLS.AUTH}/forgot_password`, { data });

  resetPassword = (data) =>
    makeRequest("post", `${DEFAULTURLS.AUTH}/reset_password`, { data });

  getNotifications = () => makeRequestAuth("get", "notifications");

  firebaseCreate = (data) =>
    makeRequestAuth("post", "notifications/firebase/token", { data });

  deleteModerator = (invitationId) =>
    makeRequestAuth("delete", `moderators/${invitationId}`);

  getModerator = (params) => makeRequestAuth("get", `moderators`, { params });

  moderatorMe = (params) => makeRequestAuth("get", `moderators/me`, { params });

  getModeratorWhose = (params) =>
    makeRequestAuth("get", `moderators/whose`, { params });

  moderatorSearch = (params) =>
    makeRequestAuth("get", `moderators/search`, { params });

  getInvitations = (params) =>
    makeRequestAuth("get", `${DEFAULTURLS.INVITATIONS}`, { params });

  deleteInvitation = (invitationId) =>
    makeRequestAuth("delete", `${DEFAULTURLS.INVITATIONS}/${invitationId}`);

  inviteModerator = (data) =>
    makeRequestAuth("post", `${DEFAULTURLS.INVITATIONS}`, { data });

  acceptModeratorInvitation = (id, data) =>
    makeRequestAuth("put", `${DEFAULTURLS.INVITATIONS}/${id}`, { data });
}
