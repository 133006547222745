import styled from "@emotion/styled";
import { Box, Flex } from "components/UI/CustomRebass";
import { FRadio } from "components/UI/Forms/FormControllers";
import Icon from "components/UI/Icon";
import { CSSTransition } from "react-transition-group";

export const InputPrefix = styled(Icon)``;

export const GenderRadioBox = styled(({ ...props }) => {
  return <FRadio {...props} color="red" />;
})`
  font-size: 70px;
  font-weight: bold;
  .ant-radio-wrapper {
    span {
      font-size: 100px;
      color: ${(props) => props.theme.colors.cottonCandy};
    }
  }
`;

export const CssTrans = styled(CSSTransition)`
  width: 100%;
  --speed: ${(props) => props.timeout}ms;

  &.-enter {
    transform: translateX(+110%);
  }
  &.-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
    height: 0;
  }
  &.-exit-active {
    transform: translateX(+110%);
    transition: all var(--speed) ease;
    height: 0;
  }
`;

export const MessageStyle = styled(Box)`
  .selectedMessage {
    position: relative;
    border: 1px solid ${(props) => props.borderColor && props.borderColor};
    &:after {
      content: "";
      position: absolute;
      right: -14px;
      background-image: url("assets/img/messageItemRight.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 20.8px;
      height: 100%;
    }
  }
`;

export const CoinstoreStyled = styled(Box)`
  .ant-radio {
    display: none;
    + span {
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: ${(props) => props.theme.colors.independence};
      height: 80px;
      margin-bottom: 15px;
      border: 3px solid ${(props) => props.theme.colors.independence};
      border-radius: 10px;
      cursor: pointer;
    }
  }
  .ant-radio-wrapper {
    width: 100%;
    .top-coinstore {
      margin-bottom: 5px;
      span {
        font-size: 20px;
        color: ${(props) => props.theme.colors.white};
      }
    }
    .bottom-coinstore {
      span {
        color: ${(props) => props.theme.colors.lavenderBlue};
        font-size: 16px;
        font-weight: 700;
        &:first-of-type {
          margin-right: 10px;
        }
      }
    }
  }
  .ant-radio-wrapper-checked {
    .ant-radio {
      + span {
        border-color: ${(props) => props.theme.colors.speedrunnersLime};
      }
    }
  }
  &.best-offer {
    label {
      .ant-radio {
        + span {
          background: ${(props) => props.theme.colors.electricBlue};
          border-color: ${(props) => props.theme.colors.electricBlue};
        }
      }
    }
    .ant-radio-wrapper-checked {
      .ant-radio {
        + span {
          border-color: ${(props) => props.theme.colors.speedrunnersLime};
        }
      }
    }
    span {
      color: ${(props) => props.theme.colors.reinza} !important;
    }
  }
`;

export const FlexStyled = styled(Flex)`
  > div {
    width: 100%;
    margin: 0;

    > .ant-radio-group {
      width: 100%;
      display: flex !important;
      flex-wrap: wrap;
    }
  }
`;
