import React, { useEffect } from "react";
import Header from "components/Header";
import { Outlet, useLocation } from "react-router-dom";
import { Box } from "components/UI/CustomRebass";
import { useScrollToTop } from "config/hooks/useScrollToTop";
import InvitationPopup from "components/InvitationPopup";
import MoneyboxPopup from "components/MoneyboxPopup";

const NonFooterLayout = () => {
  return (
    <Box>
      <Header />
      <Box
        as="main"
        sx={{
          minHeight: "calc(100vh - 194px)",
          maxWidth: "2000px",
          margin: "0 auto",
        }}
        px={40}
      >
        <Outlet />
      </Box>
      <InvitationPopup />
      <MoneyboxPopup />
    </Box>
  );
};

export default React.memo(NonFooterLayout);
