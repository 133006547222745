import React from "react";
import { Checkbox } from "antd";
import styled from "@emotion/styled";

const { Group } = Checkbox;

const SCheckboxStyled = styled(Group)``;

const SCheckboxGroup = ({
  hasError,
  opt,
  options,
  text,
  placeholderTx,
  ...rest
}) => {
  return (
    <SCheckboxStyled
      option={opt ? undefined : options}
      className={hasError ? "ant-input-status-error" : ""}
      style={{ display: "block" }}
      {...rest}
    >
      {opt && options.map(opt)}
    </SCheckboxStyled>
  );
};

export default SCheckboxGroup;
