import styled from "@emotion/styled";
import { Radio } from "antd";
import { Border } from "components/UI/Border";
import { useTranslation } from "react-i18next";

const BorderStyled = styled(Border)`
  label {
    margin: 0;
    padding: 5px 0;
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0px 0px !important;
  }
  label {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    width: "100%";
    border-radius: 12px;
    border: 1px solid transparent;
    flex-direction: row-reverse;
    span.ant-radio {
      top: 0;
      & + span {
        padding: 0;
      }
      .ant-radio-inner {
        background: none;
        border-color: ${(props) => props.theme.colors.lavenderBlue};
      }
    }
    span:last-child {
      color: ${(props) => props.theme.colors.lavenderBlue};
      font-size: 14px;
      flex: 1;
      &:hover {
        span {
          color: ${(props) => props.theme.colors.electricBlue};
        }
      }
    }
    &:hover {
      span:last-child {
        color: ${(props) => props.theme.colors.electricBlue};
      }
    }
  }
  .ant-radio-wrapper-checked {
    span.ant-radio {
      .ant-radio-inner {
        border-color: ${(props) => props.theme.colors.electricBlue};
        &:after {
          background-color: ${(props) => props.theme.colors.electricBlue};
        }
      }
    }
  }
`;

export const LanguageRadioTemplate = (props) => {
  const { t } = useTranslation();
  return (
    <BorderStyled
      key={props.value}
      borderColor="transparent"
      display="inline-flex"
      alignItems="center"
      width="100%"
    >
      <Radio value={props.value}>{t(props.label)}</Radio>
    </BorderStyled>
  );
};
