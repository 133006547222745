import React, { useState, useEffect, useMemo } from "react";
import { headermenu } from "config/enums";
import { enumToArray } from "config/utils";
import { useTranslation } from "react-i18next";
import { Box, Text } from "components/UI/CustomRebass";
import Icon from "components/UI/Icon";
import { Link } from "components/UI/Link";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import { useLocation } from "react-router-dom";

const ActiveMenu = styled(Box)`
  li:last-child {
    margin: 0;
  }
  i {
    color: ${(props) => props.activeColor && props.activeColor};
  }
  span {
    color: ${(props) => props.activeColor && props.activeColor};
  }
  .active {
    &:before {
      content: "";
      bottom: -7px;
      left: 0;
      position: absolute;
      background-color: #64f6ff;
      height: 2px;
      width: ${(props) => props.lineWidth && props.lineWidth}px;
      transition: all 0.2s ease;
    }
    i {
      color: white;
    }
    span {
      color: white;
    }
  }
  i:hover {
    animation-name: bounce-4;
    animation-timing-function: ease;
    animation-duration: 0.7s;
  }
`;

const HeaderMenu = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  let location = useLocation();
  const [lineStyle, setLineStyle] = useState({
    color: colors.vloudBurst,
    width: 40,
  });

  useEffect(() => {
    lineMove(location.pathname);
  }, [location.pathname]);

  const headerMenu = useMemo(() => {
    return enumToArray(headermenu).map((x) => {
      if (x.to === location.pathname) {
        return {
          ...x,
          icon: x.activeIcon,
        };
      }
      return x;
    });
  }, [location.pathname]);

  function lineMove(path) {
    if (document.querySelector(`.header-menu [href='${path}']`)) {
      const target = document.querySelector(`.header-menu [href='${path}']`);
      const minus = target.closest("ul").getBoundingClientRect().left;
      setLineStyle({
        left: target.closest("li").getBoundingClientRect().left - minus,
      });
      setLineStyle({
        ...lineStyle,
        width: target.closest("li").getBoundingClientRect().width,
      });
    }
  }

  return (
    <Box
      display={["none", "none", "block", "block", "block", "block", "block"]}
      ml={[30, 30, 30, 30, 30, 80, 130, 183]}
      className="header-menu"
    >
      <ActiveMenu
        activeColor={lineStyle.color}
        lineWidth={lineStyle.width}
        display="inline-flex"
        as="ul"
      >
        {headerMenu.map((x) => {
          return (
            <Box
              mr={[10, 10, 10, 10, 20, 30, 60]}
              color="vloudBurst"
              key={x.title}
              sx={{ position: "relative" }}
              as="li"
              px={8}
              py={7}
            >
              <Link
                href={x.to}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                end
              >
                <Icon icon={x.icon} fontSize={[18, 18, 18, 18, 20, 25]} />
                <Text
                  as="span"
                  mt={6}
                  display={["none", "none", "none", "flex", "flex", "flex"]}
                  style={{
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "23px",
                  }}
                >
                  {t(x.title)}
                </Text>
              </Link>
            </Box>
          );
        })}
      </ActiveMenu>
    </Box>
  );
};

export default HeaderMenu;
