import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { getLocalStorage } from "config/utils";

function UnauthRoute({ children }) {
  let location = useLocation();
  const token = getLocalStorage("token");

  if (!token) return children;
  else return <Navigate to="/" state={{ from: location }} replace />;
}

export default UnauthRoute;
