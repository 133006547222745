import _ from "lodash";
import baseVariables from "theme/defaultTheme/baseVariables";

export const defaultThemeColors = Object.assign({
  black: "#000000",
  white: "#ffffff",
  transparent: "transparent",
  electricBlue: _.get(baseVariables, "electric-blue"), //#64F6FF
  speedrunnersLime: _.get(baseVariables, "speedrunners-lime"), //#B9FF47
  magicPotion: _.get(baseVariables, "magic-potion"), //#FF4B59
  reinza: _.get(baseVariables, "reinza"), //#212C46
  darkJungleGreen: _.get(baseVariables, "dark-jungle-green"), //#131A26
  independence: _.get(baseVariables, "independence"), //#415074
  americanBlue: _.get(baseVariables, "american-blue"), //#2E4271
  ceil: _.get(baseVariables, "ceil"), //#91A7CC
  lavenderBlue: _.get(baseVariables, "lavender-blue"), //#CBDEFF
  alabaster: _.get(baseVariables, "alabaster"), //#fafafa
  policeBlue: _.get(baseVariables, "police-blue"), //#374463
  lightCobaltBlue: _.get(baseVariables, "light-cobalt-blue"), //#8DADE5
  darkElectricBlue: _.get(baseVariables, "dark-electric-blue"), //#4C5D7A
  independence2: _.get(baseVariables, "independence2"), //#92A6C8
  blueYonder: _.get(baseVariables, "blue-yonder"), //#5B72A8
  lavender: _.get(baseVariables, "lavender"), //#E8E9FF
  jordyBlue: _.get(baseVariables, "jordy-blue"), //#969EFE
  lincolnGreen: _.get(baseVariables, "lincoln-green"), //#2A4104
  purpleNavy: _.get(baseVariables, "purple-navy"), //#4C5C7A
  cottonCandy: _.get(baseVariables, "cotton-candy"), //#FFBBD4
  chinesePink: _.get(baseVariables, "chinese-pink"), //#E17EA2
  twilightLavender: _.get(baseVariables, "twilight-lavender"), //#953F6B
  gargoyleGas: _.get(baseVariables, "gargoyle-gas"), //#FFD747
  bleachedCedar: _.get(baseVariables, "bleached-cedar"), //#2E2239
  cloudsEnd: _.get(baseVariables, "clouds-end"), //#CED4DA
  vloudBurst: _.get(baseVariables, "vloud-burst"), //#91A6CC
  philippineSilver: _.get(baseVariables, "philippine-silver"), //#B3B0B0
  silverPolish: _.get(baseVariables, "silver-polish"), //#C6C6C6
  charcoal: _.get(baseVariables, "charcoal"), //#2D3C56
  carnationPink: _.get(baseVariables, "carnation-pink"), //#ffa6c1
  purplePizzazz: _.get(baseVariables, "purple-pizzazz"), //#ff4eed
  frenchFuchsia: _.get(baseVariables, "french-fuchsia"), //#ff3689
  pastelRed: _.get(baseVariables, "pastel-red"), //#ff6464
  seaGreen: _.get(baseVariables, "sea-green"), //#07ffc4
  ripeMango: _.get(baseVariables, "ripe-mango"), //#ffcf23
  violetsAreBlue: _.get(baseVariables, "violets-are-blue"), //#4dff7f
  veryLightMalachiteGreen: _.get(baseVariables, "very-light-malachite-green"), //#4dff7f
  sasquatchSocks: _.get(baseVariables, "sasquatch-socks"), //#ff417a
  ufoGreen: _.get(baseVariables, "ufo-green"), //#25d366
  vividCerulean: _.get(baseVariables, "vivid-cerulean"), //#EE4200
  chineseBlue: _.get(baseVariables, "chinese-blue"), //#3b5998
  verdigris: _.get(baseVariables, "verdigris"), //#479dad
  seaSerpent: _.get(baseVariables, "sea-serpent"), //#57CEDA
  cerisePink: _.get(baseVariables, "cerise-pink"), //#F53D78
  gainsboro: _.get(baseVariables, "gainsboro"), //#DDDDDD
  blueberry: _.get(baseVariables, "blueberry"), // #5486FF
  spiroDiscoBall: _.get(baseVariables, "spiro-disco-ball"), // #22BBF5
  deepCerise: _.get(baseVariables, "deep-cerise"), // #D42F8B
  maximumRed: _.get(baseVariables, "maximum-red"), // #D72A26
  yankeesBlue: _.get(baseVariables, "yankees-blue"), // #212C46DE
  yankeesBlues: _.get(baseVariables, "yankees-blues"), // #1A233A
  mauvelous: _.get(baseVariables, "mauvelous"), // #FCA6AD
});
