import { io } from "socket.io-client";
import {
  chatSocketModifiedData,
  chatSocketReconnect,
} from "store/chatSocketSlice";
import {
  EventHandler,
  UserHandler,
  WalletHandler,
  ChatHandler,
} from "store/sockets";
import { websocketModifiedData } from "../websocketSlice";

const MAPPINGS = {
  "event-adstatus": "event:ad",
};
const userHandler = new UserHandler();
const eventHandler = new EventHandler();
const walletHandler = new WalletHandler();
const chatHandler = new ChatHandler();

export const websocketConnectAction = (token) => (dispatch) => {
  const socket = io.connect(
    `${process.env.REACT_APP_WEBSITE_SOCKET_ENDPOINT}`,
    {
      path: "/website-socket",
      extraHeaders: {
        zapapaToken: token || "",
      },
      query: {
        zapapaToken: token || "",
      },
      transports: ["websocket"],
    },
  );
  socket.on("connect", () => {
    dispatch(websocketModifiedData({ name: "socket", data: socket }));
    dispatch(websocketListener(socket));
  });
};

export const chatSocketConnectAction = (token, id) => (dispatch, state) => {
  const socket = io.connect(`${process.env.REACT_APP_CHAT_SOCKET_ENDPOINT}`, {
    path: "/chat-socket",
    extraHeaders: {
      zapapaToken: token || "",
    },
    query: {
      zapapaToken: token || "",
    },
    transports: ["websocket"],
  });
  socket.on("connect", () => {
    dispatch(chatSocketModifiedData({ name: "socket", data: socket }));
    dispatch(chatSocketListener(socket));
    socketEmit(socket, "chat:subscribe", { eventId: id });
  });
  socket.on("disconnect", () => {
    const { unmount } = state().chatSocket;
    if (!unmount) {
      dispatch(chatSocketReconnect(id));
    }
    dispatch(chatSocketModifiedData({ name: "unmount", data: false }));
  });
};

const websocketListener = (socket) => (dispatch) => {
  const listener = (event, args) => {
    const keys = (MAPPINGS[event] || event).split(":");
    const [primary, subkey, indicator] = keys;
    //console.log("primary, subkey, indicator", primary, subkey, indicator);
    switch (primary) {
      case "event":
        dispatch(eventHandler[subkey](indicator, args));
        break;
      case "wallet":
        dispatch(walletHandler[subkey](indicator, args));
        break;
      case "user":
        if (subkey === "donate") return;
        dispatch(userHandler[subkey](indicator, args));
        break;
      default:
        return;
    }
  };
  socket.onAny(listener);
};

const chatSocketListener = (socket) => (dispatch) => {
  const listener = (event, args) => {
    const keys = event.split(":");
    const [_, subkey, indicator] = keys;
    dispatch(chatHandler[subkey](indicator, args));
    // console.log("keys", keys);
    // console.log("primary", primary);
    // console.log("subkey", subkey);
    // console.log("indicator", indicator);
  };
  socket.onAny(listener);
};

export const socketEmit = (socket, event, args) => {
  socket.emit(event, args);
};
