import { defaultThemeColors } from "theme/defaultTheme/default-colors";

export default {
  green: {
    backgroundColor: defaultThemeColors.speedrunnersLime,
    color: defaultThemeColors.lincolnGreen,
    fontWeight: 600,
    cursor: "pointer",
    "&:hover,&:active,&:focus": {
      backgroundColor: defaultThemeColors.speedrunnersLime,
      color: defaultThemeColors.lincolnGreen,
      borderColor: defaultThemeColors.transparent,
    },
  },
  cyan: {
    backgroundColor: defaultThemeColors.electricBlue,
    color: defaultThemeColors.reinza,
    cursor: "pointer",
    "&:hover,&:active,&:focus": {
      backgroundColor: defaultThemeColors.electricBlue,
      color: defaultThemeColors.reinza,
      borderColor: defaultThemeColors.transparent,
    },
  },
  outside: {
    backgroundColor: defaultThemeColors.darkJungleGreen,
    color: defaultThemeColors.white,
    border: `2px solid ${defaultThemeColors.white}`,
    cursor: "pointer",
    "&:hover,&:active,&:focus": {
      border: `2px solid ${defaultThemeColors.white}`,
      backgroundColor: defaultThemeColors.darkJungleGreen,
      color: defaultThemeColors.white,
    },
  },
  outinline: {
    background: defaultThemeColors.transparent,
    color: defaultThemeColors.white,
    border: `2px solid ${defaultThemeColors.white}`,
    cursor: "pointer",
    "&:hover,&:active,&:focus": {
      border: `2px solid ${defaultThemeColors.white}`,
      backgroundColor: defaultThemeColors.transparent,
      color: defaultThemeColors.white,
    },
  },
  policeBlue: {
    backgroundColor: defaultThemeColors.policeBlue,
    color: defaultThemeColors.white,
    cursor: "pointer",
    "&:hover,&:active,&:focus": {
      backgroundColor: defaultThemeColors.policeBlue,
      color: defaultThemeColors.white,
      borderColor: defaultThemeColors.transparent,
    },
  },
  gray: {
    backgroundColor: defaultThemeColors.lavenderBlue,
    color: defaultThemeColors.reinza,
    cursor: "pointer",
    "&:hover,&:active,&:focus": {
      backgroundColor: defaultThemeColors.lavenderBlue,
      color: defaultThemeColors.reinza,
      borderColor: defaultThemeColors.transparent,
    },
  },
  text: {
    background: "none",
    borderColor: "transpanret",
    padding: 0,
    height: "inherit",
    cursor: "pointer",
    "&:hover,&:active,&:focus": {
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
  },
};
