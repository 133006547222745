import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Popover from "components/UI/Popover";
import { Box, Flex, Image, Text } from "components/UI/CustomRebass";
import { Border } from "components/UI/Border";
import Background from "components/UI/Background";
import { userImageUrl } from "config/utils";
import HeaderUserDropdown from "./headerUserDropdown";
import styled from "@emotion/styled";
import { eventModifiedData } from "store/eventSlice";

const PopoverStyle = styled(Popover)`
  .ant-popover-placement-bottomRight {
  }
`;

const CoinStyled = styled(Box)`
  .show {
    animation: shakers 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
`;

const HeaderUserProfile = () => {
  const dispatch = useDispatch();
  const [visiblePopover, setVisiblePopover] = useState(false);
  const { info, wallet } = useSelector(({ user }) => user);
  const { r } = useSelector(({ cache }) => cache);
  const { walletAnimation } = useSelector(({ event }) => event);

  const [animation, setAnimation] = useState("show");

  const location = useLocation();

  useEffect(() => {
    if (visiblePopover === true) {
      visiblePopoverChange();
    }
  }, [location]);

  const visiblePopoverChange = () => {
    setVisiblePopover(!visiblePopover);
  };

  useEffect(() => {
    if (walletAnimation) {
      setTimeout(() => {
        dispatch(eventModifiedData({ name: "walletAnimation", data: false }));
      }, 1500);
    }
  }, [walletAnimation]);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        ml={[15, 15, 20, 24]}
        mr={19}
        fontWeight={600}
        bg="darkJungleGreen"
        sx={{
          position: "relative",
          borderRadius: 14,
          lineHeight: "26px",
        }}
      >
        <Border
          borderWidth={2}
          borderColor="darkElectricBlue"
          borderRadius={14}
          pl={13}
          pr={40}
          alignItems="center"
          height={46}
          display="flex"
          sx={
            {
              // cursor: "pointer",
            }
          }
        >
          <Flex alignItems="center">
            <CoinStyled>
              <Background
                url="/assets/img/wallet-coin.svg"
                height={20}
                className={walletAnimation && "show"}
                width={19}
              ></Background>
            </CoinStyled>

            <Text color="white" ml={5} fontSize={16}>
              {wallet.freeCoin + wallet.zapapaCoin || 0}
            </Text>
          </Flex>
          <Popover
            visible={visiblePopover}
            onVisibleChange={visiblePopoverChange}
            content={
              <Box className="denemeeee" sx={{ position: "relative" }}>
                <HeaderUserDropdown
                  visiblePopover={visiblePopover}
                  setVisiblePopover={setVisiblePopover}
                />
              </Box>
            }
          >
            <Border
              borderColor="reinza"
              borderWidth={2}
              width={52}
              height={52}
              borderRadius={52}
              src={userImageUrl(info?.username, "profile", r)}
              as={Image}
              sx={{
                position: "absolute",
                right: -24,
                cursor: "pointer",
                top: -3,
              }}
            />
          </Popover>
        </Border>
      </Box>
    </Box>
  );
};

export default HeaderUserProfile;
