import { Border } from "components/UI/Border";
import { Box, Flex } from "components/UI/CustomRebass";
import { Link } from "components/UI/Link";
import { footermenu } from "config/enums";
import { enumToArray } from "config/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const Footer = ({ style }) => {
  const { t } = useTranslation();

  const menu = useMemo(() => {
    return enumToArray(footermenu);
  }, []);

  return (
    <Flex
      bg="reinza"
      alignItems="center"
      px={[10, 10, 10, 43, 43]}
      py={[20, 20, 20, 0]}
      justifyContent="space-between"
      height={["auto", "auto", "auto", 55]}
      mt={120}
      {...style}
    >
      <Box width={1} textAlign="center">
        <Box
          as="ul"
          display={[
            "inline",
            "inline",
            "inline",
            "inline",
            "inline",
            "inline",
            "flex",
          ]}
          justifyContent={["center"]}
        >
          {menu.map((x) => {
            return (
              <Border
                key={x.title}
                borderColor="independence"
                borderWidth={0}
                borderRightWidth={[0, 0, 1]}
                as="li"
                display={["inline-block", "inline-block", "inline-block"]}
                px={[15, 15, 15, 32]}
                sx={{
                  "&:last-child": {
                    paddingRight: 0,
                    border: 0,
                  },
                }}
              >
                <Link href={x.to} color="ceil" fontSize={16}>
                  {t(x.title)}
                </Link>
              </Border>
            );
          })}
        </Box>
      </Box>
    </Flex>
  );
};

export default Footer;
