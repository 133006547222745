import React from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

const SInputStyled = styled(Input)`
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : "12px"};
  &:focus {
    border-color: transparent;
  }
  &.ant-input-status-error {
    border-color: #ff4d4f;
    border-width: 1px;
    &:hover {
      border-color: #ff4d4f;
    }
  }
  .ant-input-show-count-suffix {
    color: ${(props) => props.theme.colors.vloudBurst};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: ${(props) => props.theme.colors.white};
    -webkit-box-shadow: 0 0 0px 1000px
      ${(props) => props.theme.colors.policeBlue} inset;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: ${(props) => props.theme.colors.white};
  }
`;

const SInput = ({ hasError, placeholder, placeholderTx, ...rest }) => {
  const { t } = useTranslation();
  return (
    <SInputStyled
      placeholder={placeholderTx ? t(placeholderTx) : placeholder}
      className={hasError ? "ant-input-status-error" : ""}
      {...rest}
    />
  );
};

export default SInput;
