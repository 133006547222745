import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { useTheme } from "emotion-theming";
import { useSelector } from "react-redux";
import { UserService } from "services";
import { Context as BlockedPeopleContext } from "context/BlockedPeopleContext";
import SearchBox from "components/SearchBox";
import { Box, Flex, Text } from "components/UI/CustomRebass";
import BlockedUserDropdown from "./components/BlockedUserDropdown";
import Loading from "components/Loading";
import BlockedUserNoResult from "./components/BlockedUserNoResult";
import { useTranslation } from "react-i18next";

const userService = new UserService();

const BlockedPeople = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { info } = useSelector(({ user }) => user);

  const {
    state: { blockList, blockSearchList },
    updateBlockedPeople,
  } = useContext(BlockedPeopleContext);

  useEffect(() => {
    setIsLoading(true);
    userService
      .getBlockedUserByUserId() // { resourceId: info._id }
      .then(({ data }) => {
        let arrangeData = data.map((item) => {
          return { ...item, isBlocked: true };
        });
        updateBlockedPeople({ blockList: arrangeData });
        setIsLoading(false);
      });
  }, []);

  const onSearchChange = _.debounce(
    (e) => {
      updateBlockedPeople({ loading: true });
      setSearchText(e.target.value);
      if (e.target.value === "") {
        updateBlockedPeople({ loading: false, blockSearchList: [] });
      }
      if (
        e.target.value.trim().length > 0 &&
        e.keyCode !== 13 &&
        e.target.value
      ) {
        userService.getUser({ username: e.target.value }).then((res) => {
          updateBlockedPeople({ loading: false });

          let alreadyBlocked = res.data.items
            .map((item) => {
              return { ...item, isBlocked: false };
            })
            .filter((fl) => fl.username !== info.username);
          alreadyBlocked = alreadyBlocked.map((x) => {
            let exist = null;
            blockList.filter((item) => {
              if (item.username === x.username) {
                exist = item;
              }
            });
            return exist
              ? {
                  ...x,
                  isBlocked: true,
                }
              : x;
          });
          updateBlockedPeople({ blockSearchList: alreadyBlocked });
        });
      }
    },
    [1000],
  );

  return (
    <>
      <Box mb={20}>
        <Text fontSize={[20, 20, 25, 28, 34]} color="white">
          {t("title.blocked_people")}
        </Text>
        <Text fontSize={16} color="lavenderBlue">
          {t("description.blocked_user_here")}
        </Text>
      </Box>
      <Box sx={{ position: "relative" }}>
        <SearchBox
          onKeyDown={(e) => {
            onSearchChange(e);
          }}
          borderRadius="14px"
          bgColor={colors.policeBlue}
          width="100%"
          height={50}
        />
        {isLoading ? (
          <Flex
            justifyContent="center"
            alignItems="center"
            width="100%"
            mt={50}
          >
            <Loading width={100} height={100} />
          </Flex>
        ) : blockList.length || (blockSearchList && blockSearchList.length) ? (
          <BlockedUserDropdown
            results={searchText ? blockSearchList : blockList}
            type={
              blockSearchList && blockSearchList.length ? "block" : "unblock"
            }
          />
        ) : (
          <BlockedUserNoResult />
        )}
      </Box>
    </>
  );
};

export default BlockedPeople;
