import { useTranslation } from "react-i18next";
import { Text } from "rebass";

const FormLabel = ({
  title,
  color = "lavenderBlue",
  fontSize = 14,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Text color={color} fontSize={fontSize} mb={12} {...rest}>
      {t(title)}
    </Text>
  );
};

export default FormLabel;
