import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "store/authSlice";
import { UserService } from "services";
import { responseMessage } from "config/utils";
import { routesUrl } from "routes/routes-url";
import { Border } from "components/UI/Border";
import Button from "components/UI/Button";
import { Box, Flex, Text } from "components/UI/CustomRebass";

const userService = new UserService();

const UserSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFreezeAccount = () => {
    userService
      .freezeAccount()
      .then(() => {
        navigate(`${routesUrl.home}`);
        responseMessage({
          type: "success",
          content: "description.account_freeze_successful",
        });
        dispatch(logout());
      })
      .catch(() => {
        responseMessage({
          type: "error",
          content: "description.account_freeze_failed",
        });
      });
  };

  const onDeleteAccount = () => {
    userService
      .deleteAccount()
      .then(() => {
        navigate(`${routesUrl.home}`);
        responseMessage({
          type: "success",
          content: "description.account_delete_successful",
        });
        dispatch(logout());
      })
      .catch(() => {
        responseMessage({
          type: "error",
          content: "description.account_delete_failed",
        });
      });
  };

  return (
    <>
      <Text fontSize={[20, 20, 25, 28, 34]} color="white" mb={12}>
        {t("title.user_settings")}
      </Text>
      <Border
        borderWidth={0}
        bg="yankeesBlue"
        borderRadius={16}
        px={20}
        pt={30}
        pb={20}
        mb={32}
      >
        <Border
          borderWidth={0}
          borderBottomWidth={1}
          borderColor="independence"
          pb={40}
          mb={30}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Text color="electricBlue" fontSize={[16, 16, 18, 20, 22]}>
                {t("title.freeze_account")}
              </Text>
              <Text color="lavenderBlue" fontSize={[12, 12, 13, 14, 16]}>
                {t("description.freeze_account_description")}
              </Text>
            </Box>

            <Button
              mode="outinline"
              color="lavenderBlue"
              height={52}
              width={170}
              mr={8}
              sx={{
                borderColor: "lavenderBlue",
                borderRadius: 7.4,
              }}
              onClick={() => onFreezeAccount()}
            >
              <Text fontSize={16.446}>{t("title.freeze")}</Text>
            </Button>
          </Flex>
        </Border>
        <Border
          borderWidth={0}
          borderBottomWidth={1}
          borderColor="independence"
          pb={30}
          mb={20}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Text color="electricBlue" fontSize={[16, 16, 18, 20, 22]}>
                {t("title.delete_your_account")}
              </Text>
              <Text
                color="lavenderBlue"
                fontSize={[12, 12, 13, 14, 16]}
                maxWidth={[380, 380, 400, 600, 600, 600]}
              >
                {t("description.delete_account_description")}
              </Text>
            </Box>
            <Button
              mode="outinline"
              color="lavenderBlue"
              height={52}
              width={170}
              px={0}
              mr={8}
              bg="magicPotion"
              sx={{
                // opacity: 0.3,
                border: 0,
                borderColor: "lavenderBlue",
                borderRadius: 7.4,
              }}
              onClick={() => onDeleteAccount()}
            >
              <Text fontSize={16.446} sx={{ opacity: "1 !important" }}>
                {t("title.delete_account")}
              </Text>
            </Button>
          </Flex>
        </Border>
      </Border>
    </>
  );
};

export default UserSettings;
