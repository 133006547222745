import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { getLocalStorage } from "config/utils";

function AuthRoute({ children }) {
  const token = getLocalStorage("token");

  let location = useLocation();

  const navigator = (path = "/") => (
    <Navigate to={path} state={{ from: location }} replace />
  );

  if (token) {
    return children;
  }
  return navigator();
}
export default AuthRoute;
