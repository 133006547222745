import React from "react";
import styled from "@emotion/styled";
import { Box } from "components/UI/CustomRebass";

const ScrollViewStyled = styled(Box)`
  overflow-y: auto;
  position: relative;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px;
    background-color: ${(props) =>
      props.bg ? props.bg : props.theme.colors.reinza};
  }
  ::-webkit-scrollbar {
    width: 3px;
    background-color: ${(props) =>
      props.scBg ? props.scBg : props.theme.colors.ceil};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 28px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${(props) =>
      props.schBg ? props.schBg : props.theme.colors.ceil};
  }
`;

const ScrollView = ({ children, ...rest }) => {
  return <ScrollViewStyled {...rest}>{children}</ScrollViewStyled>;
};

export default ScrollView;
