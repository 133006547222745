import React from "react";
import { Border } from "components/UI/Border";
import { Box, Flex } from "components/UI/CustomRebass";
import ModeratorDropdownItem from "./ModeratorDropdownItem";
import SearchUserNoResult from "./SearchUserNoResult";
import { useDispatch, useSelector } from "react-redux";

const ModeratorDropdown = ({
  results,
  iconType,
  handleSearchResult,
  setSearchText,
  wrapperRef,
  type,
  ...rest
}) => {
  const dispatch = useDispatch();

  const { moderators, moderatorsSearch } = useSelector(
    ({ setting }) => setting,
  );
  return (
    <Box ref={wrapperRef} width="100%" height="100%">
      {results && results.length > 0 && (
        <Border
          as="ul"
          borderWidth={0}
          borderTopWidth={type === "search" ? 2 : 0}
          borderColor="blueYonder"
          borderBottomRightRadius={15}
          borderBottomLeftRadius={15}
          width="100%"
          bg={type === "search" ? "independence" : ""}
          overflowY="auto"
          px={type === "search" ? 10 : 0}
          pt={25}
          pb={15}
          sx={{
            position: "absolute",
            transition: "0.5s ease",
            animationName: "searchdropdown",
            animationTimingFunction: "ease",
            animationDuration: "0.5s",
          }}
          {...rest}
        >
          <Flex flexWrap="wrap">
            {results &&
              results.length > 0 &&
              results.map((item) => (
                <Box
                  width={[1 / 2, 1 / 2, 1 / 3, 1 / 3, 1 / 3]}
                  key={item._id}
                  mr={0}
                  pr={4}
                >
                  <ModeratorDropdownItem
                    item={item}
                    type={type}
                    iconType={iconType}
                  />
                </Box>
              ))}
          </Flex>
        </Border>
      )}
      {results && results.length < 1 && <SearchUserNoResult />}
    </Box>
  );
};

export default ModeratorDropdown;
