import React from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "components/UI/CustomRebass";
import Icon from "components/UI/Icon";

const ModeratorNoResult = ({ type }) => {
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      color="ceil"
      justifyContent="center"
      borderWidth={0}
      borderTopWidth={2}
      borderColor="reinza"
      width="100%"
      py={15}
      mt={[100, 120, 140, 160, 180]}
    >
      <Icon icon="icon-moderator" fontSize={[55, 55, 65, 85, 98]} mb={16} />
      <Text fontSize={[12, 14, 14, 16, 16]} textAlign="center">
        <Text
          dangerouslySetInnerHTML={{
            __html:
              type === "title.sent_requests"
                ? t("title.no_moderators")
                : t("title.no_incoming_requests"),
          }}
        />
      </Text>
    </Flex>
  );
};

export default ModeratorNoResult;
