import React from "react";
import styled from "@emotion/styled";
import { typography, color, border } from "styled-system";
import { Box } from "components/UI/CustomRebass";

const BorderStyled = styled(Box)`
  ${typography} ${color} ${border}
`;

export const Border = ({ children = null, wrapperRef, ...rest }) => {
  return (
    <BorderStyled variant={"card"} ref={wrapperRef} {...rest}>
      {children}
    </BorderStyled>
  );
};
