import React from "react";
import styled from "@emotion/styled";
import { Box } from "components/UI/CustomRebass";

const BackgroundStyled = styled(Box)`
  background-repeat: no-repeat;
`;

const Background = ({ url, sx, ...rest }) => {
  return (
    <BackgroundStyled
      sx={{ background: `url(${url})`, ...sx }}
      {...rest}
    ></BackgroundStyled>
  );
};

export default Background;
