import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Border } from "components/UI/Border";
import { Box, Text, Flex } from "components/UI/CustomRebass";
import Icon from "components/UI/Icon";
import { Link } from "components/UI/Link";
import Popover from "components/UI/Popover";
import { routesUrl } from "routes/routes-url";
import InboxMessageItem from "./inboxMessageItem";
import CustomEmpty from "components/UI/CustomEmpty";
import { useLocation } from "react-router-dom";
import Loading from "components/Loading";
import ScrollView from "components/ScrollView";
import { useSelector } from "react-redux";

const IconStyled = styled(Box)`
  .show {
    border-bottom: 2px solid;
    padding-bottom: 26px;
  }
`;

const HeaderInbox = () => {
  const [notification, setNotification] = useState(false);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  let location = useLocation();
  const { messages } = useSelector(({ inbox }) => inbox);

  useEffect(() => {
    if (messages.filter((fl) => fl.detail.unreadMessageCount > 0).length) {
      setNotification(true);
    } else {
      setNotification(false);
      setIsLoading(false);
    }
    setIsLoading(true);
    if (visiblePopover) {
      setIsLoading(false);
    }
  }, [visiblePopover, messages]);

  useEffect(() => {
    if (visiblePopover === true) {
      visiblePopoverChange();
    }
  }, [location]);

  const visiblePopoverChange = () => {
    setVisiblePopover(!visiblePopover);
  };

  return (
    <Popover
      placement="bottom"
      mode="headerShort"
      overlayStyle={{
        width: 360,
      }}
      style={{
        padding: "0px",
      }}
      visible={visiblePopover}
      onVisibleChange={visiblePopoverChange}
      content={
        <Border borderRadius={19} width="auto" borderWidth={0}>
          <Text
            mt={8}
            mx={80}
            mb={20}
            fontSize={22}
            color="white"
            sx={{ textAlign: "center" }}
          >
            {t("title.inbox")}
          </Text>
          <ScrollView maxHeight="290px" px={16}>
            <Box mb={12}>
              {isLoading ? (
                <Flex justifyContent="center">
                  <Loading width={50} height={50} />
                </Flex>
              ) : messages.length > 0 ? (
                messages
                  .filter(
                    (fl, ind) => ind < 8 || fl.detail.unreadMessageCount > 0,
                  )
                  .map((item) => {
                    return (
                      <InboxMessageItem key={item._id} messageItem={item} />
                    );
                  })
              ) : (
                <CustomEmpty
                  icon="icon-send"
                  message="description.there_are_currently_no_messages"
                  flexDirection="column"
                  my={30}
                  textOpt={{ pt: 20, fontSize: 16 }}
                  iconOpt={{ fontSize: 76, color: "ceil" }}
                />
              )}
            </Box>
          </ScrollView>
          <Link href={routesUrl.inbox}>
            <Text
              fontSize={16}
              display="flex"
              justifyContent="center"
              px={76}
              pt={24}
              color="lavenderBlue"
              sx={{ textDecoration: "underline", textAlign: "center" }}
            >
              {t("title.viewAllMessages")}
            </Text>
          </Link>
        </Border>
      }
    >
      <IconStyled sx={{ position: "relative" }}>
        {notification && (
          <Box
            sx={{
              position: "absolute",
              right: "-4px",
              top: "-4px",
              borderRadius: "14px",
              zIndex: "1",
            }}
            width={14}
            height={14}
            bg="magicPotion"
          ></Box>
        )}

        <Icon
          icon={
            visiblePopover || location.pathname === "/inbox"
              ? "icon icon-chat-fill show"
              : "icon icon-chat"
          }
          fontSize={26}
          color={
            visiblePopover || location.pathname === "/inbox"
              ? "electricBlue"
              : "vloudBurst"
          }
          sx={{ cursor: "pointer" }}
        />
      </IconStyled>
    </Popover>
  );
};

export default HeaderInbox;
