import React from "react";
import Background from "components/UI/Background";
import { Border } from "components/UI/Border";
import { Box } from "components/UI/CustomRebass";

const Loading = ({ width, height, ...rest }) => {
  return (
    <Box {...rest}>
      <Background
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={width}
        height={height}
        url={"/assets/img/loadingLogo.svg"}
        sx={{
          borderRadius: "50%",
          backgroundColor: "#161e2d",
          boxShadow: "inset 0 0 34px rgba(0, 0, 0, 0.21)",
          backgroundSize: "48%",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <Border
          borderRadius={"50%"}
          borderWidth={3}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            animation: "loadingSpin 1.5s linear infinite",
          }}
          borderColor="transparent"
          borderTopColor="#64f6ff"
        ></Border>
      </Background>
    </Box>
  );
};

export default Loading;
