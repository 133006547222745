import { createSlice } from "@reduxjs/toolkit";
import { UserService } from "services";
import { notificationFirebase } from "./actions/notificationAction";

const userService = new UserService();
const initialState = {
  notify: false,
  notifications: [],
  notificationPermission: false,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    notificationModifiedData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
    notificationUnshiftModifiedData: (state, action) => {
      state[action.payload.name] = [
        action.payload.data,
        ...state[action.payload.name],
      ];
    },
    notificationResetData: () => initialState,
  },
});

export const {
  notificationModifiedData,
  notificationResetData,
  notificationUnshiftModifiedData,
} = notificationSlice.actions;

export const getNotifications = () => (dispatch) => {
  userService.getNotifications().then(({ data }) => {
    dispatch(notificationFirebase());
    dispatch(notificationModifiedData({ name: "notifications", data }));
  });
};

export default notificationSlice.reducer;
