/* eslint-disable import/no-anonymous-default-export */
export default {
  "electric-blue": "#64F6FF",
  "speedrunners-lime": "#B9FF47",
  "magic-potion": "#FF4B59",
  reinza: "#212C46",
  "dark-jungle-green": "#131A26",
  independence: "#415074",
  "american-blue": "#2E4271",
  ceil: "#91A7CC",
  "lavender-blue": "#CBDEFF",
  alabaster: "#fafafa",
  "police-blue": "#374463",
  "light-cobalt-blue": "#8DADE5",
  "dark-electric-blue": "#4C5D7A",
  independence2: "#92A6C8",
  "blue-yonder": "#5B72A8",
  lavender: "#E8E9FF",
  "jordy-blue": "#969EFE",
  "lincoln-green": "#2A4104",
  "purple-navy": "#4C5C7A",
  "cotton-candy": "#FFBBD4",
  "chinese-pink": "#E17EA2",
  "twilight-lavender": "#953F6B",
  "gargoyle-gas": "#FFD747",
  "bleached-cedar": "#2E2239",
  "clouds-end": "#CED4DA",
  "vloud-burst": "#91A6CC",
  "philippine-silver": "#B3B0B0",
  "silver-polish": "#C6C6C6",
  charcoal: "#2D3C56",
  "carnation-pink": "#ffa6c1",
  "purple-pizzazz": "#ff4eed",
  "french-fuchsia": "#ff3689",
  "pastel-red": "#ff6464",
  "sea-green": "#07ffc4",
  "ripe-mango": "#ffcf23",
  "violets-are-blue": "#907eff",
  "very-light-malachite-green": "#4dff7f",
  "sasquatch-socks": "#ff417a",
  "ufo-green": "#25d366",
  "vivid-cerulean": "#00acee",
  "chinese-blue": "#3B5998",
  verdigris: "#479dad",
  "sea-serpent": "#57CEDA",
  "cerise-pink": "#F53D78",
  gainsboro: "#DDDDDD",
  blueberry: "#5486FF",
  "spiro-disco-ball": "#22BBF5",
  "deep-cerise": "#D42F8B",
  "maximum-red": "#D72A26",
  "yankees-blue": "#212C46DE",
  "yankees-blues": "#1A233A",
  mauvelous: "#FCA6AD",
};
