const notification = Object.freeze({
  startEvent: {
    value: "startevent",
    icon: "icon icon-event",
    content: "description.started_the_event",
    text: (notification) => ({
      left: `${notification?.senderUser?.username || notification?.senderUser}`,
      mid: "description.started_the_event",
      right: "",
    }),
    color: "magicPotion",
  },
  event: {
    value: "event",
    icon: "icon icon-event",
    content: "description.created_an_event",
    text: (notification) => ({
      left: `${notification?.senderUser?.username || notification?.senderUser}`,
      mid: "description.created_an_event",
      right: "",
    }),
    color: "magicPotion",
  },
  rankings: {
    value: "rankings",
    icon: "icon icon-rankings",
    content: "description.you_are_ranked",
    text: (notification) => ({
      left: "description.you_are_ranked",
      mid: `${notification.message}`,
      right: "description.in_rankings",
    }),
    color: "speedrunnersLime",
  },
  video: {
    value: "video",
    icon: "icon icon-trending",
    content: "description.your_video_is_trending",
    text: () => ({
      left: "description.your_video_is",
      mid: "description.trending",
      right: "",
    }),
    color: "magicPotion",
  },
  follow: {
    value: "follow",
    icon: "icon icon-follow",
    content: "description.started_following_you",
    text: (notification, t) => ({
      left: t("description.start_follow", {
        username:
          notification?.senderUser?.username || notification?.senderUser,
      }),
      mid: "description.following_you",
      right: "",
    }),
    color: "speedrunnersLime",
  },
  moderator: {
    value: "moderator",
    icon: "icon icon-moderator",
    content: "title.you_cast_as_a_moderator",
    text: (notification, t) => ({
      left: notification?.senderUser?.username,
      mid: notification.message,
    }),
    color: "speedrunnersLime",
  },
});

export default notification;
