const headermenu = Object.freeze({
  home: {
    title: "title.home",
    to: "/",
    icon: "icon icon-home",
    activeIcon: "icon icon-active-home",
  },
  rankings: {
    title: "title.rankings",
    to: "rankings",
    icon: "icon icon-rankings",
    activeIcon: "icon icon-rankings-filled",
  },
  video: {
    title: "title.video",
    to: "video",
    icon: "icon icon-video",
    activeIcon: "icon icon-active-video",
  },
});

export default headermenu;
