import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage as _onMessage,
} from "firebase/messaging";

export function init() {
  if (!window.Notification) return;

  const firebaseApp = initializeApp({
    apiKey: "AIzaSyArytdYynhNi80vHWok81NuYjS63k_Gfrk",
    authDomain: "zapapa-2aa14.firebaseapp.com",
    projectId: "zapapa-2aa14",
    storageBucket: "zapapa-2aa14.appspot.com",
    messagingSenderId: "95176078252",
    appId: "1:95176078252:web:712ff733286c67bb708009",
    measurementId: "G-QGE7KKXHFV",
  });

  return getMessaging(firebaseApp);
}

export function requestPermission(messaging, callback) {
  return window.Notification?.requestPermission?.().then(() =>
    getToken(messaging, {
      vapidKey:
        "BJVtqR6aW7fbz7npBwCjYJW7fvExNhu0VwnIs0GzZ2vVGWXNycIHjblRXuPOfV8S4rMoqbCDv2cTKpNLeSV0hHc",
    }).then(callback),
  );
}

export function onMessage(messaging, callback) {
  _onMessage(messaging, callback);
}

export default {
  init,
  onMessage,
  requestPermission,
};
