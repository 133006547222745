import React, { forwardRef } from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

const { TextArea } = Input;

const TextAreaStyled = styled(TextArea)`
  border-radius: 12px;
  &.ant-input-textarea {
    &:after {
      color: ${(props) => props.theme.colors.vloudBurst};
      margin-top: 3px;
    }
    textarea {
      border-radius: 12px;
    }
  }
  &.ant-input-status-error {
    textarea {
      border-color: #ff4d4f;
    }
  }
`;

const STextarea = forwardRef((props, ref) => {
  const { hasError, placeholder, placeholderTx, ...rest } = props;
  const { t } = useTranslation();

  return (
    <TextAreaStyled
      ref={ref}
      rows={4}
      className={hasError ? "ant-input-status-error" : ""}
      placeholder={placeholderTx ? t(placeholderTx) : placeholder}
      {...rest}
    />
  );
});

export default STextarea;
