import React, { useState } from "react";
import { Provider as BlockedPeopleProvider } from "context/BlockedPeopleContext";
import { Provider as SettingsProvider } from "context/SettingsContext";
import { Box, Flex, Text } from "components/UI/CustomRebass";
import Icon from "components/UI/Icon";
import { Border } from "components/UI/Border";
import LeftMenu from "./components/LeftMenu";
import BroadcastSettings from "./BroadcastSettings/index";
import BlockedPeople from "./BlockedPeople/index";
import Moderator from "./Moderator";
import { useTranslation } from "react-i18next";
import UserSettings from "./UserSettings";

const Settings = () => {
  const { t } = useTranslation();
  const [selectedMenuItem, setSelectedMenuItem] = useState(1);

  return (
    <SettingsProvider>
      <Flex justifyContent="center">
        <Border
          width={[307, 307, 307, 307, 400]}
          height="100%"
          borderWidth={0}
          borderRadius={15}
          bg="reinza"
          pt={30}
        >
          <Flex color="white" alignItems="center" px={15}>
            <Icon icon="icon-settings" fontSize={23} />
            <Text ml={15} fontSize={24}>
              {t("title.settings")}
            </Text>
          </Flex>
          <LeftMenu
            selectMenu={selectedMenuItem}
            setSelectMenu={setSelectedMenuItem}
          />
        </Border>
        <Box width={[716, 716, 716, 850, 950]} ml={24}>
          {selectedMenuItem === 1 ? (
            <BroadcastSettings />
          ) : selectedMenuItem === 2 ? (
            <BlockedPeopleProvider>
              <BlockedPeople />
            </BlockedPeopleProvider>
          ) : selectedMenuItem === 6 ? (
            <UserSettings />
          ) : (
            <Moderator selectedMenu={selectedMenuItem} />
          )}
        </Box>
      </Flex>
    </SettingsProvider>
  );
};

export default Settings;
