import React from "react";
import { Box, Text } from "components/UI/CustomRebass";
import FormLabel from "./FormLabel";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const ErrorText = styled(Text)`
  position: absolute;
  bottom: -22px;
`;

const InputPrefixStyled = styled(Box)`
  &.ant-form-item-has-error {
    .ant-picker {
      border-color: #ff4d4f;
    }
  }
  .ant-input-prefix {
    position: relative;
    padding-right: 13px;
    margin-right: 15px;
    width: 32px;
    &:after {
      content: "";
      width: 1px;
      height: 32px;
      position: absolute;
      right: 0;
      top: 50%;
      background: #91a7cc;
      transform: translateY(-50%);
    }
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: transparent;
  }
`;
// purpleNavy
const withFormLabel =
  (WrappedComponent) =>
  ({ errormessage, title, prefixStyled, labelOpt, transVariable, ...rest }) => {
    const { t } = useTranslation();

    return (
      <InputPrefixStyled
        className={errormessage ? "ant-form-item-has-error" : ""}
        mb={32}
        sx={{ position: "relative" }}
        {...prefixStyled}
      >
        {title && <FormLabel title={title} {...labelOpt} />}
        <WrappedComponent {...rest} hasError={!!errormessage} />
        <ErrorText fontSize={11} color="magicPotion">
          {errormessage && `*${t(errormessage, transVariable)}`}
        </ErrorText>
      </InputPrefixStyled>
    );
  };

export default withFormLabel;
