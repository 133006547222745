import React, { useState } from "react";
import { Button, Flex } from "components/UI/CustomRebass";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";

const withButtonsNotifly =
  (WrappedComponent) =>
  ({ item, themeColors, handleOnClick }) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    return (
      <>
        <WrappedComponent item={item}>
          <Flex>
            <Button
              mr={10}
              mode="outinline"
              width="50%"
              height={35}
              fontSize={14}
              justifyContent="center"
              alignItems="center"
              display="flex"
              bg={themeColors.transparent}
              onClick={() => {
                if (!loading) {
                  setLoading(true);
                  handleOnClick(false);
                }
              }}
              color={themeColors.lavenderBlue}
              fontWeight={600}
              sx={{
                borderColor: themeColors.lavenderBlue,
                borderWidth: 1,
                borderStyle: "solid",
                cursor: "pointer",
                lineHeight: 0,
              }}
            >
              {/* {loading && <Loading width={20} height={20} />} */}
              {t("title.decline")}
            </Button>
            <Button
              ml={10}
              width="50%"
              height={35}
              fontSize={14}
              justifyContent="center"
              alignItems="center"
              display="flex"
              bg={themeColors.electricBlue}
              color={themeColors.lincolnGreen}
              onClick={() => {
                if (!loading) {
                  setLoading(true);
                  handleOnClick(true);
                }
              }}
              fontWeight={600}
              sx={{
                cursor: "pointer",
                lineHeight: 0,
                "&:hover": {
                  color: themeColors.policeBlue,
                },
              }}
            >
              {/* {loading && <Loading width={20} height={20} />}s */}
              {t("title.accept")}
            </Button>
          </Flex>
        </WrappedComponent>
      </>
    );
  };

export default withButtonsNotifly;
