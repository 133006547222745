import React from "react";
import styled from "@emotion/styled";
import { Box } from "components/UI/CustomRebass";

const IconStyled = styled(Box)`
  .spinner-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spinner-custom {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    // top: 50%;
    // left: 50%;
    // margin: -25px 0 0 -25px;
    // width: 50px;
    // height: 50px;

    & .path {
      stroke: cyan;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  .spinnertwo-custom {
    animation: rotatetwo 2s linear infinite;
    z-index: 2;
    position: absolute;
    // top: 50%;
    // left: 50%;
    // margin: -25px 0 0 -25px;
    // width: 50px;
    // height: 50px;

    & .pathtwo {
      stroke: #b9ff47;
      stroke-linecap: round;
      animation: dashtwo 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotatetwo {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dashtwo {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 60, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 60, 150;
      stroke-dashoffset: -124;
    }
  }

  .spinnerthree-custom {
    animation: rotatethree 2s linear infinite;
    z-index: 2;
    position: absolute;
    // top: 50%;
    // left: 50%;
    // margin: -25px 0 0 -25px;
    // width: 50px;
    // height: 50px;

    & .paththree {
      stroke: #ffbbd4;
      stroke-linecap: round;
      animation: dashthree 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotatethree {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dashthree {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 30, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 30, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const SearchLoadingIcon = ({ width = 35, height = 35, ...rest }) => {
  return (
    <IconStyled {...rest}>
      <div className="spinner-container" width={width} height={height}>
        <svg className="spinner-custom" viewBox="0 0 50 50" width={width}>
          <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>

        <svg className="spinnertwo-custom" viewBox="0 0 50 50" width={width}>
          <circle
            className="pathtwo"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>

        <svg className="spinnerthree-custom" viewBox="0 0 50 50" width={width}>
          <circle
            className="paththree"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div>
    </IconStyled>
  );
};

export default SearchLoadingIcon;
