/* eslint-disable import/no-anonymous-default-export */
import { defaultThemeColors } from "./default-colors";
import { buttons, popover } from "./variants";

export default {
  themeName: "light",
  breakpoints: [
    "480px",
    "768px",
    "992px",
    "1200px",
    "1400px",
    "1600px",
    "1900px",
  ],
  points: [480, 768, 993, 1200, 1400, 1600, 1900],
  colors: defaultThemeColors,
  font: ["Axiforma", "Roboto", "RobotoSemibold", "RobotoBold"],
  fontSizes: [],
  weight: [100, 300, 400, 500, 600, 700],
  radii: [],
  space: [],
  shadows: {},
  variants: {
    card: {
      border: `1px solid`,
      borderColor: "darkElectricBlue",
      borderRadius: "2px",
    },
    popover,
    buttons,
  },
};
