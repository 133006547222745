import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { responseMessage, userImageUrl } from "config/utils";
import { UserService } from "services";
import { ButtonAnimation } from "assets/styled/animationClass";
import { Box, Flex, Image, Text } from "components/UI/CustomRebass";
import Modal from "components/UI/Modal";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import { Border } from "components/UI/Border";
import { useDispatch, useSelector } from "react-redux";
import { settingModifiedData } from "store/settingSlice";

const userService = new UserService();

const ModeratorModal = ({
  item,
  type,
  isModalVisible,
  closeModal,
  ...rest
}) => {
  const dispatch = useDispatch();

  const { moderators, moderatorsSearch } = useSelector(
    ({ setting }) => setting,
  );
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = () => {
    setIsLoading(true);
    const serviceUrl =
      type === "assign"
        ? "inviteModerator"
        : item.status === "pending"
        ? "deleteInvitation"
        : "deleteModerator";
    const acceptPayload = {
      type: "user_moderator",
      userId: item._id,
    };

    userService[serviceUrl](type === "assign" ? acceptPayload : item._id)
      .then(({ data }) => {
        responseMessage({
          type: "success",
          content: "description.invitation_sent",
        });
        setIsLoading(false);
        dispatch(
          settingModifiedData({
            name: "moderatorsSearch",
            data: null,
          }),
        );

        if (type === "assign") {
          dispatch(
            settingModifiedData({
              name: "moderators",
              data: [...moderators, { ...data, status: "pending" }],
            }),
          );
        } else {
          const updatedModerators = moderators.map((obj) => {
            if (obj._id === item._id) {
              return { ...obj, status: "declined" };
            }

            return obj;
          });

          dispatch(
            settingModifiedData({
              name: "moderators",
              data: [
                ...updatedModerators.filter((x) => x.status !== "declined"),
              ],
            }),
          );
        }
        closeModal();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  return (
    <Box pl={20}>
      <Modal
        title={
          type === "assign"
            ? "title.assign_moderator"
            : "title.delete_moderator_option"
        }
        visible={isModalVisible}
        onCancel={closeModal}
        width={508}
        style={{ maxHeight: "90vh" }}
        {...rest}
      >
        <Box>
          <Flex
            pl={20}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ position: "relative" }}>
              <Image
                width={126}
                height={126}
                mt={78}
                mb={49}
                src={userImageUrl(
                  type === "assign" ? item?.username : item?.typeUsername,
                  "profile",
                )}
                sx={{
                  borderRadius: "50%",
                }}
              />
              <Border
                borderWidth={0}
                bg="gargoyleGas"
                width={40}
                height={40}
                sx={{
                  position: "absolute",
                  bottom: 46,
                  right: 4,
                  borderRadius: "50%",
                }}
              >
                <Icon
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  icon="icon-moderator"
                  fontSize={20}
                  width="100%"
                  height="100%"
                />
              </Border>
            </Box>
            <Text fontSize={18} mb={57} color="lavenderBlue" textAlign="center">
              {type === "assign" ? (
                <Text
                  dangerouslySetInnerHTML={{
                    __html: t("title.you_cast_user_as_moderator", {
                      username: item.username,
                    }),
                  }}
                />
              ) : (
                <Text
                  dangerouslySetInnerHTML={{
                    __html: t("title.delete_moderator_content"),
                  }}
                />
              )}
            </Text>
          </Flex>

          <Flex>
            <Button
              flex="1"
              mode="outinline"
              height={72}
              onClick={closeModal}
              text="title.no"
              fontSize={16}
              fontWeight={600}
              mr={8}
              sx={{
                borderRadius: 16,
              }}
            />
            <ButtonAnimation
              loading={isLoading}
              flex="1"
              onClick={handleSubmit}
              text={type === "assign" ? "title.yes_sure" : "title.delete"}
              fontSize={16}
              fontWeight={600}
              mode="cyan"
              px={40}
              py={8}
              height={72}
              sx={{
                borderRadius: 16,
              }}
            />
          </Flex>
        </Box>
      </Modal>
    </Box>
  );
};

export default ModeratorModal;
