import React from "react";
import { enumToArray } from "config/utils";
import moderatorPages from "./components/moderatorPages";
import { Box } from "components/UI/CustomRebass";

const Moderator = ({ selectedMenu }) => {
  const renderContent = () => {
    return enumToArray(moderatorPages).map((x) => {
      if (x.id === selectedMenu) {
        return <Box key={x.id}>{x.render(x)}</Box>;
      }
    });
  };

  return <Box>{renderContent()}</Box>;
};

export default Moderator;
