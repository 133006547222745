export const routesUrl = {
  home: "/",
  eventPage: "/event/:id",
  rankings: "/rankings",
  inbox: "/inbox",
  profile: "/profile",
  video: "/video",
  profilePage: "/profile/:username",
  signIn: "/sign-in",
  signUp: "/sign-up",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset_password",
  search: "/search",
  wallet: "/wallet",
  settings: "/settings",
  notFound: "/404",
  aboutUs: "/about-us",
  privacyPolicy: "/privacy-policy",
  conditionOfUse: "/condition-of-use",
  cookieStatement: "/cookiestatement",
  contact: "/contact",
  payment: "/payment/:id",
};
