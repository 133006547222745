import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "components/UI/CustomRebass";
import InvitationPopup from "components/InvitationPopup";
import MoneyboxPopup from "components/MoneyboxPopup";

const WalletLayout = () => {
  return (
    <Box>
      <Box
        as="main"
        sx={{
          minHeight: "calc(100vh)",
          maxWidth: "2000px",
          margin: "0 auto",
        }}
      >
        <Outlet />
      </Box>
      <InvitationPopup />
      <MoneyboxPopup />
    </Box>
  );
};

export default React.memo(WalletLayout);
