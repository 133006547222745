import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Flex, Text } from "components/UI/CustomRebass";
import Icon from "components/UI/Icon";
import ProfilePopup from "./profilePopup";
import styled from "@emotion/styled";
import { useModal } from "config/hooks/useModal";
import { Border } from "components/UI/Border";
import Modal from "components/UI/Modal";
import LogoutModalTemplate from "components/ModalTemplate/LogoutModalTemplate";
import languageFormElements from "config/formElements/language-form-elements";
import { convertFormElements, getEnumValue } from "config/utils";
import { LanguageRadioTemplate } from "config/formTemplate/language-radio-template";
import { SRadio } from "components/UI/Forms/SFormElements";
import ScrollView from "components/ScrollView";
import i18n from "i18next";
import { languages } from "config/enums";
import { CssTrans } from "assets/styled";
import { useTranslation } from "react-i18next";

const FlexStyled = styled(Flex)`
  div {
    margin: 0;
  }
`;

const HeaderUserDropdown = ({ visiblePopover, setVisiblePopover }) => {
  const { isModalVisible, openModal, closeModal } = useModal();
  const [state, setstate] = useState("main");
  const [val, setVal] = useState(i18n.language);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    !visiblePopover && setstate("main");
  }, [visiblePopover]);

  const onLogoutClick = () => {
    setVisiblePopover(false);
    openModal();
  };

  const formElements = useMemo(() => {
    const els = languageFormElements();
    return convertFormElements({ els });
  }, []);

  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <>
      <Border
        borderWidth={0}
        width={268}
        minHeight={160}
        sx={{ overflow: "hidden", position: "relative" }}
      >
        <CssTrans
          in={state === "main"}
          timeout={500}
          classNames="menu-primary "
          unmountOnExit
        >
          <Box className="menu" px={16}>
            <ProfilePopup />
            <Border
              pb={18}
              borderColor="independence"
              borderWidth={0}
              borderBottomWidth={1}
              mb={18}
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                flex={1}
                onClick={() => navigate("/settings")}
              >
                <Text as="span">{t("title.settings")}</Text>
                <Icon icon="icon icon-arrow-right" />
              </Flex>
            </Border>
            <Border
              pb={18}
              borderColor="independence"
              borderWidth={0}
              borderBottomWidth={1}
              mb={18}
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                flex={1}
                onClick={() => navigate("/wallet")}
              >
                <Text as="span">{t("title.my_wallet")}</Text>
                <Icon icon="icon icon-arrow-right" />
              </Flex>
            </Border>
            <Border
              display="flex"
              pb={18}
              borderColor="independence"
              borderWidth={0}
              borderBottomWidth={1}
              mb={18}
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                flex={1}
                onClick={() => setstate("secondary")}
              >
                <Text as="span">
                  {t("title.languages")}:
                  <Text as="span" color="electricBlue" ml={5}>
                    {getEnumValue(languages, val).label}
                  </Text>
                </Text>
                <Icon icon="icon icon-arrow-right" />
              </Flex>
            </Border>

            <Flex
              justifyContent="space-between"
              alignItems="center"
              sx={{ cursor: "pointer" }}
              onClick={() => onLogoutClick()}
            >
              <Text>{t("title.logout")}</Text>
              <Icon icon="icon icon-arrow-right" />
            </Flex>
          </Box>
        </CssTrans>
        <CssTrans
          in={state === "secondary"}
          timeout={500}
          classNames="menu-secondary "
          unmountOnExit
        >
          <Box className="menu">
            <Border
              border="none"
              borderBottom="1px solid"
              sx={{ cursor: "pointer" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              pb={12}
              mb={20}
              mx={16}
              onClick={() => setstate("main")}
            >
              <Icon icon="icon icon-arrow-left" mr="auto" />
              <Text flex={1} textAlign="center" justifyContent="center">
                {t("title.languages")}
              </Text>
            </Border>
            <ScrollView maxHeight={480} pr={20} px={16}>
              <FlexStyled
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  "&>div": {
                    width: "100%",
                  },
                }}
              >
                <SRadio
                  {...formElements.language}
                  onChange={(e) => {
                    setVal(e.target.value);
                    changeLanguage(e);
                  }}
                  opt={LanguageRadioTemplate}
                  value={val}
                />
              </FlexStyled>
            </ScrollView>
          </Box>
        </CssTrans>
      </Border>

      <Modal
        title="title.logout"
        visible={isModalVisible}
        onCancel={closeModal}
        width={510}
        style={{ maxHeight: "90vh" }}
      >
        <LogoutModalTemplate closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default HeaderUserDropdown;
