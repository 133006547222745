import React from "react";
import { Text } from "components/UI/CustomRebass";
import { Border } from "components/UI/Border";

const SearchItem = ({ item, ...rest }) => {
  return (
    <Border
      as="li"
      fontSize={16}
      color="white"
      py={8}
      borderWidth={0}
      borderBottomWidth={1}
      borderColor="purpleNavy"
      sx={{
        "&:last-child": {
          border: "none",
        },
        cursor: "pointer",
      }}
      {...rest}
    >
      <Text>{item.name}</Text>
    </Border>
  );
};

export default SearchItem;
