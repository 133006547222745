import { message, notification } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import jwt_decode, { JwtPayload } from "jwt-decode";
import _ from "lodash";

const utc = require("dayjs/plugin/utc");
const relativeTime = require("dayjs/plugin/relativeTime");
const duration = require("dayjs/plugin/duration");
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(duration);

const time = dayjs();

export const setLocalStorage = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
};

export const getLocalStorage = (name) => {
  const value =
    localStorage.getItem(name) && localStorage.getItem(name).includes('"')
      ? JSON.parse(localStorage.getItem(name))
      : localStorage.getItem(name);
  return value;
};

export const removeLocalStorage = (name) => {
  localStorage.removeItem(name);
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const convertFormElements = ({ els, control, defaultValues }) => {
  Object.keys(els).map((key) => {
    els[key].control = control;
    if (defaultValues) els[key].defaultValue = defaultValues[key];
    return "";
  });
  return els;
};

export const enumToArray = (enm) => {
  const arr = [];
  const keys = Object.keys(enm);
  keys.forEach((key) => {
    arr.push(enm[key]);
  });
  return arr;
};

export const getEnumValue = (enumData, key) => {
  if (key || key === 0) return enumData[key.toString()];
  return "";
};

export const responseNotify = ({
  type = "warn",
  placement = "top",
  ...rest
}) => {
  notification[type]({
    duration: 3,
    placement,
    ...rest,
  });
};

export const responseMessage = ({ type = "info", content, ...rest }) => {
  return message[type]({
    content: t(content),
    duration: 3,
    ...rest,
  });
};

export const dateToTimeFormatter = (date, format) => {
  return dayjs(date).format(format);
};

export const getDiff = ({ end, start = time, format = "HH:mm:ss" }) => {
  start = start.local().format();
  end = dayjs(end).local().format();
  const diff = Math.abs(dayjs(end).diff(start));
  return dayjs.duration(diff).format(format);
};

export const addDateTime = (currentTime, number, type) => {
  const c = dayjs(currentTime);
  return c.add(number, type);
};

export const minToTimeFormatter = (date, format) => {
  return dayjs.duration(date, "m").format(format);
};

export const fromNow = (date, suffix = true) => {
  return dayjs(date).fromNow(!suffix);
};

export const userImageUrl = (username, type = "profile", cache = "") => {
  return `${
    process.env.REACT_APP_ASSETS_ENDPOINT
  }/user-images/${type}/${username}.png${cache && `?${cache}`}`;
};

export const eventImageUrl = (eventId, type = "stream", cache = "") => {
  return `${
    process.env.REACT_APP_ASSETS_ENDPOINT
  }/event-images/${type}/${eventId}.png${cache && `?${cache}`}`;
};

export const getUserInfo = () => {
  const token = localStorage.getItem("token");
  if (!token) return {};
  return jwt_decode(token);
};

export const isMe = (name) => {
  const { username } = getUserInfo();
  return username === name;
};

export const messageColors = () => {
  const colorArr = ["#FFADBC", "#FFC899", "#B589FF", "#74FFFF"]; // TODO: fix color
  const color = colorArr[Math.floor(Math.random() * colorArr.length)];
  return color;
};

export const depthItemModified = (curObj, name, keys, value) => {
  let obj = curObj[name];
  for (var i = 0; i < keys.length - 1; i++) {
    obj = obj[keys[i]];
  }
  obj[keys[i]] = value;
};

export const dayMonthYearFormatter = (date) => {
  return dayjs(date).format("D MMMM YYYY");
};

export const cooldown = (cooldown) => {
  const interval = 30 * 60;
  const closeTime = new Date(cooldown);
  const currentTime = new Date();
  const difference = closeTime - currentTime + interval;
  return difference;
};

export const checkPermissions = (list, codes) => {
  const perm = Object.keys(list).filter((x) => list[x]);
  for (let i = 0; i < perm.length; i += 1) {
    if (perm.includes(codes[i])) {
      return true;
    }
  }
  return false;
};

export async function getDeviceInfo() {
  if (!navigator.mediaDevices?.enumerateDevices) {
    console.log("enumerateDevices() not supported.");
  } else {
    return await navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        return {
          audioInputDevices: devices.filter(
            (device) => device.kind === "audioinput",
          ),
          videoInputDevices: devices.filter(
            (device) => device.kind === "videoinput",
          ),
          audioOutputDevices: devices.filter(
            (device) => device.kind === "audiooutput",
          ),
          hasAudioInputDevices: devices.some(
            (device) => device.kind === "audioinput",
          ),
          hasVideoInputDevices: devices.some(
            (device) => device.kind === "videoinput",
          ),
        };
      })
      .catch((err) => {
        console.error(`${err.name}: ${err.message}`);
      });
  }
}

export async function getUserMediaInit(obj, callback) {
  const media = navigator.mediaDevices
    .getUserMedia(obj)
    .then(callback)
    .catch((err) => {
      handleError(err);
      callback("Permission denied");
    });

  return media;
}

function handleError(error) {
  responseMessage({
    type: "error",
    content: error,
  });
}

export const paginate = (array, n) => {
  const pageSize = Math.ceil(array.length / n);

  return Array.from({ length: pageSize }, (_, index) => {
    const start = index * n;
    return array.slice(start, start + n);
  });
};

export const createBase64 = (event, setImage) => {
  if (event.file) {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const image = reader.result;
      setImage(image);
    });
    reader.readAsDataURL(event.file.originFileObj);
  }
};

export const compresDate = (date) => {
  const now = dayjs().valueOf();
  const diff = dayjs(date).valueOf();
  return now < diff;
};

export const disableMinNumber = (number) => {
  const arr = [];
  for (let index = 0; index < number; index++) {
    arr.push(index);
  }
  return arr;
};

export const checkEventToken = (eventId) => {
  const token = localStorage.getItem("eventToken");
  if (!token) return false;
  const { eventId: id } = jwt_decode(token);
  if (id === eventId) {
    return true;
  }
  return false;
};

export const checkTokenExpires = (eventId) => {
  const token = localStorage.getItem("token");
  try {
    if (!token) return true;
    return jwt_decode(token).exp < dayjs().unix();
  } catch (error) {
    return true;
  }
};

export const objectSize = (val = {}) => {
  return _.size(val);
};

export const dollarFormat = (value) => {
  if (value === 0 || value === null || value === undefined) {
    return 0.0;
  }
  return Number(value.toFixed(4));
};

export const splitFullname = (fullname) => {
  if (!fullname) return [];
  const arr = fullname.split(" ");
  if (arr.length < 2) return [arr[0]];
  return [arr.slice(0, -1).join(" ").trim(), arr[arr.length - 1]];
};
