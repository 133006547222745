import React, { useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Border } from "components/UI/Border";
import { Box, Flex, Text } from "components/UI/CustomRebass";
import { SRadio, SSwitch } from "components/UI/Forms/SFormElements";
import Popover from "components/UI/Popover";
import Icon from "components/UI/Icon";
import { devicesRadioTemplate } from "config/formTemplate/devices-radio-template";
import { getLocalStorage, getUserMediaInit } from "config/utils";
import { useDispatch } from "react-redux";
import { eventModifiedData } from "store/eventSlice";
import { useTranslation } from "react-i18next";

const SwitchStyle = styled(Box)`
  .ant-switch {
    width: 60px;
    background: #2e4271; // american-blue // TODO: fix color
  }

  .ant-switch-checked {
    /* width: 60px; */
    background: #01d73d; // TODO: fix color
  }

  .ant-switch-handle::before {
    background-color: #d9d9d9; // TODO: fix color
  }

  .ant-switch-checked:focus {
    box-shadow: none;
  }

  .ant-switch-disabled {
    opacity: 1;
  }
`;

const DeviceSettings = ({
  firstTitle,
  firstText,
  secondTitle,
  secondText,
  permission,
  inputDevices,
  notDevicesText,
  notDevicesPermissionText,
  selectedDevice,
  selectedDeviceAction,
  type,
  hasDevice,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [visiblePopover, setVisiblePopover] = useState(null);

  const visiblePopoverChange = () => {
    setVisiblePopover(!visiblePopover);
  };

  function gotStream(stream) {
    if (stream === "Permission denied") {
      dispatch(
        eventModifiedData({
          name: "isBrowserStreamPermission",
          data: {
            status: false,
            video: getLocalStorage("videoId") || false,
            audio: getLocalStorage("audioId") || false,
          },
        }),
      );
      return;
    }
    // const videoElement = document.querySelector("video.streamVideo");
    // window.stream = stream; // make stream available to console
    // videoElement.srcObject = stream;
    // videoElement.onloadedmetadata = function (e) {
    //   videoElement.play();
    // };
  }

  const handleChange = (deviceId) => {
    selectedDeviceAction({
      id: deviceId,
      type: type,
    });
    const constraints = {
      audio: type === "audio" ? { deviceId } : hasDevice,
      video: type === "video" ? { deviceId } : hasDevice,
    };
    getUserMediaInit(constraints, gotStream);
  };

  return (
    <Border
      borderWidth={0}
      bg="yankeesBlue"
      borderRadius={16}
      px={20}
      pt={30}
      pb={20}
      mb={32}
    >
      <Border
        borderWidth={0}
        borderBottomWidth={1}
        borderColor="independence"
        pb={40}
        mb={30}
      >
        <Text color="electricBlue" fontSize={[16, 16, 18, 20, 22]}>
          {t(firstTitle)}
        </Text>
        <Flex justifyContent="space-between">
          <Text color="lavenderBlue" fontSize={[12, 12, 13, 14, 16]}>
            {t(firstText)}
          </Text>
          <SwitchStyle>
            <SSwitch
              checked={permission === "granted" ? true : false}
              disabled
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </SwitchStyle>
        </Flex>
      </Border>
      {permission === "granted" ? (
        <Border
          borderWidth={0}
          borderBottomWidth={1}
          borderColor="independence"
          pb={30}
          mb={20}
        >
          <Text color="electricBlue" fontSize={[16, 16, 18, 20, 22]}>
            {t(secondTitle)}
          </Text>
          <Flex justifyContent="space-between">
            <Text
              color="lavenderBlue"
              fontSize={[12, 12, 13, 14, 16]}
              maxWidth={[380, 380, 400, 600, 600, 600]}
            >
              {t(secondText)}
            </Text>
            {inputDevices.length > 0 ? (
              <Popover
                visible={visiblePopover}
                onVisibleChange={visiblePopoverChange}
                mode="broadcast"
                content={
                  <Flex
                    width={[200, 200, 200, 250, 300]}
                    flexDirection="column"
                    px={12}
                  >
                    <SRadio
                      opt={devicesRadioTemplate}
                      options={inputDevices.filter(
                        (fl) => fl.deviceId !== "default",
                      )}
                      value={
                        selectedDevice.length && selectedDevice[0]?.deviceId
                      }
                      prefixStyled={{ mb: 0 }}
                      onChange={(e) => handleChange(e.target.value)}
                    />
                  </Flex>
                }
              >
                <Border
                  borderWidth={2}
                  borderColor="lavenderBlue"
                  borderRadius={100}
                  bg="reinza"
                  color="white"
                  pl={20}
                  pr={20}
                  alignItems="center"
                  height={52}
                  display="flex"
                  mx={15}
                  ml={[0, 0, 0, 15]}
                  width={[231, 231, 231, 250, 300]}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <Icon icon="icon-camera" fontSize={20} mr={15} />
                  <Text>{selectedDevice[0]?.label}</Text>
                  <Icon icon="icon-arrow-down" fontSize={20} ml="auto" />
                </Border>
              </Popover>
            ) : (
              <Flex justifyContent="center">
                <Icon
                  icon="icon-block-guard"
                  color="magicPotion"
                  fontSize={[16, 18, 20, 24, 26]}
                  ml={10}
                ></Icon>
                <Text
                  fontSize={[14, 14, 15, 16, 18]}
                  color="magicPotion"
                  textAlign="center"
                  ml={5}
                >
                  {t(notDevicesText)}
                </Text>
              </Flex>
            )}
          </Flex>
        </Border>
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Icon
            icon="icon-block-guard"
            color="magicPotion"
            fontSize={[16, 18, 20, 24, 26]}
            mr={10}
          ></Icon>
          <Text
            fontSize={[14, 14, 15, 16, 18]}
            color="magicPotion"
            textAlign="center"
          >
            {t(notDevicesPermissionText)}
          </Text>
        </Flex>
      )}
    </Border>
  );
};

export default DeviceSettings;
