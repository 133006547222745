import createDataContext from "./createDataContext";

const reducer = (state, action) => {
  switch (action.type) {
    case "update":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const updateBlockedPeople = (dispatch) => (payload) => {
  dispatch({ type: "update", payload });
};

export const { Provider, Context } = createDataContext(
  reducer,
  { updateBlockedPeople },
  { blockList: [], blockSearchList: [], isBlocked: false, loading: false },
);
