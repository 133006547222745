import { createSlice } from "@reduxjs/toolkit";
import { websocketConnectAction } from "./actions/socketAction";

const initialState = { socket: null };

export const websocketSlice = createSlice({
  name: "websocket",
  initialState,
  reducers: {
    websocketModifiedData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
  },
});

export const { websocketModifiedData } = websocketSlice.actions;

export const websocketConnect = websocketConnectAction;

export default websocketSlice.reducer;
