import React, { useContext, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "config/hooks/useModal";
import { Context as SettingsContext } from "context/SettingsContext";
import { userImageUrl } from "config/utils";
import { Flex, Image, Text } from "components/UI/CustomRebass";
import { Border } from "components/UI/Border";
import Icon from "components/UI/Icon";
import ModeratorModal from "./ModeratorModal";
import { EventService, UserService } from "services";
import Loading from "components/Loading";
import { useTranslation } from "react-i18next";
import { settingModifiedData } from "store/settingSlice";
// import BlockUserModal from "./BlockUserModal";

const userService = new UserService();
const eventService = new EventService();

const ModeratorDropdownItem = ({ item, type, iconType, ...rest }) => {
  const dispatch = useDispatch();

  const { moderators, moderatorsSearch } = useSelector(
    ({ setting }) => setting,
  );
  const { t } = useTranslation();
  const { r } = useSelector(({ cache }) => cache);
  const [modalType, setModalType] = useState("assign");
  const [loading, setLoading] = useState(false);

  const {
    state: { moderator },
    updateSettings,
  } = useContext(SettingsContext);

  const {
    isModalVisible: isVisibleModeratorModal,
    openModal: openModeratorModal,
    closeModal: closeModeratorModal,
  } = useModal();

  const addModerator = () => {
    setModalType("assign");
    openModeratorModal();
  };

  const acceptModerator = () => {
    setLoading(true);

    eventService
      .answerInvite({ answer: true, invitationId: item._id })
      .then(({ data }) => {
        setLoading(false);

        const updatedModerators = moderators.map((obj) => {
          if (obj._id === item._id) {
            return { ...obj, _id: data._id, status: "accepted" };
          }

          return obj;
        });

        dispatch(
          settingModifiedData({
            name: "moderators",
            data: updatedModerators,
          }),
        );
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const deleteModerator = () => {
    setModalType("delete");
    openModeratorModal();
  };

  const declinedModerator = () => {
    setLoading(true);

    const updatedModerators = moderators.map((obj) => {
      if (obj._id === item._id) {
        return { ...obj, status: "declined" };
      }

      return obj;
    });

    if (item.type === "user_moderator") {
      eventService
        .answerInvite({ answer: false, invitationId: item._id })
        .then((res) => {
          setLoading(false);

          dispatch(
            settingModifiedData({
              name: "moderators",
              data: [
                ...updatedModerators.filter((x) => x.status !== "declined"),
              ],
            }),
          );
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      eventService
        .answerInvite({ answer: false, invitationId: item._id })
        .then(() => {
          setLoading(false);

          dispatch(
            settingModifiedData({
              name: "moderators",
              data: [
                ...updatedModerators.filter((x) => x.status !== "declined"),
              ],
            }),
          );
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const renderFunc = () => {
    return type === "search"
      ? addModerator
      : item.status === "pending"
      ? [acceptModerator, declinedModerator, deleteModerator]
      : deleteModerator;
  };

  return (
    <>
      <Border
        as="li"
        fontSize={16}
        color="white"
        py={8}
        pl={5}
        borderWidth={0}
        bg={type === "search" ? "reinza" : "independence"}
        display="flex"
        justifyContent="space-between"
        borderRadius={[13, 13, 14, 16, 16]}
        height={[38, 38, 38, 45, 45]}
        alignItems="center"
        mb={[5, 5, 10, 10, 13]}
        sx={{
          "&:last-child": {
            border: "none",
          },
          cursor: "pointer",
        }}
        {...rest}
      >
        <Flex alignItems="center">
          <Image
            minWidth={[22, 22, 24, 30, 36]}
            maxWidth={[22, 22, 24, 30, 36]}
            height={[22, 22, 24, 30, 36]}
            mx={[4, 4, 5, 6, 8]}
            sx={{ borderRadius: "50%" }}
            src={userImageUrl(
              type === "search"
                ? item.username
                : moderator.moderatorType === 4
                ? item.username // item.typeUsername
                : item.ownerUsername, // item.ownerUsername
              "profile",
              r,
            )}
          />
          <Text
            fontSize={[12, 12, 12, 14, 16]}
            maxWidth={155}
            sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {type === "search"
              ? item.username
              : moderator.moderatorType === 4
              ? item.username // item.typeUsername
              : item.ownerUsername}
          </Text>
        </Flex>
        {loading ? (
          <Flex mr={10}>
            <Loading width={30} height={30} />
          </Flex>
        ) : type === "search" ? (
          <Icon
            icon="icon-plus"
            mr={[5, 5, 8, 12, 15]}
            fontSize={[12, 12, 12, 14, 14, 16]}
            color="lavenderBlue"
            onClick={() => addModerator()}
          />
        ) : (
          iconType(item.status, renderFunc(), t)
        )}
      </Border>
      <ModeratorModal
        type={modalType}
        isModalVisible={isVisibleModeratorModal}
        closeModal={closeModeratorModal}
        item={item}
        getContainer={() => document.querySelector(".moderatorUserDropdown")}
      />
    </>
  );
};

export default ModeratorDropdownItem;
