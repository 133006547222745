const languages = Object.freeze({
  en: {
    label: "English",
    value: "en",
  },
  // da: {
  //   label: "Dansk",
  //   value: "da",
  // },
  // de: {
  //   label: "Deutsch",
  //   value: "de",
  // },
  // "en-gb": {
  //   label: "English - UK",
  //   value: "en-gb",
  // },
  // es: {
  //   label: "Español - España",
  //   value: "es",
  // },
  // "es-mx": {
  //   label: "Español - Latinoamérica",
  //   value: "es-mx",
  // },
  // fr: {
  //   label: "Français",
  //   value: "fr",
  // },
  // it: {
  //   label: "Italiano",
  //   value: "it",
  // },
  // hu: {
  //   label: "Magyar",
  //   value: "hu",
  // },
  // nl: {
  //   label: "Nederlands",
  //   value: "nl",
  // },
  // no: {
  //   label: "Norsk",
  //   value: "no",
  // },
  // pl: {
  //   label: "Polski",
  //   value: "pl",
  // },
  // pt: {
  //   label: "Português",
  //   value: "pt",
  // },
  // "pt-br": {
  //   label: "Português - Brasil",
  //   value: "pt-br",
  // },
  // ro: {
  //   label: "Română",
  //   value: "ro",
  // },
  // sk: {
  //   label: "Slovenčina",
  //   value: "sk",
  // },
  // fi: {
  //   label: "Suomi",
  //   value: "fi",
  // },
  // sv: {
  //   label: "Svenska",
  //   value: "sv",
  // },
  // vi: {
  //   label: "Tiếng Việt",
  //   value: "vi",
  // },
  // tr: {
  //   label: "Türkçe",
  //   value: "tr",
  // },
  // cs: {
  //   label: "Čeština",
  //   value: "cs",
  // },
  // el: {
  //   label: "Ελληνικά",
  //   value: "el",
  // },
  // bg: {
  //   label: "Български",
  //   value: "bg",
  // },
  // ru: {
  //   label: "Русский",
  //   value: "ru",
  // },
  // th: {
  //   label: "ภาษาไทย",
  //   value: "th",
  // },
  // "zh-cn": {
  //   label: "中文 简体",
  //   value: "zh-cn",
  // },
  // "zh-tw": {
  //   label: "中文 繁體",
  //   value: "zh-tw",
  // },
  // ja: {
  //   label: "日本語",
  //   value: "ja",
  // },
  // ko: {
  //   label: "한국어",
  //   value: "ko",
  // },
});

export default languages;
