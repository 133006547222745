import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "emotion-theming";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useModal } from "config/hooks/useModal";
import { dollarFormat } from "config/utils";
import { ButtonAnimation } from "assets/styled/animationClass";
import { eventDepthModifiedData } from "store/eventSlice";
import { Flex, Image, Text } from "components/UI/CustomRebass";
import { Border } from "components/UI/Border";
import Modal from "components/UI/Modal";

const MoneyboxPopup = () => {
  const { isModalVisible, openModal, closeModal } = useModal();
  const { moneybox } = useSelector(({ event }) => event);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { colors } = useTheme();

  useEffect(() => {
    openModal();
  }, [moneybox.showPopup]);

  const closeMoneyboxModal = () => {
    dispatch(
      eventDepthModifiedData({
        name: "moneybox",
        depth: ["showPopup"],
        data: false,
      }),
    );

    closeModal();
  };

  return (
    moneybox.showPopup && (
      <Modal
        visible={isModalVisible}
        onCancel={closeMoneyboxModal}
        width={528}
        height={519}
        style={{ maxHeight: "90vh", padding: "0px" }}
      >
        <Border
          borderWidth={0}
          borderRadius={20}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          height="100%"
          sx={{
            position: "relative",
            zIndex: 2,
          }}
        >
          <Image
            src={require("assets/img/moneyboxpopupbg2.png")}
            sx={{ position: "absolute", top: 0, zIndex: 2, borderRadius: 20 }}
            width="100%"
            height="100%"
          />

          <Image
            src={require("assets/img/pig.png")}
            width={131}
            height={138}
            sx={{ transform: "rotate(-13deg)", zIndex: 2 }}
          />
          <Text color="electricBlue" fontSize={[20, 22, 24, 26, 34]}>
            {t("title.money_box")}
          </Text>
          <Flex alignItems="center">
            <Text
              textAlign="center"
              fontSize={20}
              color="lavenderBlue"
              dangerouslySetInnerHTML={{
                __html: t("title.moneybox_popup_content", {
                  prizePool: dollarFormat(Number(moneybox.prize || 0)), // TODO: use dollar format
                  prizeColor: `color: ${colors.speedrunnersLime}`,
                }),
              }}
            />
          </Flex>
        </Border>
        <Flex justifyContent="center">
          <ButtonAnimation
            bg="cyan"
            color="black"
            flex={1}
            mr={8}
            p={5}
            fontSize={[12, 12, 18, 18, 18]}
            width={290}
            height={[35, 56, 56, 56, 56]}
            sx={{
              position: "absolute !important",
              bottom: 50,
              zIndex: 10,
              "&:hover,&:active,&:focus": {
                backgroundColor: (props) => props.colors.electricBlue,
              },
            }}
            onClick={() => {
              navigate("/wallet");
              closeMoneyboxModal();
            }}
          >
            {t("title.my_wallet")}
          </ButtonAnimation>
        </Flex>
      </Modal>
    )
  );
};

export default MoneyboxPopup;
