import React, { useEffect, useState } from "react";
import Modal from "components/UI/Modal";
import { useModal } from "config/hooks/useModal";
import { useSelector } from "react-redux";
import EventModalInviteStream from "containers/EventPage/components/EventModal/EventModalInviteStream";
import { EventModalInviteModerator } from "containers/EventPage/components/EventModal";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { responseNotify } from "config/utils";

const InvitationPopup = () => {
  const { isModalVisible, openModal, closeModal } = useModal();
  const { popup } = useSelector(({ event }) => event);
  const { t } = useTranslation();
  const { id: eventId } = useParams();
  const [req, setreq] = useState(null);

  useEffect(() => {
    console.log("eventId", eventId);
    // if (req) return;
    // if (eventId) {
    //   setreq(true);
    //   responseNotify({
    //     placement: "topRight",
    //     duration: 0,
    //     // closeIcon: () => <>asd</>,
    //     message: "Notification Title",
    //     description:
    //       "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
    //   });
    // } else {
    //   setreq(true);
    openModal();
    // }
  }, [popup]);

  useEffect(() => {
    return () => {
      setreq(false);
    };
  }, []);

  return (
    popup && (
      <Modal
        title={
          popup.type === "stream"
            ? t("title.invite_streamer")
            : t("title.invite_moderator_popup")
        }
        visible={isModalVisible}
        onCancel={closeModal}
        width={510}
        style={{ maxHeight: "90vh" }}
        closeIcon={undefined}
        maskClosable={false}
        keyboard={false}
      >
        {popup.type === "stream" ? (
          <EventModalInviteStream item={popup} closeModal={closeModal} />
        ) : (
          <EventModalInviteModerator item={popup} closeModal={closeModal} />
        )}
      </Modal>
    )
  );
};

export default InvitationPopup;
