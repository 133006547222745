import Icon from "components/UI/Icon";
import { Flex, Text } from "components/UI/CustomRebass";
import { useTranslation } from "react-i18next";

const CustomEmpty = ({ icon, iconOpt, textOpt, message, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent="center" alignItems="center" {...rest}>
      {icon && <Icon icon={icon} {...iconOpt} />}
      <Text
        as="p"
        color="ceil"
        sx={{ textAlign: "center" }}
        dangerouslySetInnerHTML={{
          __html: t(message),
        }}
        {...textOpt}
      ></Text>
    </Flex>
  );
};

export default CustomEmpty;
