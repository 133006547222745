import React, { useCallback } from "react";
import { Box, Text, Flex } from "components/UI/CustomRebass";
import Background from "components/UI/Background";
import { Border } from "components/UI/Border";
import { useNavigate } from "react-router-dom";
import { fromNow, userImageUrl } from "config/utils";
import { notification } from "config/enums";
import Icon from "components/UI/Icon";
import { useTranslation } from "react-i18next";

const NotificationItem = ({ notificationItem, ...rest }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToProfile = useCallback((item) => {
    switch (item.type) {
      case "follow":
        return navigate(`/profile/${item.senderUser.username}`);
      case "moderator":
        if (item.detail?.eventId) {
          return navigate(`/event/${item.detail.eventId}`);
        } else {
          return navigate(`/profile/${item.senderUser.username}`);
        }
      case "event":
        return navigate(`/event/${item.detail.eventId}`);
      default:
        return navigate(`/profile/${item.senderUser.username}`);
    }
  }, []);

  const type = () => {
    const item = notification[notificationItem?.type];
    return {
      ...item,
      text: item.text(notificationItem, t),
    };
  };
  let isEvent = type().value === "event" || type().value === "startevent";

  return (
    <Border
      display="flex"
      py={12}
      borderColor="independence"
      borderWidth={0}
      borderBottomWidth={1}
      sx={{
        cursor: "pointer",
        "&:first-of-type": {
          paddingTop: 0,
        },
      }}
      onClick={() => goToProfile(notificationItem)}
      {...rest}
    >
      {isEvent ? (
        <Background
          url={userImageUrl(notificationItem?.senderUser?.username, "profile")}
          height={36}
          minWidth={36}
          mr={8}
          sx={{
            backgroundSize: "cover",
            borderRadius: "50%",
          }}
        />
      ) : (
        <Border
          borderWidth={1}
          borderRadius="50%"
          borderColor="greenYellow"
          bg="black"
          display="flex"
          justifyContent="center"
          alignItems="center"
          minWidth={36}
          height={36}
          mr={8}
          sx={{
            boxSizing: "border-box",
          }}
        >
          <Icon icon={type().icon} fontSize={18} color="greenYellow" />
        </Border>
      )}
      <Box
        sx={{
          wordBreak: "break-word",
        }}
      >
        <Flex>
          <Text fontSize={14} color="lavenderBlue">
            {t(type().text.left)}
            <Box as="span" ml={3} color="electricBlue">
              {t(type().text.mid)}
            </Box>
          </Text>
          <Text ml={3} color="lavenderBlue">
            {t(type().text.right)}
          </Text>
        </Flex>
        <Text fontSize={12} color="ceil">
          {fromNow(notificationItem?.updatedAt)}
        </Text>
      </Box>
    </Border>
  );
};

export default NotificationItem;
