import axios from "axios";
import {
  getLocalStorage,
  responseMessage,
  setLocalStorage,
} from "config/utils";

var refreshTokenPromise = null;

class Response {
  data;
  status;
  statusCode;

  constructor(data, status, statusCode) {
    this.data = data;
    this.status = status;
    this.statusCode = statusCode;
  }
}

function refreshToken() {
  if (!getLocalStorage("refreshToken")) return;

  refreshTokenPromise =
    refreshTokenPromise ||
    axios
      .post(
        `${process.env.REACT_APP_USER_API_ENDPOINT}/auth/refresh`,
        {
          token: getLocalStorage("token"),
          refreshToken: getLocalStorage("refreshToken"),
        },
        {
          headers: { Authorization: "public" },
        },
      )
      .then((e) => e?.data?.data)
      .then((e) => {
        if (!e) throw Error("Cannot refresh token");
        setLocalStorage("token", e.token);
        setLocalStorage("refreshToken", e.refreshToken);
        return e;
      })
      .catch(() => {
        // TODO: logout yapilacak
        console.log("axios - refreshToken - logout");
        // logout();
      });

  return refreshTokenPromise;
}

const axiosClient = (baseURL, interceptors = true) => {
  const instance = axios.create({
    baseURL,
    timeout: 20000,
  });

  if (interceptors) {
    instance.interceptors.response.use(
      (res) => {
        let status = res.status === 200 ? true : false;
        return new Response(res.data, status, res.status);
      },
      async (err) => {
        // console.log(`[axios][interceptors][response][failure] ${err}`);
        const originalRequest = err.config;

        if (err?.response?.status === 401) {
          originalRequest._retry = true;
          const { token } = await refreshToken();
          originalRequest.headers.Authorization = token;
          return await instance(originalRequest);
        } else {
          responseMessage({ content: err.response.data.message });
        }
        throw err;
      },
    );
  }

  function makeRequest(type, path, options = {}) {
    // console.log(`[axios][makeRequest] ${type} ${path}}`);

    return new Promise((resolve, reject) => {
      instance(path, {
        method: type,
        headers: {
          Authorization: "public",
        },
        ...options,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  }

  async function makeRequestAuth(type, path, options = {}) {
    // console.log(`[axios][makeRequestAuth] ${type} ${path}`);

    // TODO: logout yapilacak
    if (getLocalStorage("token") && !getLocalStorage("refreshToken"))
      return console.log("axios - makeRequestAuth - logout");
    // return logout();

    const token = getLocalStorage("token");

    return makeRequest(type, path, {
      headers: {
        Authorization: token,
      },
      ...options,
    });
  }

  return {
    instance,
    makeRequest,
    makeRequestAuth,
  };
};

export default axiosClient;
