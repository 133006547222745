import React from "react";
import { Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

const SCheckboxStyled = styled(Checkbox)`
  color: ${(props) => props.theme.colors.white};
  font-size: 12px;
  font-weight: 400;
  .ant-checkbox-inner {
    border-radius: 4px;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: ${(props) => props.theme.colors.electricBlue};
      border-color: ${(props) => props.theme.colors.electricBlue};
    }
  }
`;

const SCheckbox = ({ hasError, placeholder, text, placeholderTx, ...rest }) => {
  const { t } = useTranslation();
  return (
    <SCheckboxStyled
      placeholder={placeholderTx ? t(placeholderTx) : placeholder}
      className={hasError ? "ant-input-status-error" : ""}
      {...rest}
    >
      {t(text)}
    </SCheckboxStyled>
  );
};

export default SCheckbox;
