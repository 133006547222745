import { useCallback, useEffect, useState } from "react";
import {
  getDeviceInfo,
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "config/utils";

export default function useDevices() {
  const [deviceInfo, setDeviceInfo] = useState({
    selectedAudioDevice: [],
    selectedVideoDevice: [],
    audioInputDevices: [],
    videoInputDevices: [],
    audioOutputDevices: [],
    hasAudioInputDevices: false,
    hasVideoInputDevices: false,
    videoPermissions: false,
    audioPermissions: false,
  });

  const selectedDevice = useCallback(
    ({ id, type }) => {
      switch (type) {
        case "audio": {
          const selectedAudioDevice = deviceInfo.audioInputDevices.filter(
            (fl) => fl.deviceId === id,
          );
          setLocalStorage("audioId", id);
          setDeviceInfo({ ...deviceInfo, selectedAudioDevice });
          break;
        }
        case "video": {
          const selectedVideoDevice = deviceInfo.videoInputDevices.filter(
            (fl) => fl.deviceId === id,
          );
          setLocalStorage("videoId", id);
          setDeviceInfo({ ...deviceInfo, selectedVideoDevice });
          break;
        }

        default:
          break;
      }
    },
    [deviceInfo],
  );

  useEffect(() => {
    const getDevices = async () =>
      getDeviceInfo().then(async (devices) => {
        if (!devices) return;
        const audioId = getLocalStorage("audioId");
        const videoId = getLocalStorage("videoId");
        const curAudio = devices.audioInputDevices.filter((fl) =>
          audioId ? fl.deviceId === audioId : fl.deviceId === "default",
        );
        const curVideo = devices.videoInputDevices.filter((fl) =>
          audioId ? fl.deviceId === videoId : fl.deviceId === "default",
        );
        const audioDevice = curAudio.length
          ? devices.audioInputDevices.filter(
              (fl) =>
                fl.groupId === curAudio[0].groupId && fl.deviceId !== "default",
            )
          : devices.audioInputDevices;

        const videoDevice = curVideo.length
          ? devices.videoInputDevices.filter(
              (fl) => fl.groupId === curVideo[0].groupId,
            )
          : devices.videoInputDevices;
        audioDevice[0].deviceId.length
          ? setLocalStorage("audioId", audioDevice[0].deviceId)
          : removeLocalStorage("audioId");
        videoDevice[0].deviceId.length
          ? setLocalStorage("videoId", videoDevice[0].deviceId)
          : removeLocalStorage("videoId");

        const checkDevices = async () => {
          let cameraPerm = "";
          let audioPerm = "";
          if (navigator.vendor) {
            cameraPerm = await navigator?.permissions?.query({
              name: "camera",
            });

            audioPerm = await navigator?.permissions?.query({
              name: "microphone",
            });
          }

          return { cameraPerm, audioPerm };
        };
        const devicePerm = await checkDevices();

        setDeviceInfo({
          ...deviceInfo,
          ...devices,
          selectedAudioDevice: audioDevice,
          selectedVideoDevice: videoDevice,
          videoPermissions: devicePerm?.cameraPerm?.state,
          audioPermissions: devicePerm?.audioPerm?.state,
        });
      });
    navigator?.mediaDevices?.addEventListener("devicechange", getDevices);
    getDevices();

    return () => {
      navigator?.mediaDevices?.removeEventListener("devicechange", getDevices);
    };
  }, []);

  return { ...deviceInfo, selectedDevice };
}
