import { createSlice } from "@reduxjs/toolkit";
import { removeLocalStorage, setLocalStorage } from "config/utils";
import { getViaToken, googleSignIn, signIn } from "./actions/authAction";
import { userResetData } from "./userSlice";
import { websocketConnect } from "./websocketSlice";

const initialState = {
  authenticated: false,
  refreshToken: "",
  token: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authModifiedData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
    authResetData: () => initialState,
  },
});

export const { authModifiedData, authResetData } = authSlice.actions;

export const authSignIn = (payload) => (dispatch) => {
  dispatch(setToken(payload.token));
  setLocalStorage("refreshToken", payload?.refreshToken);
  return dispatch(getViaToken());
};

export const setToken = (token) => () => {
  setLocalStorage("token", token);
  setLocalStorage("refreshToken", token);
};

export const authGoogleSignIn = (payload) => (dispatch) => {
  return dispatch(googleSignIn(payload));
};

export const logout = () => (dispatch, state) => {
  const { socket } = state().websocket;
  localStorage.clear();
  dispatch(authResetData());
  dispatch(userResetData());
  dispatch(websocketConnect());
  socket && socket.close();
  return true;
};

export const selectAuth = (state) => state.auth;

export default authSlice.reducer;
