import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTheme } from "emotion-theming";
import { useTranslation } from "react-i18next";
import {
  settingAddUpdateModifiedData,
  settingModifiedData,
} from "store/settingSlice";
import { UserService } from "services";
import useClickOutside from "config/hooks/useClickOutside";
import SearchBox from "components/SearchBox";
import { Box, Flex, Text } from "components/UI/CustomRebass";
import { Border } from "components/UI/Border";
import ScrollView from "components/ScrollView";
import Loading from "components/Loading";
import ModeratorDropdown from "./ModeratorDropdown";
import ModeratorNoResult from "./ModeratorNoResult";
import { acceptedSettingModeratorAction } from "store/actions/settingAction";

const userService = new UserService();

const ModeratorContent = ({
  title,
  description,
  secondService,
  status,
  scope = null,
  value,
  icon,
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { info } = useSelector(({ user }) => user);
  const { socket } = useSelector(({ websocket }) => websocket);

  const { moderators, moderatorsSearch, selectedModeratorPage } = useSelector(
    ({ setting }) => setting,
  );

  const [searchLoading, setSearchLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(null);

  const getModerators = () => {
    setIsLoading(true);
    let params = { scope, type: "moderator", status };

    userService.getInvitations(params).then(({ data }) => {
      dispatch(
        settingModifiedData({
          name: "moderators",
          data: data.filter((x) => x.status === "pending"),
        }),
      );
      let userInvitations = data.filter((x) => x.status === "pending");

      if (secondService === "moderators") {
        userService
          .getModerator({
            userId: info?._id,
            type: "user",
          })
          .then(({ data }) => {
            setIsLoading(false);
            let allModerators = userInvitations.concat(data);

            dispatch(
              settingModifiedData({
                name: "moderators",
                data: allModerators,
              }),
            );
          });
      } else {
        userService
          .getModeratorWhose({
            userId: info?._id,
          })
          .then(({ data }) => {
            const whoseData = data.map((x) => {
              return { ...x, ownerUsername: x.username };
            });
            setIsLoading(false);
            let allModerators = userInvitations.concat(whoseData);
            dispatch(
              settingModifiedData({
                name: "moderators",
                data: allModerators,
              }),
            );
          });
      }
    });
  };

  useEffect(() => {
    getModerators();
    return () => {
      dispatch(
        settingModifiedData({
          name: "moderators",
          data: null,
        }),
      );
    };
  }, []);

  const onSearchChange = _.debounce(
    (e) => {
      setSearchValue(e?.target?.value);
      if (e.target.value === "") {
        dispatch(
          settingModifiedData({
            name: "moderatorsSearch",
            data: null,
          }),
        );
      }
      if (
        e.target.value.trim().length > 0 &&
        e.keyCode !== 13 &&
        e.target.value
      ) {
        setSearchLoading(true);
        userService.getUser({ username: e.target.value }).then((res) => {
          let responseData = res.data.items;
          responseData = responseData.filter((x) => x._id !== info._id);
          setSearchLoading(false);

          let alreadyInvited = responseData
            .map((item) => {
              return { ...item, isInvited: false };
            })
            .filter((fl) => fl.username !== info.username);
          alreadyInvited = alreadyInvited.map((x) => {
            let exist = null;
            moderators.filter((item) => {
              if (item.username === x.username) {
                exist = item;
              }
            });
            return exist
              ? {
                  ...x,
                  isInvited: true,
                }
              : x;
          });

          dispatch(
            settingModifiedData({
              name: "moderatorsSearch",
              data: alreadyInvited.filter((x) => x.isInvited === false),
            }),
          );
        });
      }
    },
    [300],
  );

  const searchDropdown = () => {
    dispatch(
      settingModifiedData({
        name: "moderatorsSearch",
        data: null,
      }),
    );
    setSearchValue(null);
  };

  const searchboxDropdownRef = useRef(null);
  useClickOutside(searchboxDropdownRef, searchDropdown);

  return (
    <>
      <Box mb={20}>
        <Text fontSize={34} color="white">
          {t(title)}
        </Text>
        <Text fontSize={16} color="lavenderBlue">
          {t(description)}
        </Text>
      </Box>
      <Border
        borderWidth={0}
        borderRadius={0}
        borderColor="electricBlue"
        borderTopRightRadius={15}
        borderTopLeftRadius={15}
        sx={{ position: "relative" }}
        height={382}
        wrapperRef={searchboxDropdownRef}
        className="moderatorUserDropdown"
      >
        {value === "sentRequest" && (
          <Border borderWidth={0}>
            <SearchBox
              onKeyUp={(e) => {
                onSearchChange(e);
              }}
              value={searchValue}
              borderRadius="14px"
              borderBottom={moderatorsSearch ? "0" : "14px"}
              bgColor={colors.independence}
              width="100%"
              height={50}
              isLoading={searchLoading}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Border>
        )}

        {isLoading ? (
          <Flex
            justifyContent="center"
            alignItems="center"
            width="100%"
            mt={50}
          >
            <Loading width={100} height={100} />
          </Flex>
        ) : (moderators && moderators.length) ||
          (moderatorsSearch && moderatorsSearch.length) ? (
          <ScrollView maxHeight={[410, 420, 430, 416, 495]} height="100%">
            <ModeratorDropdown
              results={moderatorsSearch ? moderatorsSearch : moderators}
              type={moderatorsSearch ? "search" : "main"}
              iconType={icon}
            />
          </ScrollView>
        ) : (
          <ModeratorNoResult type={title} />
        )}
      </Border>
    </>
  );
};

export default ModeratorContent;
