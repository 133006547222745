import React from "react";
import { Link as RebassLink } from "components/UI/CustomRebass";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";

const StyledLink = styled(
  ({ iconPosition, icon, bold, textDecoration, textTransform, ...props }) => (
    <RebassLink {...props} />
  ),
)`
  font-weight: bold;
  text-decoration: none;
`;

export const Link = ({
  color = "white",
  textDecoration = "none",
  children,
  bold,
  href = "#",
  ...rest
}) => {
  return (
    <StyledLink
      color={color}
      textDecoration={textDecoration}
      {...rest}
      as={NavLink}
      to={href}
    >
      {children}
    </StyledLink>
  );
};
