import { languages } from "config/enums";
import { enumToArray } from "config/utils";

const options = enumToArray(languages);

const languageFormElements = () => {
  return {
    language: {
      name: "language",
      options,
    },
  };
};

export default languageFormElements;
